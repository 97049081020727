import React, { useEffect, useState } from "react";
import Index from "../Index";
import Modal from "../modal/Modal";
import UseKeyPress, {
  useKeyPress,
} from "../common/keyboard-shortcuts/UseKeyPressEvent";
// import { useState } from 'react'
// import { Modal } from '@mui/material'

const EditShortcut = (props) => {
  const { setreplaceShortcut, replaceShortcut, seteditShortcut, editShortcut } =
    props;
  const [user, setUser] = useState({
    shortcutName: "",
    shortcutKey: [],
  });

  const handleEditModel = () => {
    seteditShortcut(!editShortcut);
    setreplaceShortcut(!replaceShortcut);
  };
  // const onChangeInput = (e) => {
  //   const { name, value } = e.target;
  //   setUser((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const HandleShortcutEdit = async (e) => {
    e.preventDefault();
    // let data = {
    //   shortcutName: user.shortcutName,
    //   shortcutKey: user.shortcutKey,
    // };
    const formData = new URLSearchParams();
    formData.append("shortcutName", user.shortcutName);
    formData.append("shortcutKey", user.shortcutKey);
    <UseKeyPress data={[...formData]} />;
  };

  // const [pressedKeys, setPressedKeys] = useState([]);

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   window.addEventListener("keyup", handleKeyUp);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //     window.removeEventListener("keyup", handleKeyUp);
  //   };
  // }, []);

  // const handleKeyDown = (event) => {
  //   // Check if the key is not already in the pressedKeys array
  //   if (!pressedKeys.includes(event.key)) {
  //     setPressedKeys((prevKeys) =>
  //       prevKeys.length !== 3 ? [...prevKeys, event.key] : prevKeys
  //     );
  //   }
  // };
  // const handleKeyUp = (event) => {
  //   // Remove the key from the pressedKeys array
  //   // setPressedKeys((prevKeys) => prevKeys.filter((key) => key !== event.key));
  // };

  // Function to check if both 'A' and 'B' keys are pressed simultaneously
  // const areKeysPressed = () => {
  //   return pressedKeys.includes("a") && pressedKeys.includes("b");
  // };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      ["shortcutName"]: value,
    }));
  };
  const [show, setShow] = useState([]);
  const handleKeyPress = (e) => {
    // setUser(...user, ["shortcutKey"] : e.key)
    if (show.length < 4) {
      setUser((prevData) => ({
        ...prevData,
        ["shortcutKey"]: show,
      }));
    }
    
    //   setPressedKeys((prevKeys) =>
    //   prevKeys.length !== 3 ? [...prevKeys, event.key] : prevKeys
    // );

    setShow([...show, e.key]);
    if (e.key === "Enter") {
      // Do code here
      e.preventDefault();
    }
  };
  return (
    <>
      <Index.Box
        className="body-p edit-shortcuts-wrapper"
        component="form"
        onSubmit={HandleShortcutEdit}
      >
        <Index.Box className="cus-input-grp">
          <Index.Typography
            className="cus-lable light-font"
            variant="p"
            component="p"
          >
            Enter shortcut name
          </Index.Typography>
          <Index.Box className="form-group" mb={2}>
            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="Hide Header"
              autoComplete="off"
              name="shortcutName"
              value={user.shortcutName}
              onChange={onChangeInput}
            />
          </Index.Box>
          <Index.Box className="form-group update_key_shortcut">
            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="kye shortcut"
              autoComplete="off"
              name="shortcutKey"
              value={Array.from(new Set(user.shortcutKey)).join("+")}
              // value={(user.shortcutKey).join("+")}
              // onChange={onChangeInput}
              onKeyDown={handleKeyPress}
              InputProps={{
                endAdornment: (
                  <Index.InputAdornment position="end">
                    <Index.IconButton
                      onClick={() => setUser([])}
                      edge="end"
                    >
                      <Index.ClearIcon />
                    </Index.IconButton>
                  </Index.InputAdornment>
                ),
              }}
            />
          </Index.Box>
        </Index.Box>
        {/* <Index.Box className="add-btn-sec"> */}
        <Index.PrimaryButton
          className="primary-btn edit-sortcuts-btn edit-btn"
          btnLabel="Add"
          type="submit"
        ></Index.PrimaryButton>
        {/* <Index.PrimaryButton
            className="primary-btn edit-sortcuts-btn replace-btn"
            btnLabel="Replace"
            onClick={() => handleEditModel()}
          ></Index.PrimaryButton> */}
        {/* </Index.Box> */}
      </Index.Box>
    </>
  );
};

export default EditShortcut;
