import type from "./type";

import {
  GetAllShortcuts,
  loginApiHandle,
  PostUserProfileApiHandle,
  getSingleUser,
  exitStocks,
  resetStocks,
} from "../../service/UserIndex";
import Index from "../../container/Index";
// import Index from "../../Index";

// User Login Function
const UserLogin = (payload, navigate, getWatchList, setUser, setDisable, setError) => {
  return async function (dispatch) {
    loginApiHandle(payload).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.USER_LOGIN_SUCCESS,
          payload: { ...data },
        });
        localStorage.setItem("token", data.data.token);
        sessionStorage.setItem("token", data.data.token);
        getWatchList(data?.data?.token);
        // Index.toast.success(data.message);
        setTimeout(() => {
          navigate("user/dashboard");
          setDisable(false);
        }, 1000);
        dispatch(getAllShortcuts(data.data.token));
        Index.DataService(localStorage.getItem("token"))
          .get(Index.Api.admin.getAllIndex, { params: { isActive: true } })
          .then((res) => {
            if (res.data?.status === 200) {
              let { data } = res.data;
              if (data?.length) {
                dispatch(
                  manageScript({
                    market: data[0]?.category,
                    script: data[0]?.indexName,
                  })
                );
                dispatch(getWishListData(data[0].stocks || []));
                // dispatch(setGridViewStore({ column: 1, row: 1 }));
              }
            }
          })
          .catch((error) => {
            console.error(error.message);
          });
      } else {
        dispatch({ type: type.USER_ERROR_HANDLER, payload: data });
        setTimeout(() => {
          setDisable(false);
        }, 1000);

        setUser({
          email: "",
          password: "",
        });
        // Index.toast.error(data.message);
        setError(data.message);
      }
    });
  };
};

const UserLogoutAction = (navigate) => {
  return async (dispatch) => {
    dispatch({ type: type.USERLOGOUT });
    localStorage.removeItem("token");
    sessionStorage.clear();
    navigate("/");
  };
};
const UserLoginFetch = (payload) => ({
  type: type.fetchAdminLoginSuccess,
  payload,
});

const GetGraphTypeAction = (payload) => ({
  type: type.graphType,
  payload,
});

const GetGraphStyleAction = (payload) => ({
  type: type.graphStyle,
  payload,
});

const UserLogOutAction = (payload) => ({
  type: type.AdminlogOutSuccess,
  payload,
});

// User profile Function
const PostUserProfile = (payload, token, setError) => {
  return async function (dispatch) {
    PostUserProfileApiHandle(payload, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.EDIT_PROFILE, payload: data });
      } else {
        dispatch({ type: type.USER_ERROR_HANDLER, payload: data });
        // Index.toast.error(data.message);
        setError(data.message);
      }
    });
  };
};

const getAllShortcuts = (payload, token) => {
  return async function (dispatch) {
    GetAllShortcuts(payload, token).then((data) => {
      if (data) {
        dispatch({ type: type.GETALLSHORTCUTS, payload: data });
      } else {
        dispatch({ type: type.USER_ERROR_HANDLER, payload: data });
        // Index.toast.error(data.message);
      }
    });
  };
};

const getAngelListData = (payload) => {
  return {
    type: type.angelListData,
    payload,
  };
};
const getWishListData = (payload) => {
  return {
    type: type.wishListData,
    payload,
  };
};
const deleteWishListData = (payload) => {
  return {
    type: type.wishListDataRemove,
    payload,
  };
};
const viewWishListData = (payload) => {
  return {
    type: type.wishListDataView,
    payload,
  };
};
const setChartDuration = (payload) => {
  return {
    type: type.setChartDuration,
    payload,
  };
};
const setTimeFrame = (payload) => {
  return {
    type: type.timeFrame,
    payload,
  };
};

const showHeaderSearchBar = (payload) => {
  return {
    type: type.headerSearchBar,
    payload,
  };
};

const showDefaultSettingModal = (payload) => {
  return {
    type: type.defaultSettingModal,
    payload,
  };
};

const setChartView = (payload) => {
  return {
    type: type.chartView,
    payload,
  };
};
const setLiveData = (payload) => {
  return {
    type: type.liveData,
    payload,
  };
};
const setGridViewStore = (payload) => {
  return {
    type: type.gridView,
    payload,
  };
};

const pageSetting = (payload) => {
  return {
    type: type.pageSetting,
    payload,
  };
};

const activeShortCut = (payload) => {
  return {
    type: type.activeShortCut,
    payload,
  };
};

const currentPagePlus = (payload) => {
  return {
    type: type.currentPagePlus,
    payload,
  };
};

const currentPageMinus = (payload) => {
  return {
    type: type.currentPageMinus,
    payload,
  };
};

const sortWishListData = (payload) => {
  return {
    type: type.sortingWishList,
    payload,
  };
};

const getAllTagsList = (payload) => {
  return {
    type: type.getTagListAll,
    payload,
  };
};

const manageCurrentTag = (payload) => {
  return {
    type: type.MANAGE_CURRENT_TAG,
    payload,
  };
};

const manageGridSpacing = (payload) => {
  return {
    type: type.MANAGE_GRID_SPACING,
    payload,
  };
};

const manageGridBorder = (payload) => {
  return {
    type: type.MANAGE_GRID_BORDER,
    payload,
  };
};

const manageGridColor = (payload) => {
  return {
    type: type.MANAGE_GRID_COLOR,
    payload,
  };
};

const manageScript = (payload) => {
  return {
    type: type.SELECTED_SCRIPT,
    payload,
  };
};

const manageOtherSetting = (payload) => {
  return {
    type: type.OTHER_SETTING,
    payload,
  };
};
const manageDisplaySettings = (payload) => {
  return {
    type: type.DISPLAY_SETTINGS,
    payload,
  };
};

const manageChartDrawingData = (payload) => {
  return {
    type: type.DRAW_CHART,
    payload,
  };
};

const manageActiveChartHoverData = (payload) => {
  return {
    type: type.ACTIVE_CHART_HOVER,
    payload,
  };
};

const manageTrackingActiveChartHoverData = (payload) => {
  return {
    type: type.TRACKING_ACTIVE_CHART_HOVER,
    payload,
  };
};

const manageDownloadChartData = (payload) => {
  return {
    type: type.DOWNLOAD_CHART_TOKEN,
    payload,
  };
};

const manageMiniChartSize = (payload) => {
  return {
    type: type.MINI_CHART_SIZE,
    payload,
  };
};

const manageDrawingButton = () => {
  return {
    type: type.SHOW_HIDE_DRAWING_BUTTON,
  };
};

const manageDrawingToolActiveDeactive = (payload) => {
  return {
    type: type.DRAWING_TOOL_ACTIVE_DEACTIVE,
    payload,
  };
};
const manageDrawingToolsList = (payload) => {
  return {
    type: type.DRAWING_TOOLS_LIST,
    payload,
  };
};

const manageToolOrientation = (payload) => {
  return {
    type: type.TOOL_ORIENTATION,
    payload,
  };
};

const manageChartInstance = (payload) => {
  return {
    type: type.CHART_INSTANCE,
    payload,
  };
};

const manageResetState = (payload) => {
  return {
    type: type.RESET_STATE,
    payload,
  };
};

const manageFlip = () => {
  return {
    type: type.FLIP_STATE,
  };
};

const manageRowsPerPage = (payload) => {
  return {
    type: type.ROWS_PER_PAGE,
    payload,
  };
};

const manageCategoryFilter = (payload) => {
  return {
    type: type.CATEGORY_FILTER,
    payload,
  };
};

const changeChartView = () => {
  return {
    type: type.CHART_VIEW_STATE,
  };
};

const manageDefaultSetting = (payload) => {
  return {
    type: type.DEFAULT_SETTINGS,
    payload,
  };
};

const manageSortingTags = (payload) => {
  return {
    type: type.SORTING_TAGS,
    payload,
  };
};

const manageCustomScript = (payload) => {
  return {
    type: type.CUSTOM_SCRIPTS,
    payload,
  };
};

const manageExitAllModal = (payload) => {
  return {
    type: type.EXIT_ALL_MODAL,
    payload,
  };
};

const manageListSettings = (payload) => {
  return {
    type: type.LIST_SETTINGS,
    payload,
  };
};

const manageTaggedStocksCount = (payload) => {
  return {
    type: type.TAGGED_STOCKS_COUNT,
    payload,
  };
};

const manageSidebarVisibility = (payload) => {
  return {
    type: type.SIDEBAR_VISIBILITY,
    payload,
  };
};

const manageModalParameter = (payload) => {
  return {
    type: type.MODAL_PARAMETERS,
    payload,
  };
};

const manageTwoSideArrowZoom = () => {
  return {
    type: type.TWO_SIDE_ARROW_ZOOM,
  };
};

const manageEraseDrawing = (payload) => {
  return {
    type: type.ERASE_DRAWING,
    payload,
  };
};

const manageCurrentPage = (payload) => {
  return {
    type: type.CURRENT_PAGE,
    payload,
  };
};

export {
  UserLoginFetch,
  UserLogOutAction,
  UserLogin,
  GetGraphTypeAction,
  UserLogoutAction,
  GetGraphStyleAction,
  PostUserProfile,
  getAngelListData,
  getWishListData,
  setChartDuration,
  setTimeFrame,
  showHeaderSearchBar,
  showDefaultSettingModal,
  setChartView,
  setLiveData,
  setGridViewStore,
  pageSetting,
  getAllShortcuts,
  activeShortCut,
  currentPagePlus,
  currentPageMinus,
  deleteWishListData,
  viewWishListData,
  sortWishListData,
  getAllTagsList,
  manageCurrentTag,
  manageGridBorder,
  manageGridSpacing,
  manageGridColor,
  manageScript,
  manageOtherSetting,
  manageDisplaySettings,
  manageChartDrawingData,
  manageActiveChartHoverData,
  manageTrackingActiveChartHoverData,
  manageDownloadChartData,
  manageMiniChartSize,
  manageDrawingButton,
  manageDrawingToolActiveDeactive,
  manageDrawingToolsList,
  manageToolOrientation,
  manageChartInstance,
  manageResetState,
  manageFlip,
  manageRowsPerPage,
  manageCategoryFilter,
  changeChartView,
  manageDefaultSetting,
  manageSortingTags,
  manageCustomScript,
  manageExitAllModal,
  manageListSettings,
  manageTaggedStocksCount,
  manageSidebarVisibility,
  manageModalParameter,
  manageTwoSideArrowZoom,
  manageEraseDrawing,
  manageCurrentPage,
};
