const timeFrames = {
  ONE_MINUTE: "1Minute",
  FIVE_MINUTE: "5Minute",
  TEN_MINUTE: "10Minute",
  FIFTEEN_MINUTE: "15Minute",
  THIRTY_MINUTE: "30Minute",
  ONE_HOUR: "1Hour",
  ONE_DAY: "1Day",
};

const chartInterval = {
  ONE_MINUTE: { interval: "1m", value: 1, offset: "15m" },
  FIVE_MINUTE: { interval: "5m", value: 5, offset: "15m" },
  TEN_MINUTE: { interval: "10m", value: 10, offset: "15m" },
  FIFTEEN_MINUTE: { interval: "15m", value: 15, offset: "15m" },
  THIRTY_MINUTE: { interval: "30m", value: 30, offset: "15m" },
  ONE_HOUR: { interval: "1h", value: 60, offset: "15m" },
  FOUR_HOUR: { interval: "4h", value: 180, offset: "15m" },
  ONE_DAY: { interval: "1d", value: 1440, offset: "0m" },
};

const downloadsType = [
  {
    type: "SVG",
    saveAs: "saveAsSvg",
  },
  {
    type: "PNG",
    saveAs: "saveAsPng",
  },
  {
    type: "JPG",
    saveAs: "saveAsJpg",
  },
  {
    type: "PDF",
    saveAs: "saveAsPdf",
  },
];

const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];

const Constants = {
  timeFrames,
  chartInterval,
  downloadsType,
  allTags,
};

export default Constants;
