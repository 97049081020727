import React from "react";

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Index from "../../component/Index";

const PublicLayout = ({ children }) => {
    const location = Index.useLocation();
  const UserLoggedIn = useSelector((state) => state.UserReducer.isUserLogin);

  return !UserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/user/dashboard"  state={{ from: location }} replace={true} />
  );
};

export default PublicLayout;
