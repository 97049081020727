import React, { useState } from "react";
import Index from "../../Index";
import { UserLogin, activeShortCut } from "../../../redux/user/action";
import { loginValidations } from "../../../validation/Validations";
import { Formik } from "formik";
import * as Yup from "yup";

const userRegisterValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter first name")
    .min(3, "First name must be at least 3 characters long")
    .max(100, "First name must be at most 16 characters long"),
  lastName: Yup.string()
    .required("Please enter last name")
    .min(3, "Last name must be at least 3 characters long")
    .max(100, "Last name must be at most 16 characters long"),
  email: Yup.string()
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    // .matches(
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(?=\.([a-zA-Z]{2,3})?$))$/,
    //   "Please enter valid email"
    // )
    // .matches(
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(?=\.?($| ))|au(?=($| ))))$/,
    //   "Please enter valid email"
    // )
    .matches(
      /^(?:(?!\.)(?![0-9])(?:\w+\.)*(?:\w+\+)*(?:\w+\!)*(?:\w+\#)*(?:\w+\$)*(?:\w+\&)*(?:\w+\%)*(?:\w+\-)*\w+@\w+\.\w{2,3}(?:\.[a-z]{2,3})?)$/,
      "Please enter valid email"
    )
    .required("Please enter email"),
  password: Yup.string()
    .required("Please enter password")
    .max(16, "Password must be at most 16 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case characters."
    ),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Password and confirm Password should be same"
    )
    .required("Please enter confirm password"),
});

const Register = () => {
  // Const declaration region start
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  // Const declaration region end

  // State declaration region start
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  // Submit handler function start

  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    setIsSubmitting(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("firstName", values?.firstName);
    urlencoded.append("lastName", values?.lastName);
    urlencoded.append("email", values?.email);
    urlencoded.append("password", values?.password);
    urlencoded.append("userType", "user");

    try {
      const response = await Index.DataService().post(
        Index.Api.auth.register,
        urlencoded
      );
      Index.toast.success(response?.data?.message);
      navigate("/");
      resetForm();
    } catch (error) {
      if (error.response.status === 409) {
        setFieldError("email", "Email is already exist with the account");
      } else {
        Index.toast.error(error?.response?.data?.message);
      }
    }
    setIsSubmitting(false);
  };
  // Submit handler function end
  // Function declaration region end
  return (
    <>
      <Index.Box className="login-bg">
        <Index.Box className="login-inner-main auth-main register-inner-main-cus register-inner-main custom-register">
          <img
            className="main-logo main-logo-register"
            src={Index.Png.logo}
            alt="logo"
          />
          <Index.Typography variant="h1">Register</Index.Typography>
          <Formik
            validationSchema={userRegisterValidationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              values,
              errors,
              handleBlur,
              handleChange,
              touched,
              setFieldError,
            }) => (
              <Index.Box component="form" onSubmit={handleSubmit}>
                <Index.Box className="register-input-flex">
                  <Index.Box className="cus-input-grp password-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      First name
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        name="firstName"
                        value={values.firstName}
                        className="form-control custom-form-input"
                        placeholder="Enter first name"
                        autoComplete="off"
                        onChange={(e) => {
                          let value = e.target.value;
                          if (
                            (/^[A-Za-z]+$/.test(value) || value === "") &&
                            value?.length <= 20
                          )
                            handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault();
                          }
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          dispatch(activeShortCut(true));
                        }}
                        onFocus={(e) => {
                          dispatch(activeShortCut(false));
                        }}
                      />
                    </Index.Box>
                    {errors?.firstName && touched?.firstName && (
                      <span className="auth-error-msg">
                        {errors?.firstName}
                      </span>
                    )}
                  </Index.Box>
                  <Index.Box className="cus-input-grp password-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Last name
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.TextField
                        name="lastName"
                        fullWidth
                        className="form-control custom-form-input"
                        placeholder="Enter last name"
                        value={values.lastName}
                        autoComplete="off"
                        onChange={(e) => {
                          let value = e.target.value;
                          if (
                            (/^[A-Za-z]+$/.test(value) || value === "") &&
                            value?.length <= 20
                          )
                            handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault();
                          }
                        }}
                        // onBlur={handleBlur}
                        onBlur={(e) => {
                          handleBlur(e);
                          dispatch(activeShortCut(true));
                        }}
                        onFocus={(e) => {
                          dispatch(activeShortCut(false));
                        }}
                      />
                    </Index.Box>
                    {errors?.lastName && touched?.lastName && (
                      <span className="auth-error-msg">{errors?.lastName}</span>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    Email
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="email"
                      fullWidth
                      className="form-control custom-form-input"
                      placeholder="Enter email"
                      value={values.email}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.value.trim() === "") {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  {errors?.email && touched?.email && (
                    <span className="auth-error-msg">{errors?.email}</span>
                  )}
                </Index.Box>
                <Index.Box className="register-input-flex register-password">
                  <Index.Box className="cus-input-grp password-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Password
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control custom_form_control custom-form-input"
                        id="outlined-adornment-password"
                        sx={{ background: "red" }}
                        name="password"
                        fullWidth
                        value={values.password}
                        // onBlur={handleBlur}
                        onBlur={(e) => {
                          handleBlur(e);
                          dispatch(activeShortCut(true));
                        }}
                        onFocus={(e) => {
                          dispatch(activeShortCut(false));
                        }}
                        // onFocus={handleFocus}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        // error={Boolean(errors.password && touched.password)}
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        inputProps={{
                          autoComplete: "new-password",
                          maxLength: 16,
                        }}
                        endAdornment={
                          <Index.InputAdornment
                            position="end"
                            className="pass_position"
                          >
                            <Index.IconButton
                              className="icon_end_icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.VisibilityOff className="pass-icon-color-admin" />
                              ) : (
                                <Index.Visibility className="pass-icon-color-admin" />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {errors?.password && touched?.password && (
                      <span className="auth-error-msg">{errors?.password}</span>
                    )}
                  </Index.Box>
                  <Index.Box className="cus-input-grp password-input-grp">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Confirm password
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control custom_form_control custom-form-input"
                        id="outlined-adornment-password"
                        sx={{ background: "red" }}
                        name="confirmPassword"
                        fullWidth
                        value={values.confirmPassword}
                        onBlur={(e) => {
                          handleBlur(e);
                          dispatch(activeShortCut(true));
                        }}
                        onFocus={(e) => {
                          dispatch(activeShortCut(false));
                        }}
                        onChange={handleChange}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        // error={Boolean(
                        //   errors.confirmPassword && touched.confirmPassword
                        // )}
                        placeholder="Enter confirm password"
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment
                            position="end"
                            className="pass_position"
                          >
                            <Index.IconButton
                              className="icon_end_icon"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              // onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Index.VisibilityOff className="pass-icon-color-admin" />
                              ) : (
                                <Index.Visibility className="pass-icon-color-admin" />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {errors?.confirmPassword && touched?.confirmPassword && (
                      <span className="auth-error-msg">
                        {errors?.confirmPassword}
                      </span>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.PrimaryButton
                  className="primary-btn login-btn custom-btn"
                  btnLabel="Register"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Index.Box>
            )}
          </Formik>
          <Index.Link className="forgot-link cus-link" to="/">
            Already have an account ? <span>Log In</span>
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Register;
