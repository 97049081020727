import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Index from "../../Index";

const usePreviousDateCalculator = (timeFrame) => {

  const holidays = [
    "2024-01-22",
    "2024-01-26",
    "2024-03-08",
    "2024-03-25",
    "2024-03-29",
    "2024-04-11",
    "2024-04-17",
    "2024-05-01",
    "2024-05-20",
    "2024-06-17",
    "2024-07-17",
    "2024-08-15",
    "2024-10-02",
    "2024-11-15",
    "2024-12-25",
  ];

  const isBusinessDay = (date) => {
    const day = date.day();
    const dateString = date.format("YYYY-MM-DD");
    return day !== 0 && day !== 6 && !holidays.includes(dateString);
  };

  const getTradingDaysAgoDate = (daysAgo) => {
    let currentDate = Index.moment();
    let businessDaysCount = 0;

    while (businessDaysCount < daysAgo) {
      currentDate.subtract(1, "days");
      if (isBusinessDay(currentDate)) {
        businessDaysCount++;
      }
    }

    currentDate.set({ hour: 9, minute: 15 });
    return currentDate.format("YYYY-MM-DD HH:mm");
  };

  return getTradingDaysAgoDate(timeFrame);
};

export default usePreviousDateCalculator;
