import {
  React,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Index from "../../Index";
import { useSelector } from "react-redux";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Modal from "../../../component/modal/Modal";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import _ from "lodash";
import {
  currentPageMinus,
  currentPagePlus,
  manageChartDrawingData,
  manageDownloadChartData,
  manageEraseDrawing,
  manageExitAllModal,
  manageTaggedStocksCount,
  manageTrackingActiveChartHoverData,
  pageSetting,
  setGridViewStore,
} from "../../../redux/user/action";
import Footer from "../../../component/DefaultLayout/Footer";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import DrawChartButton from "../../../component/modal/DrawChartButton";
import { DataService } from "../../../config/DataService";
import Constants from "../../../component/common/constants";
import OneColumnTrackingWindow from "./OneColumnTrackingWindow";

const chartInterval = {
  ONE_MINUTE: { interval: "1m", value: 1, offset: "15m" },
  FIVE_MINUTE: { interval: "5m", value: 5, offset: "15m" },
  TEN_MINUTE: { interval: "10m", value: 10, offset: "15m" },
  FIFTEEN_MINUTE: { interval: "15m", value: 15, offset: "15m" },
  THIRTY_MINUTE: { interval: "30m", value: 30, offset: "15m" },
  ONE_HOUR: { interval: "1h", value: 60, offset: "15m" },
  FOUR_HOUR: { interval: "4h", value: 180, offset: "15m" },
  ONE_DAY: { interval: "1d", value: 1440, offset: "0m" },
};

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const TrackingWindowCharts = (props) => {
  const dispatch = Index.useDispatch();
  const [allTagsData, setAllTagsData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const {
    token,
    displaySettings,
    graphType,
    chartDuration,
    chartView,
    categoryFilter,
    isActiveShortCut,
    isUserLogin,
    isExitAllModalOpen,
    pageSetting: pageSettingState,
    gridSpacing,
    gridView,
    otherSettings,
    twoSideArrowZoom,
    isDrawingButtonActive,
    isEraseDrawing,
    taggedStocksCount,
    gridBorder,
    userLoginDetail,
    currentPageRedux,
  } = useSelector((state) => state.UserReducer);
  console.log(currentPageRedux,"currentPageRedux")
  const abortControllers = useRef([]);
  const isMounted = useRef(true);
  const apiHasBeenCalled = useRef(null);
  const [scriptData, setScriptData] = useState([]);
  const [openModel, setOpenModel] = useState({});
  const [currenChart, setCurrenChart] = useState("");
  const [orderCurrentChart, setOrderCurrentChart] = useState("");
  const [updateOrder, setUpdateOrder] = useState({});
  const [placeOrderData, setPlaceOrderData] = useState({});
  const [editOrderData, setEditOrderData] = useState({});
  const [targetOrTopLoss, setTargetOrTopLoss] = useState();
  const [removedStocks, setRemovedStocks] = useState([]);
  const [chartLiveData, setChartLiveData] = useState(null);
  const [cursor, setCursor] = useState(-1);
  const [opacity, setOpacity] = useState(1);
  const [drawingChart, setDrawingChart] = useState(null);
  const [isExitAllOpen, setIsExitAllOpen] = useState(false);
  const [headMenu, setHeadMenu] = useState(false);
  const [tags, setTags] = useState("");
  const [displayDataLength, setDisplayDataLength] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const handleClose = () => {
    setOpenModel({});
  };
  const getTheme = localStorage.getItem("default-theme");

  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);

  const getAllStockTags = async () => {
    try {
      const response = await Index.DataService(userLoginToken).get(
        `${Index.Api.user.getAllTags}?open=true`
      );
      setAllTagsData(response?.data?.data);
      setScriptData(response?.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllStockTags();
  }, []);

  const handleStopLossTargetHit = (data) => {
    setAllTagsData((prev) =>
      prev?.map((item) => {
        if (JSON.parse(item?.token) == JSON.parse(data?.token)) {
          return {
            ...item,
            ...data,
          };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    if (props?.socket) {
      props?.socket.on(`hit_status_action-${userLoginDetail?._id}`, (data) => {
        if (data) {
          handleStopLossTargetHit(data);
        }
      });
      return () => {
        props?.socket.off(`hit_status_action-${userLoginDetail?._id}`, () => {});
      };
    }
  }, [props?.socket]);

  useEffect(() => {
    if (props.socket && allTagsData?.length) {
      allTagsData?.forEach((token) => {
        props.socket.emit("joinRoom", `token_${JSON.parse(token?.token)}`);

        props.socket.on(`token_${JSON.parse(token?.token)}`, (data) => {
          setChartLiveData(data);
        });
      });
    }
    return () => {
      allTagsData?.length &&
        allTagsData?.forEach((token) => {
          props.socket.emit("leaveRoom", `token_${token?.token}`);
        });
    };
  }, [props.socket, allTagsData]);

  const handleCategoryWiseFilter = useCallback(
    (ele) => {
      if (ele) {
        const exchangeType = ele?.exchange_type?.toString();
        return categoryFilter?.length
          ? categoryFilter?.includes(exchangeType)
          : ele;
      }
    },
    [categoryFilter]
  );

  const rows = useMemo(() => {
    return (
      allTagsData?.length &&
      allTagsData
        ?.filter(handleCategoryWiseFilter)
        ?.map((item, index, array) => {
          if (
            chartLiveData &&
            JSON.parse(item?.token) == JSON.parse(chartLiveData?.token)
          ) {
            item["livePrice"] = chartLiveData?.tick;
          }

          return {
            item,
            tag: item?.tag || "",
            entryPrice: item?.entryPrice || 0,
            token: item?.token,
            stockData: item?.stockData || [],
            timeStamp: item?.timeStamp,
            topLossAt: item?.topLossAt,
            targetAt: item?.targetAt,
            targetStatus: item?.targetStatus,
            stopLossStatus: item?.stopLossStatus,
            lastTradedPrice: item?.lastTradedPrice,
            exchange_type: item?.exchange_type,
            livePrice: item?.livePrice,
          };
        })
    );
  }, [allTagsData, chartLiveData, categoryFilter]);

  const removeTopLossAtOrTargetAt = async (token, status) => {
    try {
      const response = await Index.DataService(userLoginToken).post(
        "/user/stocks/remove-toploss-or-target",
        {
          token: JSON.parse(token),
          targetTag: status,
        }
      );
      // Index.toast.success("status updated");
      setTargetOrTopLoss();
      setEditOrderData({});
    } catch (error) {
      setTargetOrTopLoss();
      setEditOrderData({});
    }
  };

  function deleteLine() {
    // get the selected annotation
    if (orderCurrentChart) {
      let controller = orderCurrentChart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      let marker = orderCurrentChart.plot(0).textMarker(selectedAnnotation.id);
      marker.remove();
      controller.removeAnnotation(selectedAnnotation);
      if (targetOrTopLoss) {
        setTimeout(() => {
          removeTopLossAtOrTargetAt(editOrderData?.token, targetOrTopLoss);
        }, 1000);
      }
    }
  }

  //Shortcut Alt + t to set target
  const handleSetTarget = () => {
    const activeChart = document.querySelector(".active-chart");
    if (!activeChart) return;

    const token = activeChart.getAttribute("data");
    const chartData = allTagsData?.find(
      (item) => JSON.parse(item?.token) === JSON.parse(token)
    );

    if (orderCurrentChart && chartData) {
      const plot = orderCurrentChart.plot(0);
      const controller = plot.annotations();
      const annotations = controller
        .toJson()
        .annotationsList.filter((ele) => ele.type !== "marker");

      if (annotations.length < 3) {
        const currentPrice = chartData.livePrice[5];
        const price =
          chartData.tag === "Buy" ? currentPrice - 5 : currentPrice + 5;

        const line = controller
          .startDrawing("horizontal-line")
          .color("#00FF00");
        line.id = controller.getAnnotationsCount() - 1;

        plot
          .textMarker(line.id)
          .enabled(true)
          .text(price)
          .value(price)
          .align("right")
          .padding(3, 5)
          .anchor("left-center")
          .fontColor("#FFFFFF")
          .fontSize(10)
          .background("#00a12b")
          .zIndex(999);

        line.valueAnchor(price);

        const position = chartData.tag === "Buy" ? "arrow-up" : "arrow-down";

        setEditOrderData({
          targetAt: {
            targetTag: "targetAt",
            price: price,
            position,
            color: otherSettings?.["target"]?.color,
            token: JSON.parse(token),
          },
          token: JSON.parse(token),
        });
        setOpenModel({ [JSON.parse(chartData?.token)]: true });
      }

      // let anno = controller.getAnnotationAt(2)

      // controller.select(anno)
    }
  };

  //Shortcut Alt + l to set stop loss
  const handleSetStopLoss = () => {
    const activeChart = document.querySelector(".active-chart");
    if (!activeChart) return;

    const token = activeChart.getAttribute("data");
    const chartData = allTagsData?.find(
      (item) => JSON.parse(item?.token) === JSON.parse(token)
    );

    if (orderCurrentChart && chartData) {
      const plot = orderCurrentChart.plot(0);
      const controller = plot.annotations();
      const annotations = controller
        .toJson()
        .annotationsList.filter((ele) => ele.type !== "marker");

      if (annotations.length < 3) {
        const currentPrice = chartData.livePrice[5];
        const price =
          chartData.tag === "Buy" ? currentPrice + 5 : currentPrice - 5;

        let line = controller.startDrawing("horizontal-line").color("#FF0000");

        line.id = plot.annotations().getAnnotationsCount() - 1;

        plot
          .textMarker(line.id)
          .enabled(true)
          .text(price)
          .value(price)
          .align("right")
          .padding(3, 5)
          .anchor("left-center")
          .fontColor("#FFFFFF")
          .fontSize(10)
          .background("#FF0000")
          .zIndex(999);

        line.valueAnchor(price);

        const position = chartData.tag === "Buy" ? "arrow-down" : "arrow-up";

        setEditOrderData({
          topLossAt: {
            targetTag: "topLossAt",
            price: price,
            position,
            color: otherSettings?.["stopLoss"]?.color,
            token: JSON.parse(token),
          },
          token: JSON.parse(token),
        });
        setOpenModel({ [JSON.parse(chartData?.token)]: true });
      }
    }
  };

  function handleActiveCursorAction() {
    setCursor(-1);
  }

  const fromDate = usePreviousDateCalculator(
    chartView === "Intraday" ? 1 : chartDuration[chartView]?.timeFrame - 1
  );

  const removeStockTag = async (t, token, currentPrice) => {
    try {
      let data = {
        token: JSON.parse(token),
        tag: t,
      };
      if (currentPrice) {
        data["lastTradedPrice"] = currentPrice;
      }
      await Index.DataService(userLoginToken).post(
        Index.Api.user.removeStock,
        data
      );
      if (props?.socket) {
        props?.socket.emit("stock_action", "Action taken");
      }
      const annotationData = {
        token: JSON.parse(token),
        data: [],
        chartType: chartView,
        interval: Constants.timeFrames[chartDuration[chartView]?.interval],
      };
      handleUpdateAnnotation(annotationData);
      setAllTagsData((prev) => {
        const showDataLength = prev
          ?.filter((item) => JSON.parse(item?.token) !== JSON.parse(token))
          ?.slice(
            pageSettingState?.page * pageSettingState?.rowsPerPage -
              pageSettingState?.rowsPerPage,
            pageSettingState?.page * pageSettingState?.rowsPerPage
          );

        if (showDataLength?.length == 0 && currentPageRedux > 1) {
          dispatch(
            pageSetting({
              ...pageSettingState,
              page: currentPageRedux - 1,
            })
          );
        }
        return prev?.filter(
          (item) => JSON.parse(item?.token) !== JSON.parse(token)
        );
      });

      getStockTagsCount();
    } catch (error) {
      // toast.success("Tag updated");
    }
  };

  const getStockTagsCount = async () => {
    try {
      const response = await Index.DataService(userLoginToken).get(
        Index.Api.user.stockTagCounts
      );
      dispatch(manageTaggedStocksCount(response.data.data));
    } catch (error) {}
  };

  useEffect(() => {
    getStockTagsCount();
  }, []);

  const handleDisplayDataLength = () => {
    const data = gridView[chartView]?.row * gridView[chartView]?.column;
    setDisplayDataLength(data);
  };

  useEffect(() => {
    handleDisplayDataLength();
  }, [gridView[chartView]]);

  useEffect(() => {
    setPageData([]);
    const indexOfLastRow =
      pageSettingState?.page * pageSettingState?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSettingState?.rowsPerPage;
    const modifiedRows = allTagsData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    // setCursor(-1);
  }, [pageSettingState, allTagsData, chartView]);

  //Four arrow functionality
  const handleKeyDown = (e) => {
    setPageData((el) => {
      if (e.keyCode === 38) {
        setCursor((e) =>
          e >= gridView[chartView]?.row ? e - gridView[chartView]?.row : e
        );
      } else if (e.keyCode === 40) {
        setCursor((cursor) =>
          cursor < 0
            ? 0
            : cursor < el.length - 1 &&
              el?.length > cursor + gridView[chartView]?.row
            ? cursor + gridView[chartView]?.row
            : cursor
        );
      } else if (e.keyCode === 37) {
        if (cursor >= 0) {
          setCursor((e) => (e - 1 === -1 ? e : e - 1));
        } else {
          dispatch(currentPageMinus());
        }
      } else if (e.keyCode === 39) {
        if (cursor >= 0) {
          setCursor((cursor) =>
            cursor < el.length - 1 && cursor !== null ? cursor + 1 : cursor
          );
        } else {
          dispatch(currentPagePlus());
        }
      }

      return el;
    });
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isActiveShortCut, isUserLogin, gridView, cursor, pageSettingState?.page]);

  //Get Annotation
  const getAllAnnotations = useCallback(async () => {
    try {
      const response = await DataService(token).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: false,
            interval: Constants.timeFrames[chartDuration[chartView]?.interval],
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  }, [chartView, chartDuration]);

  useEffect(() => {
    getAllAnnotations();
  }, [chartView, chartDuration]);

  //Update Annotation
  const handleUpdateAnnotation = async (data) => {
    try {
      const response = await DataService(token, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        { ...data, isDashboardAnnotations: false }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  function removeAnnotationOnDeletePress() {
    // get the selected annotation
    if (drawingChart?.chart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      if (selectedAnnotation) {
        const updatedAnnotations = controller
          .removeAnnotation(selectedAnnotation)
          .toJson()?.annotationsList;

        let annotationData = {
          token: JSON.parse(drawingChart?.token),
          data: updatedAnnotations,
          chartType: chartView,
          interval: Constants.timeFrames[chartDuration[chartView]?.interval],
        };

        handleUpdateAnnotation(annotationData);
      }
    }
  }

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    headerRemoveTargetOrStopLoss: deleteLine,
    handleEscapeModalOff: handleActiveCursorAction,
    handleSetTarget,
    handleSetStopLoss,
    removeAnnotationOnDeletePress,
  });

  //Erase single chart drawing
  function handleEraseDrawing() {
    if (drawingChart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let clearAnnotation = controller.removeAllAnnotations();
      let dataToRemove = clearAnnotation.toJson()?.annotationsList;
      let annotationData = {
        token: JSON.parse(drawingChart?.token),
        data: dataToRemove,
        chartType: chartView,
        interval: Constants.timeFrames[chartDuration[chartView]?.interval],
      };
      handleUpdateAnnotation(annotationData);
      dispatch(manageEraseDrawing(false));
    }
  }

  useEffect(() => {
    if (isEraseDrawing) {
      handleEraseDrawing();
    }
  }, [isEraseDrawing]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(
          setGridViewStore({ ...gridView, [chartView]: { column: 2, row: 1 } })
        );
      } else {
        dispatch(
          setGridViewStore({ ...gridView, [chartView]: { column: 1, row: 1 } })
        );
      }
    };

    if (window.innerWidth < 992) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      let mainGridHeight = document.getElementsByClassName(
        "dashboard-chart-main"
      )?.[0]?.clientHeight;

      let chartHeaderHeight =
        document.getElementsByClassName("order-header-main")?.[0]?.clientHeight;

      setChartHeight(
        (mainGridHeight -
          (chartHeaderHeight ?? 0) * gridView[chartView]?.column -
          (gridSpacing * 8 * (gridView[chartView]?.column - 1) + 8) -
          0) /
          gridView[chartView]?.column
      );
    },
    [document.getElementsByClassName("order-header-main")?.[0]?.clientHeight],
    gridView[chartView]
  );

  return (
    <>
      <Index.Box className="dashboard-main tracking-window-chart tracking-window">
        {isDrawingButtonActive && (
          <DrawChartButton
            newChart={drawingChart && drawingChart?.chart}
            token={drawingChart && drawingChart?.token}
            setNewChart={setDrawingChart}
          />
        )}
        <Index.Box className="dashboard-wrapper">
          <Index.Overlay></Index.Overlay>
          <Index.Box
            className={`main-content-sec dashboard-main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Index.Box className="tracking-chart-sub-header">
              <Index.Box className="tracking-sub-btn-main">
                <Index.PrimaryButton
                  className="primary-btn header-btn"
                  btnLabel="Exit All"
                  onClick={() => dispatch(manageExitAllModal())}
                />
              </Index.Box>
              <Index.Box className="trade-btn-flex">
                <Index.Box className="trade-btn bg-green">
                  {taggedStocksCount?.buyTag || 0}
                </Index.Box>
                <Index.Box className="trade-btn bg-red">
                  {taggedStocksCount?.sellTag || 0}
                </Index.Box>
                <Index.Box className="trade-btn bg-blue">
                  {taggedStocksCount?.watchTag || 0}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {rows?.length ? (
              <>
                {chartView === "Intraday" || chartView === "Positional" ? (
                  <Index.Grid
                    container
                    spacing={gridSpacing}
                    className={`chart-main dashboard-chart-main dashboard-chart-mobile ${
                      twoSideArrowZoom && "dashboard-chart-main-zoom"
                    } `}
                  >
                    {rows?.length
                      ? rows
                          ?.slice(
                            pageSettingState?.page *
                              pageSettingState?.rowsPerPage -
                              pageSettingState?.rowsPerPage,
                            pageSettingState?.page *
                              pageSettingState?.rowsPerPage
                          )
                          ?.map((item, index, array) => {
                            return (
                              // <Index.Box key={item?.token}>
                              <OneColumnTrackingWindow
                                key={item?.token}
                                index={index}
                                item={item}
                                token={JSON.parse(item?.token)}
                                length={pageSettingState?.rowsPerPage}
                                active={index === cursor}
                                selectedId={index === cursor ? index : null}
                                setOpenModel={setOpenModel}
                                openModel={openModel}
                                deleteLine={deleteLine}
                                handleClose={handleClose}
                                updateOrder={updateOrder}
                                setUpdateOrder={setUpdateOrder}
                                editOrderData={editOrderData}
                                setEditOrderData={setEditOrderData}
                                removeStockTag={removeStockTag}
                                placeOrderData={placeOrderData}
                                setPlaceOrderData={setPlaceOrderData}
                                currenChart={currenChart}
                                setCurrenChart={setCurrenChart}
                                setTargetOrTopLoss={setTargetOrTopLoss}
                                orderCurrentChart={orderCurrentChart}
                                setOrderCurrentChart={setOrderCurrentChart}
                                setDrawingChart={setDrawingChart}
                                chartLiveData={chartLiveData}
                                setAllTagsData={setAllTagsData}
                                scriptData={scriptData}
                                setScriptData={setScriptData}
                                fromDate={fromDate}
                                live={chartView === "Intraday"}
                                socket={props.socket}
                              />
                              // </Index.Box>
                            );
                          })
                      : ""}
                    {rows?.length
                      ? Array.from(
                          {
                            length:
                              pageSettingState?.rowsPerPage -
                              rows?.slice(
                                pageSettingState?.page *
                                  pageSettingState?.rowsPerPage -
                                  pageSettingState?.rowsPerPage,
                                pageSettingState?.page *
                                  pageSettingState?.rowsPerPage
                              )?.length,
                          },
                          (_, index) => (
                            <Index.Grid
                              item
                              xs={gridView[chartView]?.column}
                              key={index}
                            >
                              <Index.Box sx={{ height: chartHeight }} />
                            </Index.Grid>
                          )
                        )
                      : ""}
                  </Index.Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Index.Box className="loader-box">No record found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Footer pageData={rows} apiHasBeenCalled={apiHasBeenCalled} />
      <Modal
        show={isExitAllModalOpen}
        setShow={() => dispatch(manageExitAllModal())}
        title={"Exit From Stock"}
        x={window?.innerWidth / 2 - 180}
        y={window?.innerHeight / 2 - 130}
        opacity={opacity}
        SVG={
          getTheme === "Medium"
            ? Index.Svg.settingsSolid
            : getTheme === "Light"
            ? Index.Svg.settingsLight
            : Index.Svg.settingsDark
        }
        setOpacity={setOpacity}
        width={"407px"}
        html={
          <ExitStockModal
            setShowExitModal={() => dispatch(manageExitAllModal())}
            getAllTagsData={getAllStockTags}
            setRemovedStocks={setAllTagsData}
            allTagsData={allTagsData}
            getStockTagsCount={getStockTagsCount}
            socket={props?.socket}
          />
        }
      />
    </>
  );
};

export default memo(TrackingWindowCharts);

const ExitStockModal = ({
  setShowExitModal,
  setRemovedStocks,
  allTagsData,
  getStockTagsCount,
  socket,
}) => {
  const { token } = useSelector((state) => state.UserReducer);
  const allStockTypes = [
    { id: 1, name: "Equity", value: "NSE" },
    { id: 2, name: "F&O", value: "NFO" },
    { id: 3, name: "Commodity", value: "MCX" },
  ];

  //Validation
  const validationSchemaExitStock = yup.object().shape({
    category: yup.string().required("Please select category"),
  });

  const handleExitFromStock = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("exchange_type", values?.category);
    Index.DataService(token)
      .post(Index.Api.user.exitStocks, urlencoded)
      .then((data) => {
        if (data?.data?.status === 200) {
          let tokensList = allTagsData?.map((ele) => {
            if (ele?.exchange_type == values?.category) {
              return JSON.parse(ele?.token);
            }
          });
          setRemovedStocks((prev) =>
            prev?.filter(
              (item) => !tokensList?.includes(JSON.parse(item?.token))
            )
          );
          if (socket) {
            socket.emit("stock_action", "Action taken");
          }
          getStockTagsCount();
        }
      })
      .catch((error) => {
        // setShowExitModal(false);
      });
    setShowExitModal(false);
  };
  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Formik
            validationSchema={validationSchemaExitStock}
            initialValues={{ category: "" }}
            onSubmit={handleExitFromStock}
          >
            {({ values, errors, handleBlur, handleChange, touched }) => (
              <Form>
                <Index.Box className="name-wrap name-warp-tracking-window">
                  <Index.Box className="cus-input-grp exit-stock-modal-box">
                    <Index.Typography
                      className="cus-lable light-font"
                      variant="p"
                      component="p"
                    >
                      Category
                    </Index.Typography>
                    <Index.Box className="form-group">
                      <Index.FormControl className="form-control-user drop-form-control">
                        <Index.Select
                          fullWidth
                          name="category"
                          className="form-control"
                          displayEmpty
                          value={values?.category}
                          placeholder="User Type"
                          // onChange={handleChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {values?.category === "" ||
                          values?.category === undefined ? (
                            <Index.MenuItem
                              disabled
                              value=""
                              className="form-control exit-catg-option"
                            >
                              Category
                            </Index.MenuItem>
                          ) : null}
                          {allStockTypes?.map((ele) => {
                            return (
                              <Index.MenuItem
                                key={ele?.id}
                                value={ele?.value}
                                // name="admin"
                                className="drop-menuitem game-item"
                              >
                                {ele?.name}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormHelperText error className="error-text">
                        {errors.category && touched.category
                          ? errors.category
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.PrimaryButton
                    className="primary-btn save-changes-btn tracking-window"
                    btnLabel="Exit"
                    type="submit"
                  />
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};
