import React, { useEffect, useRef, useState, useMemo } from "react";
import Index from "../Index";
import Draggable from "react-draggable";
import { Slider } from "@mui/material";
import useKeyboardShortcut from "../common/keyboard-shortcuts/useKeyboardShortcut";
import { useDispatch } from "react-redux";
import { activeShortCut, manageModalParameter } from "../../redux/user/action";

// const Separator = styled("div")(
//   ({ theme }) => `
//     height: ${theme.spacing(2)};
//   `
// );

const Modal = ({
  title,
  show,
  x,
  y,
  setShow,
  html,
  width,
  height,
  opacity,
  setOpacity,
  SVG,
  position,
  isNotificationData,
  className
}) => {
  const getTheme = localStorage.getItem("default-theme");
  const dispatch = useDispatch();
  const [isActive, SetIsActive] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [tabPosition, setTabPosition] = useState(false);
  const [isColorPicking, setIsColorPicking] = useState(false);
  const elementRef = useRef(null);
  const handleClose = () => {
    setIsMobile(false);
    SetIsActive(true);
    setTabPosition(false);
    setShow(!show);
    dispatch(manageModalParameter({}));
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  const handleEscapeModalOff = () => {
    const demoModal = document.getElementById("main-modal");
    if (demoModal) {
      demoModal.click();
      dispatch(activeShortCut(true));
    }
  };
  const isModelOPen = useMemo(() => {
    return show;
  }, [show]);

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    handleEscapeModalOff,
  });
  const updatePosition = () => {
    if (elementRef.current) {
      const elementWidth = elementRef.current.offsetWidth;
      const elementHeight = elementRef.current.offsetHeight;
      if (window.innerWidth >= 500 && window.innerWidth <= 767) {
        setTabPosition(true);
      } else {
        setTabPosition(false);
      }
      if (window.innerWidth <= 900) {
        const centerX = (window.innerWidth - elementWidth) / 2;
        const centerY = (window.innerHeight - elementHeight) / 2;
        dispatch(manageModalParameter({ x: centerX, y: centerY }));
      }
    }
  };
  const handleResize = () => {
    if (window.innerWidth <= 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    updatePosition();
  };

  useEffect(() => {
    if (show) {
      handleResize();
    }
  }, [show, isNotificationData, Window.innerWidth]);

  useEffect(() => {
    const isPopOver = document.querySelector(".MuiColorInput-PopoverBody");

    if (isPopOver) {
      setIsColorPicking(true);
    } else {
      setIsColorPicking(false);
    }
  }, [document.querySelector(".MuiColorInput-PopoverBody")]);

  return (
    <>
      {isModelOPen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          onClick={handleOverlayClick}
          id="main-modal"
        >
          <Draggable
            defaultPosition={{ x, y }}
            position={isMobile || tabPosition ? position : null}
            cancel=".test"
            disabled={(isMobile || isColorPicking)}
          >
            <Index.Box
              className="handle"
              ref={elementRef}
              style={{ opacity: opacity + 0.2 || 1 }}
            >
              <Index.Box className={`${!isModelOPen ? "hide-modal" : ""}`}>
                <Index.Box className="modal-wrapper" style={{ width, height }}>
                  <Index.Box className="modal-header cus-between">
                    <Index.Box className="model-header-left cus-vertical-center">
                      {SVG && (
                        <img
                         
                          className={`${className} setting-ic`}
                          alt="settingsolid"
                          width={20}
                          src={SVG}
                        />
                      )}
                      <h6>{title}</h6>
                    </Index.Box>
                    <Index.Box className="model-header-right cus-vertical-center">
                      <Index.Box
                        // className={`btn-bg opacity-btn test slider-modal-icon${
                        //   title == "Recent Index"
                        //     ? "opacity-btn-recent-box"
                        //     : ""
                        // }`}
                        className={`btn-bg opacity-btn test slider-modal-icon`}
                      >
                        <p>Opacity</p>
                        <Index.Box sx={{ width: 55 }}>
                          <Slider
                            aria-label="Temperature"
                            color="secondary"
                            step={25}
                            value={opacity * 100 + 20 || 100}
                            onChange={(e) => {
                              setOpacity(e.target.value / 100);
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        className="btn-bg cus-center modal-close-btn minimize-btn"
                        onClick={() => SetIsActive(!isActive)}
                      >
                        {/* <img
                      className="minus-btn-ic"
                      alt="minus"
                      src={Index.Svg.minus}
                    /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.917"
                          height="1.21"
                          viewBox="0 0 13.917 1.21"
                        >
                          <g id="minus" transform="translate(-6.75 -22.5)">
                            <path
                              // className={`modal-minus-svg ${
                              //   title == "Recent Index"
                              //     ? "modal-minus-svg-recent-box"
                              //     : ""
                              // }`}
                              className={`modal-minus-svg modal-icon-fill`}
                              id="Path_237"
                              data-name="Path 237"
                              d="M6.75,22.5H20.667v1.21H6.75Z"
                              fill="#f8d905"
                            />
                          </g>
                        </svg>
                      </Index.Box>
                      <Index.Box
                        className="btn-bg cus-center modal-close-btn"
                        onClick={() => handleClose()}
                      >
                        {/* <img
                      className="close-btn-ic"
                      alt="close"
                      src={Index.Svg.close}
                    /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12.713"
                          height="12.713"
                          viewBox="0 0 12.713 12.713"
                        >
                          <path
                            className={`modal-header-close-svg modal-icon-fill`}
                            id="Path_236"
                            data-name="Path 236"
                            d="M24.713,13.112,23.6,12l-5.244,5.244L13.112,12,12,13.112l5.244,5.244L12,23.6l1.112,1.112,5.244-5.244L23.6,24.713,24.713,23.6l-5.244-5.244Z"
                            transform="translate(-12 -12)"
                            fill="#ff0078"
                          />
                        </svg>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  {isActive && (
                    <Index.Box className="modal-body">{html}</Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Draggable>
        </div>
      )}
    </>
  );
};

export default Modal;
