import axios from "axios";

// //Local
// const API_ENDPOINT = `http://localhost:3018/api`;
// export const IMAGE_URL = `http://localhost:3018/api/images/`;
// export const SOCKET_URL = "http://localhost:3018";

//AppWorkDemo
const API_ENDPOINT = `https://stock-charting.appworkdemo.com/api`;
export const IMAGE_URL = `https://stock-charting.appworkdemo.com/api/images/`;
export const SOCKET_URL = "https://socket-stock-charting.appworkdemo.com";

export const DataService = (token, contentType) => {
  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      "Content-Type": contentType ?? "application/x-www-form-urlencoded",
      auth: token ? token : "",
    },
  });
};
