import logo from '../assets/images/png/logo.png';
import graph from '../assets/images/png/graph.png';
import lineChart from '../assets/images/png/lineChart.png';
import lineChartLight from '../assets/images/png/lineChartLight.png';
import lineChartDark from '../assets/images/png/lineChartDark.png';
import AreaChart from '../assets/images/png/AreaChart.png';
import AreaChartLight from '../assets/images/png/AreaChartLight.png';
import AreaChartDark from '../assets/images/png/AreaChartDark.png';
import dottedChart from '../assets/images/png/dottedChart.png';
import dottedChartLight from '../assets/images/png/dottedChartLight.png';
import dottedChartDark from '../assets/images/png/dottedChartDark.png';
import candelChart from '../assets/images/png/candelChart.png';
import candelChartLight from '../assets/images/png/candelChartLight.png';
import candelChartDark from '../assets/images/png/candelChartDark.png';
import alert from '../assets/images/png/alert.png';
import roundChart from '../assets/images/png/roundChart.png';
import roundChartBlue from '../assets/images/png/roundChartBlue.png';
import menuLogo from '../assets/images/png/menu-logo.png';
import menuLogoWhite from '../assets/images/png/menu-logo-white.png';
import notification from '../assets/images/png/notification.png'

const Png = {
      logo,
      graph,
      lineChart,
      lineChartLight,
      lineChartDark,
      AreaChart,
      AreaChartLight,
      AreaChartDark,
      dottedChart,
      dottedChartLight,
      dottedChartDark,
      candelChart,
      candelChartLight,
      candelChartDark,
      alert,
      roundChart,
      roundChartBlue,
      menuLogo,
      menuLogoWhite,
      notification,
}

export default Png;