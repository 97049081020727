import { useState, useEffect } from 'react';
import moment from 'moment';

const useCurrentTime = () => {
  const [currentTime , setCurrentTime] = useState(moment().format('HH:mm:ss'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format('HH:mm:ss'));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return currentTime;
};

export default useCurrentTime;