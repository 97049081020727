import {
  React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Index from "../../Index";
import OneColumn from "./OneColumn";
import { SocketContext } from "../../../theme/ThemeContext";
import {
  currentPagePlus,
  currentPageMinus,
  manageChartDrawingData,
  setGridViewStore,
  manageEraseDrawing,
} from "../../../redux/user/action";
import Footer from "../../../component/DefaultLayout/Footer";
import DrawChartButton from "../../../component/modal/DrawChartButton";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import { DataService } from "../../../config/DataService";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import _ from "lodash";
import Constants from "../../../component/common/constants";

const Dashboard = (props) => {
  const {
    gridSpacing,
    gridBorder,
    currentTag,
    wishListData,
    token,
    chartView,
    liveData,
    gridView,
    pageSetting,
    isActiveShortCut,
    isUserLogin,
    displaySettings,
    isDrawingButtonActive,
    isFlip,
    chartDuration,
    graphType,
    selectedChart,
    twoSideArrowZoom,
    isEraseDrawing,
    wishListDataTemp,
  } = Index.useSelector((state) => state.UserReducer);
  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const abortControllers = useRef([]);
  const isMounted = useRef(true);
  const [openLineChart, setOpenLineChart] = useState(false);
  const [openAreaChart, setOpenAreaChart] = useState(false);
  const [openCandleChart, setOpenCandleChart] = useState(false);
  const [chartIndex, setChartIndex] = useState(false);
  const [scriptData, setScriptData] = useState({});
  const [wishListDataState, setWishListDataState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [oldData, setOldData] = useState({});
  const [loader, setLoader] = useState(true);
  const [cursor, setCursor] = useState(-1);
  const [gridV, setGridV] = useState({
    row: 0,
    column: 0,
  });

  const [drawingChart, setDrawingChart] = useState(null);
  const [chartHeight, setChartHeight] = useState(0);

  const fromDate = usePreviousDateCalculator(
    chartView === "Intraday" ? 0 : chartDuration[chartView]?.timeFrame - 1
  );

  const handleOpenLineChart = (index) => {
    setOpenLineChart(true);
    setChartIndex(index);
  };
  const handleCloseLineChart = () => {
    setOpenLineChart(false);
    setChartIndex();
  };
  const handleOpenAreaChart = (index) => {
    setOpenAreaChart(true);
    setChartIndex(index);
  };
  const handleCloseAreaChart = () => {
    setOpenAreaChart(false);
    setChartIndex();
  };
  const handleOpenCandleChart = (index) => {
    setOpenCandleChart(true);
    setChartIndex(index);
  };
  const handleCloseCandleChart = () => {
    setOpenCandleChart(false);
    setChartIndex();
  };

  // const getAllHistoryData = async(live, loopIndex, controller, dataLength) => {
  //   const tokens = dataLength?.map((item) => JSON.parse(item?.token));
  //   const intervalData = Constants.chartInterval[chartDuration[chartView]?.interval];
  //   const minutesToLess = intervalData?.value * 60000;

  //   const formData = {
  //     tokens: tokens,
  //     interval: intervalData?.interval,
  //     fromDate: live
  //       ? Index.moment()
  //           .set({ hour: 9, minute: 15 })
  //           .format("YYYY-MM-DDTHH:mm:00") + "Z"
  //       : Index.moment(fromDate, "YYYY-MM-DD HH:mm").format(
  //           "YYYY-MM-DDTHH:mm:00"
  //         )+ "Z",
  //     toDate: Index.moment().format("YYYY-MM-DDTHH:mm:00") + "Z",
  //     offset: intervalData?.offset,
  //   };
  //   const formDataIntraday = {
  //     tokens: tokens,
  //     interval: intervalData?.interval,
  //     fromDate: Index.moment()
  //           .set({ hour: 9, minute: 15 })
  //           .format("YYYY-MM-DDTHH:mm:00") + "Z",
  //     toDate: Index.moment().format("YYYY-MM-DDTHH:mm:00") + "Z",
  //     offset: intervalData?.offset,
  //   };
  //   try {
  //     const apiCalls = live ? [
  //       Index.DataService(token).post(Index.Api.user.getIntradayData, formDataIntraday, { signal: controller.signal }),
  //     ] : [
  //       Index.DataService(token).post(Index.Api.user.getAllHistoricalData, formData, { signal: controller.signal }),
  //       Index.DataService(token).post(Index.Api.user.getIntradayData, formDataIntraday, { signal: controller.signal }),
  //     ];
  //   const [intradayData,historicalData] = await Promise.all(apiCalls);

  //   const combinedData = [...historicalData?.data?.data || [], ...intradayData?.data?.data]?.reduce((acc, item) => {
  //     const key = Object.keys(item)[0];
  //     if (!acc[key]) {
  //       acc[key] = item[key];
  //     } else {
  //       acc[key] = [...acc[key], ...item[key]];
  //     }
  //     return acc;
  //   }, {});

  //   const formattedCombinedData = Object.keys(combinedData)?.map(key => ({
  //     [key]: combinedData[key],
  //   }));

  //   if (formattedCombinedData.length) {
  //     setFilterData(prevData =>
  //       prevData?.map(ele => {
  //         const token = JSON.parse(ele?.token);
  //         const item = formattedCombinedData.find(data => data[token]);

  //         if (item && item[token]?.length) {
  //           const format = item[token].map(el =>
  //             live
  //               ? [
  //                   Index.moment(el[0]).startOf('minute').valueOf() - minutesToLess,
  //                   +el[1],
  //                   +el[2],
  //                   +el[3],
  //                   +el[4],
  //                   +el[4],
  //                 ]
  //               : [
  //                   Index.moment(el[0]).startOf('minute').valueOf() - minutesToLess,
  //                   +el[1],
  //                   +el[2],
  //                   +el[3],
  //                   +el[4],
  //                   +el[5],
  //                 ]
  //           );
  //           return { ...ele, historicalStockData: format };
  //         } else {
  //           return ele;
  //         }
  //       })
  //     );
  //   }
  //   } catch (error) {

  //   }
  // };

  // const callAllHistorical = async () => {
  //   abortControllers.current.forEach((controller) => controller.abort());
  //   abortControllers.current = [];
  //   const chunckData = _.chunk(wishListData, 2);

  //   for (let index = 0; index < chunckData?.length; index++) {
  //     if (!isMounted.current) break;
  //     const controller = new AbortController();
  //     abortControllers.current.push(controller);
  //     getAllHistoryData(
  //       chartView === "Intraday",
  //       index,
  //       controller,
  //       chunckData[index]
  //     );
  //     await delay(500);
  //   }
  // };

  // useEffect(() => {
  //   isMounted.current = true;
  //   callAllHistorical();
  //   return () => {
  //     abortControllers.current.forEach((controller) => controller.abort());
  //     isMounted.current = false;
  //   };
  // }, [wishListData, chartDuration, graphType[chartView], chartView]);

  //Render Data
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    setWishListDataState(wishListData); //Grid load restrict
    // setWishListDataState(filterData);
  }, [wishListData]); //Grid load restrict
  // }, [filterData]);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setCursor(-1);
      setLoader(false);
    }, 1000);
  }, [wishListData, chartView, currentTag]);

  useEffect(() => {
    setPageData([]);
    const indexOfLastRow = pageSetting?.page * pageSetting?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSetting?.rowsPerPage;
    const modifiedRows = wishListData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    setTimeout(() => {
      setCursor(-1);
    }, 1000);
  }, [pageSetting, wishListData, chartView, currentTag]);

  useEffect(() => {
    const indexOfLastRow = pageSetting?.page * pageSetting?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSetting?.rowsPerPage;
    const modifiedRows = wishListData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    setFilterData(wishListData);
    setTimeout(() => {
      setCursor(-1);
      setLoader(false);
    }, 1000);
  }, [wishListData, chartView]);

  //Add to favorite
  const handleAddToFavorite = async (favoriteToken) => {
    const favoriteData = wishListData?.find(
      (ele) => JSON.parse(ele?.token) == JSON.parse(favoriteToken)
    );

    if (favoriteData) {
      try {
        let data = {
          token: favoriteData?.token,
          stock_name: favoriteData?.name,
          tradeType: chartView,
          exchange_type: favoriteData?.exch_seg,
        };
        const response = await Index.DataService(token).post(
          Index.Api.user.aadFavoriteTag,
          data
        );

        // toast.success(response?.data?.message);
      } catch (error) {
        if (error?.response?.data?.status === 409) {
          // toast.success(error?.response?.data?.message);
        }
      }
    }
  };

  //Remove from favorite
  const handleRemoveFromFavorite = async (favoriteToken) => {
    try {
      const response = await Index.DataService(token).post(
        Index.Api.user.removeFavoriteTag,
        { token: favoriteToken }
      );

      // toast.success(response?.data?.message);
    } catch (error) {}
  };

  const handleKeyDown = (e) => {
    // setFilterData((el) => {
    setPageData((el) => {
      setGridV((grid) => {
        if (e.keyCode === 38) {
          setCursor((e) =>
            e >= gridView[chartView]?.row ? e - gridView[chartView]?.row : e
          );
          // setCursor((e) =>
          //   e - 1 <= 0
          //     ? e
          //     : e >
          //         gridView[chartView]?.row * gridView[chartView]?.column * (pageSetting?.page - 1) &&
          //       e >= gridView[chartView]?.row * gridView[chartView]?.column * (pageSetting?.page - 1)
          //     ? e - gridView[chartView]?.row
          //     : e
          // );
        } else if (e.keyCode === 40) {
          setCursor((cursor) =>
            cursor < 0
              ? 0
              : cursor < el.length - 1 &&
                el?.length > cursor + gridView[chartView]?.row
              ? cursor + gridView[chartView]?.row
              : cursor
          );
          // setCursor((cursor) =>
          //   cursor < 0
          //     ? gridView[chartView]?.row * gridView[chartView]?.column * pageSetting?.page -
          //       gridView[chartView]?.row * gridView[chartView]?.column
          //     : cursor <
          //         gridView[chartView]?.row * gridView[chartView]?.column * pageSetting?.page - 1 &&
          //       gridView[chartView]?.row * gridView[chartView]?.column * pageSetting?.page >
          //         cursor + gridView[chartView]?.row
          //     ? cursor + gridView[chartView]?.row
          //     : cursor
          // );
        } else if (e.keyCode === 37) {
          if (cursor >= 0) {
            setCursor((e) => (e - 1 === -1 ? e : e - 1));
            // setCursor((e) =>
            //   e ==
            //   gridView[chartView]?.row * gridView[chartView]?.column * pageSetting?.page -
            //     gridView[chartView]?.row * gridView[chartView]?.column
            //     ? e
            //     : e - 1
            // );
          } else {
            dispatch(currentPageMinus());
          }
          // setCursor((cursor)=>cursor-grid.row>=0?cursor-grid.row:cursor)
        } else if (e.keyCode === 39) {
          if (cursor >= 0) {
            setCursor((cursor) =>
              cursor < el.length - 1 && cursor !== null ? cursor + 1 : cursor
            );
            // setCursor((cursor) =>
            //   cursor <
            //     gridView[chartView]?.row * gridView[chartView]?.column * pageSetting?.page - 1 &&
            //   cursor !== null
            //     ? cursor + 1
            //     : cursor
            // );
          } else {
            dispatch(currentPagePlus());
          }
          // setCursor((cursor)=>cursor+grid.row)
        } else if (e.keyCode === 46) {
          // setCursor((cursor) => {
          //   let token = window.document
          //     .getElementById(`${`active-chart-${cursor}`}`)
          //     .getAttribute("data");
          //   dispatch(deleteWishListData(token));
          //   setScriptData((prevScriptData) => {
          //     let obj = { ...prevScriptData };
          //     delete obj[token];
          //     return obj;
          //   });
          //   return cursor;
          // });
        } else if (e.keyCode === 70) {
          //Add to Favorite list -- press(F)
          setCursor((cursor) => {
            let token = window?.document
              ?.getElementById(`${`active-chart-${cursor}`}`)
              ?.getAttribute("data");

            handleAddToFavorite(token);
            return cursor;
          });
        } else if (e.keyCode === 82) {
          //Remove From Favorite list --(Press R)
          setCursor((cursor) => {
            let token = window.document
              ?.getElementById(`${`active-chart-${cursor}`}`)
              ?.getAttribute("data");

            handleRemoveFromFavorite(token);
            return cursor;
          });
        }
        return grid;
      });

      return el;
    });
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      // window.addEventListener("keyup", upKeyHandler);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        // window.removeEventListener("keyup", upKeyHandler);
      };
    }
  }, [isActiveShortCut, isUserLogin, gridView, cursor, pageSetting?.page]);

  useEffect(() => {
    setGridV(gridView);
  }, [gridView]);

  //Get Annotation
  const getAllAnnotations = useCallback(async () => {
    try {
      const response = await DataService(token).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: true,
            interval: Constants.timeFrames[chartDuration[chartView]?.interval],
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  }, [chartView, chartDuration]);

  useEffect(() => {
    getAllAnnotations();
  }, [chartView, chartDuration]);

  //Update Annotation
  const handleUpdateAnnotation = async (data) => {
    try {
      const response = await DataService(token, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        { ...data, isDashboardAnnotations: true }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  function removeAnnotationOnDeletePress() {
    // get the selected annotation
    if (drawingChart?.chart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      if (selectedAnnotation) {
        const updatedAnnotations = controller
          .removeAnnotation(selectedAnnotation)
          .toJson()?.annotationsList;

        let annotationData = {
          token: JSON.parse(drawingChart?.token),
          data: updatedAnnotations,
          chartType: chartView,
          interval: Constants.timeFrames[chartDuration[chartView]?.interval],
        };

        handleUpdateAnnotation(annotationData);
      }
    }
  }

  function handleActiveCursorAction() {
    setCursor(-1);
  }

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    removeAnnotationOnDeletePress,
    handleEscapeModalOff: handleActiveCursorAction,
  });

  //Erase single chart drawing
  function handleEraseDrawing() {
    if (drawingChart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let clearAnnotation = controller.removeAllAnnotations();
      let dataToRemove = clearAnnotation.toJson()?.annotationsList;
      let annotationData = {
        token: JSON.parse(drawingChart?.token),
        data: dataToRemove,
        chartType: chartView,
        interval: Constants.timeFrames[chartDuration[chartView]?.interval],
      };
      handleUpdateAnnotation(annotationData);
      dispatch(manageEraseDrawing(false));
    }
  }

  useEffect(() => {
    if (isEraseDrawing) {
      handleEraseDrawing();
    }
  }, [isEraseDrawing]);

  //Calculate OHLC
  let ohlcData = {};
  let openData = {};

  const handleCalculateOHLC = (
    currentInterval,
    nextInterval,
    chartLiveData,
    stockData,
    interval
  ) => {
    const prevInterval = currentInterval - 60 * 1000 * interval;

    // Remove previous interval data
    delete ohlcData[prevInterval];
    delete openData[prevInterval];

    const liveData = chartLiveData?.tick;
    const ltp = liveData?.[4];
    const lastData = stockData?.length && stockData?.[stockData.length - 1];
    const lastNode = stockData?.length && stockData?.at(-1);

    const lastDataTimestamp = Index.moment(lastData?.[0])
      .subtract(5, "hours")
      .subtract(30, "minutes")
      .valueOf();

    const currentTimestamp = Index.moment(currentInterval)
      .add(5, "hours")
      .add(30, "minutes")
      .valueOf();

    const liveTimestampWithSec = Index.moment(chartLiveData.timestamp)
      .startOf("minute")
      .valueOf();

    ohlcData[currentInterval] = ohlcData[currentInterval] || {};
    openData[currentInterval] = openData[currentInterval] || {};

    const token = JSON.parse(chartLiveData?.token);

    if (lastDataTimestamp === currentInterval) {
      openData[currentInterval][token] = lastData[1];
    }

    if (!openData[currentInterval][token]) {
      openData[currentInterval][token] = ltp;
    }

    if (
      ohlcData[currentInterval][token] &&
      liveTimestampWithSec >= currentInterval &&
      liveTimestampWithSec < nextInterval
    ) {
      const prevData = ohlcData[currentInterval][token];
      ohlcData[currentInterval][token] = [
        currentTimestamp,
        openData[currentInterval][token],
        Math.max(prevData[2], ltp),
        Math.min(prevData[3], ltp),
        ltp,
        ltp,
      ];
    } else {
      if (
        liveTimestampWithSec >= currentInterval &&
        liveTimestampWithSec < nextInterval
      ) {
        ohlcData[currentInterval][token] = [
          currentTimestamp,
          ltp,
          ltp,
          ltp,
          ltp,
          ltp,
        ];
      }
    }

    return ohlcData[currentInterval][token];
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(
          setGridViewStore({ ...gridView, [chartView]: { column: 2, row: 1 } })
        );
      } else {
        dispatch(
          setGridViewStore({ ...gridView, [chartView]: { column: 1, row: 1 } })
        );
      }
    };

    if (window.innerWidth < 992) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      let mainGridHeight = document.getElementsByClassName(
        "dashboard-chart-main"
      )?.[0]?.clientHeight;
  
      let chartHeaderHeight =
        document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight;

      setChartHeight(
        (mainGridHeight -
          (chartHeaderHeight ?? 0) * gridView[chartView]?.column -
          (gridSpacing * 8 * (gridView[chartView]?.column - 1) + 8) -
          0) /
          gridView[chartView]?.column
      );
    },
    [document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight],
    gridView[chartView]
  );

  return (
    <>
      <Index.Box className="dashboard-main my-dashboard">
        {isDrawingButtonActive && (
          <DrawChartButton
            newChart={drawingChart && drawingChart?.chart}
            token={drawingChart && drawingChart?.token}
            setNewChart={setDrawingChart}
          />
        )}
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec dashboard-main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            {filterData?.length ? (
              <>
                {chartView === "Intraday" || chartView === "Positional" ? (
                  <Index.Grid
                    container
                    spacing={gridSpacing}
                    className={`chart-main dashboard-chart-main dashboard-chart-mobile ${
                      twoSideArrowZoom && "dashboard-chart-main-zoom"
                    } `}
                  >
                    {filterData?.length
                      ? filterData
                          ?.slice(
                            pageSetting?.page * pageSetting?.rowsPerPage -
                              pageSetting?.rowsPerPage,
                            pageSetting?.page * pageSetting?.rowsPerPage
                          )
                          ?.map((item, index, array) => {
                            return (
                              <OneColumn
                                key={item.token}
                                socket={props.socket}
                                headerData={item}
                                data={item}
                                index={index?.token}
                                loopIndex={index}
                                token={item?.token}
                                chartStyle={0}
                                handleOpenLineChart={handleOpenLineChart}
                                handleOpenAreaChart={handleOpenAreaChart}
                                handleOpenCandleChart={handleOpenCandleChart}
                                length={pageSetting?.rowsPerPage}
                                live={chartView === "Intraday"}
                                active={index === cursor}
                                selectedId={index === cursor ? index : null}
                                setDrawingChart={setDrawingChart}
                                activeCursor={cursor}
                                fromDate={fromDate}
                                handleCalculateOHLC={handleCalculateOHLC}
                              />
                            );
                          })
                      : ""}
                    {filterData?.length
                      ? Array.from(
                          {
                            length:
                              pageSetting?.rowsPerPage -
                              filterData?.slice(
                                pageSetting?.page * pageSetting?.rowsPerPage -
                                  pageSetting?.rowsPerPage,
                                pageSetting?.page * pageSetting?.rowsPerPage
                              )?.length,
                          },
                          (_, index) => (
                            <Index.Grid
                              item
                              xs={gridView[chartView]?.column}
                              key={index}
                            >
                              <Index.Box sx={{ height: chartHeight }} />
                            </Index.Grid>
                          )
                        )
                      : ""}
                  </Index.Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Index.Box className="loader-box">No record found</Index.Box>
            )}
          </Index.Box>
          {/* 
          <Index.Modal
            open={openLineChart}
            onClose={handleCloseLineChart}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Index.Box sx={style} className="m-modal">
              <Index.Box className="modal-header-sec">
                <Index.ChartHeader
                  headerData={
                    chartView === "Intraday"
                      ? liveData[chartIndex]
                      : angelListData[chartIndex]
                  }
                  onClose={handleCloseLineChart}
                ></Index.ChartHeader>
              </Index.Box>
              <Index.Box className="modal-body-sec">
                <Index.Box className="chart-body">
                  <Index.CustomLineChart
                    shareData={
                      chartView === "Intraday"
                        ? liveData[chartIndex]?.stockData
                        : angelListData[chartIndex]?.stockData
                    }
                    index={`-${chartIndex}`}
                    DivideHeight="2"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openAreaChart}
            onClose={handleCloseAreaChart}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Index.Box sx={style} className="m-modal">
              <Index.Box className="modal-header-sec">
                <Index.ChartHeader
                  headerData={angelListData[chartIndex]}
                  onClose={handleCloseAreaChart}
                ></Index.ChartHeader>
              </Index.Box>
              <Index.Box className="modal-body-sec">
                <Index.Box className="chart-body">
                  <Index.CustomAreaChart
                    shareData={angelListData[chartIndex]?.stockData}
                    index={`-${chartIndex}`}
                    DivideHeight="2"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
          <Index.Modal
            open={openCandleChart}
            onClose={handleCloseCandleChart}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Index.Box sx={style} className="m-modal">
              <Index.Box className="modal-header-sec">
                <Index.ChartHeader
                  headerData={angelListData[chartIndex]}
                  onClose={handleCloseCandleChart}
                ></Index.ChartHeader>
              </Index.Box>
              <Index.Box className="modal-body-sec">
                <Index.Box className="chart-body">
                  <Index.CustomCandlestickChart
                    shareData={angelListData[chartIndex]?.stockData}
                    index={`-${chartIndex}`}
                    DivideHeight="2"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal> */}
        </Index.Box>
      </Index.Box>
      <Footer pageData={wishListData} />
    </>
  );
};

export default Dashboard;
