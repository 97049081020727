import React, { useEffect, useState } from "react";
import Index from "../Index";

const ReportModal = (props) => {
  const [reportFilterStock, setReportFilterStock] = useState("");
  const [reportBuySellButton, setReportBuySellButton] = useState("");
  const [reportCategoryFilter, setReportCategoryFilter] = useState({
    market: "",
    script: "",
  });
  const [reportIntraPositionButton, setReportIntraPositionButton] =
    useState("");
  const [reportModelAction, setReportModelAction] = useState(0);

  const categoryButtons = [
    {
      id: 1,
      title: "Equity",
      value: "NSE",
      scripts: [
        { id: 19, title: "All", value: "All" },
        { id: 11, title: "Nifty 50", value: "50" },
        { id: 12, title: "Nifty 100", value: "100" },
        { id: 13, title: "Nifty 200", value: "200" },
        { id: 14, title: "Nifty 500", value: "500" },
      ],
    },
    {
      id: 2,
      title: "F&O",
      value: "NFO",
      scripts: [
        { id: 20, title: "All", value: "All" },
        { id: 15, title: "Near Month", value: "near" },
        { id: 16, title: "Far Month", value: "far" },
      ],
    },
    {
      id: 3,
      title: "Commodity",
      value: "MCX",
      scripts: [
        { id: 21, title: "All", value: "All" },
        { id: 17, title: "Near Month", value: "near" },
        { id: 18, title: "Far Month", value: "far" },
      ],
    },
  ];

  const ReportAction = [{ title: "View Report" }, { title: "Clear All" }];

  //Date Range
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [calenderDateRange, setCalenderDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const changeShowCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };
  const handleDateSelect = (range) => {
    setCalenderDateRange({
      startDate: range?.selection?.startDate,
      endDate: range?.selection?.endDate,
      key: "selection",
    });
  };
  const handleClearDate = () => {
    setDateRange({
      startDate: "",
      endDate: "",
      key: "selection",
    });
    setCalenderDateRange({
      startDate: "",
      endDate: "",
      key: "selection",
    });
    changeShowCalendar(false);
  };

  const handleFilter = (type, index) => {
    setReportModelAction(index);
    props?.setShow?.((prev) => !prev);

    const newFilters = {};

    if (type === "View Report") {
      if (reportIntraPositionButton) {
        newFilters.tradeType = reportIntraPositionButton;
      } else {
        newFilters.tradeType = null;
      }
      if (dateRange?.startDate) {
        newFilters.fromDate = dateRange?.startDate;
      }
      if (dateRange?.endDate) {
        newFilters.toDate = dateRange?.endDate;
      }
      if (reportCategoryFilter?.market && reportCategoryFilter?.script) {
        newFilters.categories = [];
        newFilters.market = reportCategoryFilter?.market;
        newFilters.script = reportCategoryFilter?.script;
      } else {
        newFilters.market = null;
        newFilters.script = null;
        newFilters.categories = [];
      }

      if (reportBuySellButton) {
        newFilters.tag = reportBuySellButton || null;
      } else {
        newFilters.tag = null;
      }

      if (reportFilterStock) {
        newFilters.stockName = reportFilterStock || null;
      }
      props?.setFilters?.((prev) => ({
        ...prev,
        ...newFilters,
      }));
    } else {
      props?.setFilters?.({});
    }
  };

  useEffect(() => {
    if (props?.filters?.tradeType) {
      setReportIntraPositionButton(props?.filters?.tradeType);
    }
    if (props?.filters?.fromDate) {
      setDateRange((prev) => ({
        ...prev,
        startDate: props?.filters?.fromDate,
      }));
    }
    if (props?.filters?.toDate) {
      setDateRange((prev) => ({
        ...prev,
        endDate: props?.filters?.toDate,
      }));
    }
    if (props?.filters?.market && props?.filters?.script) {
      setReportCategoryFilter({
        market: props?.filters?.market,
        script: props?.filters?.script,
      });
    } else {
      setReportCategoryFilter({
        market: "",
        script: "",
      });
    }
    if (props?.filters?.tag) {
      setReportBuySellButton(props?.filters?.tag);
    }
    if (props?.filters?.stockName) {
      setReportFilterStock(props?.filters?.stockName);
    }
  }, []);

  return (
    <>
      <Index.Box className="body-p report-modal">
        <Index.Box className="report-category-wrapper">
          <Index.Box className="chart-view-sec">
            <Index.Box className="modal-ul-li">
              <Index.List className="modal-ul">
                <Index.Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    gap: {
                      xs: 3.5, // gap of 2 for extra-small screens
                      sm: 3.5, // gap of 3 for small screens
                      md: 3.5, // gap of 4 for medium screens
                      lg: 3, // gap of 5 for large screens
                    },
                  }}
                >
                  {categoryButtons.map((item, index) => (
                    <Index.ListItem
                      className={`modal-li cus-center ${
                        reportCategoryFilter?.market === item?.value && "active"
                      } `}
                      onClick={() => {
                        setReportCategoryFilter((prev) => ({
                          ...prev,
                          market:
                            prev?.market === item?.value ? "" : item?.value,
                          script: "",
                        }));
                      }}
                      key={index}
                    >
                      <Index.Typography className="" variant="p" component="p">
                        {item?.title}
                      </Index.Typography>
                    </Index.ListItem>
                  ))}
                </Index.Grid>
              </Index.List>
            </Index.Box>
          </Index.Box>
          <Index.Box className="time-frame-sec">
            <Index.Box className="time-frame-content report-modal-sec report-modal-sec-cus">
              <Index.Box className="time-frame-left">
                <Index.Box className="time-frame-ul-li">
                  <Index.List className="time-frame-ul">
                    {categoryButtons
                      ?.filter(
                        (item) => item?.value === reportCategoryFilter?.market
                      )?.[0]
                      ?.scripts?.map((item, index) => (
                        <Index.ListItem
                          className={`time-frame-li ${
                            reportCategoryFilter?.script === item?.value &&
                            "active"
                          } `}
                          onClick={() => {
                            setReportCategoryFilter((prev) => ({
                              ...prev,
                              script: item?.value,
                            }));
                          }}
                          key={index}
                        >
                          <Index.Box className="day-btn">
                            <Index.Typography
                              className=""
                              variant="p"
                              component="p"
                            >
                              {item.title}
                            </Index.Typography>
                          </Index.Box>
                        </Index.ListItem>
                      ))}
                  </Index.List>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="report-buy-sell-main">
          {["Buy", "Sell"].map((item, index) => (
            <Index.Box className="report-buy-sell-sec" key={index}>
              <Index.Box
                className={`report-buy-sell-btn report-buy-sell-btn-border report-buy-btn cus-center ${
                  reportBuySellButton === item && reportBuySellButton === "Buy"
                    ? "active-buy"
                    : reportBuySellButton === item &&
                      reportBuySellButton === "Sell"
                    ? "active-sell"
                    : ""
                } `}
                onClick={() =>
                  setReportBuySellButton((prev) => (prev !== item ? item : ""))
                }
              >
                {item}
              </Index.Box>
            </Index.Box>
          ))}
        </Index.Box>
        <Index.Box className="report-intra-pos-main">
        </Index.Box>
        <Index.Box className="date-range-wrapper">
          <Index.Typography
            className="modal-sub-title"
            variant="p"
            component="p"
          >
            Date Range
          </Index.Typography>

          <Index.Box className="date-range-sec">
            <Index.Box className="report-date-range-box report-modal-range-box">
              <Index.Box
                onClick={()=>{
                  if(dateRange.startDate && dateRange.endDate){
                    setCalenderDateRange(dateRange);
                  }
                  changeShowCalendar()
                }}
                className="report-date-box-cus"
              >
                <Index.TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  className="form-control"
                  placeholder="DD-MM-YYYY To DD-MM-YYYY"
                  value={
                    dateRange.startDate &&
                    Index.moment(dateRange.startDate).format("DD-MM-YYYY") +
                      "  To  " +
                      Index.moment(dateRange.endDate).format("DD-MM-YYYY")
                  }
                />
                <Index.Button onClick={handleClearDate}>
                  <Index.RefreshIcon />
                </Index.Button>
              </Index.Box>

              <Index.Box
                className={`report-date-range-calender-box ${
                  !showCalendar && "date-display-none"
                } `}
              >
                <Index.DateRangePicker
                  onChange={handleDateSelect}
                  ranges={[calenderDateRange]}
                  staticRanges={[]}
                  inputRanges={[]}
                  showPreview={false}
                  preview={false}
                  showDateDisplay={false}
                  className="responsive-date-range-picker  custom-picker-direction"
                />
                <Index.Box className="date-range-btns">
                  <Index.Button 
                    onClick={(e)=>{
                      setDateRange(calenderDateRange);
                      setShowCalendar(false)
                    }}
                    disabled={!(calenderDateRange.startDate && calenderDateRange.endDate)}
                  >
                    Apply
                  </Index.Button>
                  <Index.Button onClick={(e)=> {
                    setCalenderDateRange(dateRange);  
                    setShowCalendar(false)
                  }}>
                    Close
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="report-buy-sell-sec view-cleare-sec">
          {ReportAction.map((item, index) => (
            <Index.Box
              className={`report-buy-sell-btn report-buy-sell-btn-border report-buy-btn cus-center ${
                reportModelAction === index && "active"
              } `}
              onClick={() => handleFilter(item?.title, index)}
              key={index}
            >
              {item.title}
            </Index.Box>
          ))}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ReportModal;
