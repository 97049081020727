import React from 'react'
import Index from '../Index'

const Overlay = () => {
  return (
    <>
    <Index.Box className="overlay-main over-d-none"></Index.Box>
    </>
  )
}

export default Overlay