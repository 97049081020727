import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLineChartSingle from "../../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import { useNavigate } from "react-router-dom";
import CustomMiniHistoricalChart from "../../../../component/custom-chart/mini-chart/CustomMiniHistoricalChart";
import {
  Subscribe_stock_tokens,
  useSocketData,
  useStockDataListener,
} from "../../../../socket";
import Index from "../../../Index";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1E2431",
  borderRadius: "10px 10px 0 0",
  border: "none",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
};

const tags = [
  { id: 1, name: "All", value: "All" },
  { id: 2, name: "Open", value: "Active" },
  { id: 3, name: "Closed", value: "Inactive" },
  { id: 4, name: "Exitted", value: "Inactive" },
  { id: 5, name: "Target Hit", value: "Target Hit" },
  { id: 6, name: "Stop Loss Hit", value: "Stop Loss Hit" },
];

const allStockTypes = [
  { id: 1, name: "All", value: "All" },
  { id: 2, name: "Equity", value: "NSE" },
  { id: 3, name: "F&O", value: "MCX" },
];

const FavoriteList = () => {
  const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse"];
  let stock_data = useStockDataListener();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socketData, addSocketData } = useSocketData();
  const [allTagsData, setAllTagsData] = useState([]);
  const [allFavoriteTagsData, setAllFavoriteTagsData] = useState([]);
  const {
    wishListData,
    token,
    selectedScript,
    timeFrame,
    chartView,
    chartDuration,
    displaySettings,
    rowsPerPage,
  } = useSelector((state) => state.UserReducer);
  const [isActive, SetIsActive] = useState(true);
  const [active, Setactive] = useState(false);
  const [open, setOpen] = useState(false);
  const [chartData, setChartData] = useState([]);
  const handleOpen = (data) => {
    setTag("buy");
    setminiChartOpen(true);
    // getHistorylist(data);
    setChartData(data?.stockData);
  };
  const [scriptData, setScriptData] = useState();
  const [activeMiniChartOpen, setActiveMiniChartOpen] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [currenChart, setCurrenChart] = useState("");
  const [selectedTag, setSelectedTag] = useState("All");
  const [filterdData, setFilteredData] = useState([]);
  const [stockType, setStockType] = useState("All");
  const [tag, setTag] = useState("watch");
  const [miniChartOpen, setminiChartOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  // const handleOpen = () => setOpenModel(true);
  const handleClose = () => {
    setminiChartOpen(false);
    setChartData([]);
  };
  // const handleClose = () => setOpen(false);

  // const { index } = props;
  // const [LineChart, setLineChart] = useState(null);

  // const [open ,  setOpen] = useState(false);

  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);

  useEffect(() => {
    Index.DataService(userLoginToken)
      .get(`${Index.Api.user.getAllFavoriteTags}`)
      .then((data) => {
        setAllFavoriteTagsData(data?.data?.data);
      })
      .catch((error) => {});
  }, []);

  const handleMenu = (e) => {};

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 76,
      headerAlign: "center",
      hideable: false,
      align: "center",
    },
    {
      field: "script",
      headerName: "Script",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 120,
      editable: false,
      align: "center",
      headerAlign: "center",

      renderCell: (params) =>
        params.value ? (
          <Index.Button
            className={
              params.value.toLowerCase() === "buy"
                ? "green-buy-btn"
                : params.value.toLowerCase() === "sell"
                ? "red-buy-btn"
                : params.value.toLowerCase() === "watch"
                ? "blue-watch-btn"
                : "green-buy-btn"
            }
            disableRipple
          >
            {params.value}
          </Index.Button>
        ) : null,
    },
    {
      field: "CMP",
      headerName: "CMP",
      align: "center",
      headerAlign: "center",
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "miniChart",
      headerName: "Mini Chart",
      type: "number",
      width: 200,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => (
        <Index.Button
          onClick={() => {
            handleOpen(params?.row);
            setActiveMiniChartOpen(params?.value?.token);
          }}
          disableRipple
        >
          {/* <CustomLineChartSingle
              height={65}
              width={200}
              liveData={socketData}
              index={`1_${params?.value?.token}`}
              stockToken={params?.value?.token}
              /> */}

          <CustomMiniHistoricalChart
            chartData={params?.value?.stockData || []}
            // chartData={socketData}
            token={JSON.parse(params?.value?.token)}
            index={params?.value?.index}
          />
          {/* <CustomMiniChart
            chartData={socketData}
            token={JSON.parse(params?.value?.token)}
          /> */}
        </Index.Button>
      ),
    },

    {
      field: "stopLossat",
      headerName: "Stop Loss",
      type: "number",
      width: 130,
      editable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
    },
    {
      field: "targetat",
      headerName: "Target",
      type: "number",
      width: 130,
      editable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
    },
    {
      field: "createdAt",
      headerName: "Time",
      type: "number",
      width: 120,
      editable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
    },
  ];

  useEffect(() => {
    setScriptData(null);
    const tokenList = wishListData?.map((token) => {
      return token?.token || 0;
    });
    Subscribe_stock_tokens(tokenList);
  }, [wishListData]);

  const row = useMemo(() => {
    return Object.values(allFavoriteTagsData || {})?.map((item, index) => {
      const lastTradePrice =
        item?.stockData?.[0] &&
        item?.stockData?.[item?.stockData?.length - 1][2]?.toFixed(2);
      const closePrice =
        item?.stockData?.[0] &&
        item?.stockData?.[item?.stockData?.length - 1][4]?.toFixed(2);
      const changePointPercent =
        ((lastTradePrice - closePrice) / closePrice) * 100;
      const changePointValue = lastTradePrice - closePrice;
      const script = wishListData?.filter(
        (data) => data?.token == JSON.parse(item?.token)
      )?.[0]?.name;

      // const tag = getCurrentTag(item?.token)
      const tagData = scriptData?.[item?.token]?.findToken;

      return {
        index: index,
        tag: item?.tag || null,
        status: item?.status || "Watch",
        CMP: lastTradePrice,
        entry: tagData?.entryPrice || "-",
        stopLoss: tagData?.topLossAt?.price || "-",
        target: tagData?.targetAt?.price || "-",
        chargepoint: `${
          closePrice > lastTradePrice
            ? changePointValue.toFixed(2)
            : closePrice !== lastTradePrice
            ? `+${changePointValue.toFixed(2)}`
            : 0
        } (${changePointPercent ? `${changePointPercent.toFixed(2)}%` : "0%"})`,
        script: item?.stock_name,
        id: index + 1,
        miniChart: {
          token: item?.token,
          lastTradePrice: lastTradePrice,
        },
        createdAt: Index.moment(tagData?.createdAt).format("HH:MM:SS"),
        updatedAt: Index.moment(tagData?.updatedAt).format("DD-MM-YYYY"),
        token: item?.token,
        target: item?.targetAt,
        stopLoss: item?.topLossAt,
        targetat: item?.targetAt?.price,
        stopLossat: item?.topLossAt?.price,
        exchange_type: item?.exchange_type,
        stockData: item?.stockData,
        action: [
          {
            token: item?.token,
            value: Index.Svg.roundPlus,
          },
          {
            token: item?.token,
            stockName: item?.name,
            value: Index.Svg.whiteDots,
          },
        ],
      };
    });
  }, [scriptData, allFavoriteTagsData]);

  useEffect(() => {
    const filteredData = row?.filter((ele) => {
      const exchangeType = ele?.exchange_type?.toLowerCase();

      if (stockType !== "All") {
        return exchangeType === stockType?.toLowerCase();
      } else {
        return ele;
      }
    });

    setFilteredData(filteredData || []);
  }, [row, stockType]);

  function deleteLine() {
    // get the selected annotation
    var selectedAnnotation = currenChart.annotations().getSelectedAnnotation();
    // remove the selected annotation
    currenChart.annotations().removeAnnotation(selectedAnnotation);
  }

  const getHistorylist = (headerData) => {
    const formData = new URLSearchParams();
    formData.append(
      "exchange",
      headerData?.exch ||
        headerData?.exch_seg ||
        headerData?.Exch ||
        headerData?.exchange_type ||
        "MCX"
    );
    formData.append("symboltoken", headerData?.token);
    formData.append("interval", "ONE_MINUTE");
    formData.append(
      "fromdate",
      Index.moment()
        .subtract(chartDuration[chartView]?.timeFrame, "d")
        .set({ hour: 9, minute: 15 })
        .format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      Index.moment()
        .add(1, "d")
        .set({ hour: 3, minute: 30 })
        .format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(token)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.data) {
          // setChartData(res.data.data);
          let tagDataObj = allFavoriteTagsData?.find(
            (ele) => ele?.token == headerData?.token
          );

          if (tagDataObj) {
            tagDataObj.stockData = res.data.data;
            setScriptData((prevScriptData) => ({
              ...prevScriptData,
              [headerData?.token]: tagDataObj,
            }));
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (allFavoriteTagsData?.length > 0) {
      allFavoriteTagsData?.forEach((tag, index) => {
        setTimeout(() => {
          getHistorylist(tag);
        }, index * 1000);
      });
    }
  }, [allFavoriteTagsData, stockType]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  return (
    <>
      <Index.Box className="dashboard-wrapper open-order-list-wrap">
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } `}
        >
          <Index.Box className={"open-order-body active"}>
            <Index.Box className="report-filter-flex-box">
              <Index.Select
                className="common-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="StockSelect"
                // label={"Chart"}
                defaultValue={stockType || "All"}
                value={stockType || "All"}
                // onChange={filterRowData}
                onChange={(e) => {
                  let value = e?.target?.value;
                  setStockType(value);
                }}
              >
                {allStockTypes.map((tag, index) => (
                  <Index.MenuItem
                    key={tag?.id}
                    value={tag?.value}
                    className="common-dropdown-menu"
                  >
                    {tag?.name}
                  </Index.MenuItem>
                ))}
              </Index.Select>
            </Index.Box>

            <Index.Box className="table-sec">
              <Index.Box sx={{ width: "100%" }}>
                <Index.DataGrid
                  rows={filterdData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[1000]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  // checkboxSelection

                  disableRowSelectionOnClick
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {miniChartOpen && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={miniChartOpen}
              onClose={() => {
                handleClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Index.Box sx={style}>
                <Index.Box className="modal-header-sec">
                  <Index.ChartHeader
                    index={JSON.parse(activeMiniChartOpen)}
                    tags={tag}
                    setTags={setTag}
                    allTags={allTags}
                    headerData={{
                      stock_name: wishListData?.filter(
                        (data) => data?.token == JSON.parse(activeMiniChartOpen)
                      )?.[0]?.name,
                      // stockData:socketData[JSON.parse(activeMiniChartOpen)]
                    }}
                    chartData={chartData}
                    onClose={() => {
                      handleClose();
                    }}
                  ></Index.ChartHeader>
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  <Index.Box className="chart-body">
                    <CustomLineChartSingle
                      height={500}
                      width={1000}
                      liveData={socketData}
                      index={activeMiniChartOpen}
                      stockToken={activeMiniChartOpen}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
    </>
  );
};

export default FavoriteList;
