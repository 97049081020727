import { useState, useEffect, useMemo } from "react";
import Index from "../../../component/Index";
import { doGet } from "../../../service/UserIndex";
import moment from "moment";

const Notification = () => {
  const token = Index.useSelector((state) => state.UserReducer.token);
  const { displaySettings, rowsPerPage, twoSideArrowZoom } = Index.useSelector(
    (state) => state.UserReducer
  );

  const [notification, setNotification] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [pageSize, setPageSize] = useState(16);
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentNotifications = notification?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  //Columns for table
  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 100,
      hideable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stock_name",
      headerName: "Script",
      width: 250,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stock_price",
      headerName: "Price",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 230,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Index.Button
          className={
            params.value.toLowerCase() === "buy"
              ? "green-buy-btn"
              : params.value.toLowerCase() === "sell" ||
                params.value.toLowerCase() === "exit"
              ? "red-buy-btn"
              : params.value.toLowerCase() === "watch"
              ? "blue-watch-btn"
              : ""
          }
          disableRipple
        >
          {params.value}
        </Index.Button>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date and Time",
      width: 270,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  const row = useMemo(() => {
    return notification?.map((item, index) => {
      return {
        id: index + 1,
        tag: item?.tag || "",
        status: item?.status,
        stock_name: item?.stock_name,
        stock_price: item?.stock_price,
        token: item?.token,
        createdAt: moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss A"),
      };
    });
  }, [notification]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await doGet("/user/get-notifications", token);
        setNotification(data.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let topSecElement = document.querySelector(".list-heading-main");

  useEffect(() => {
    if (mainContentElement && topSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const topSecHeight = topSecElement.clientHeight;
      setDataTableHeight(mainHeight - topSecHeight - 65);
    }
  }, [mainContentElement, topSecElement, twoSideArrowZoom]);

  return (
    <>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Index.Box className="list-heading-main">
              <Index.Typography className="list-heading-text">
                Notifications
              </Index.Typography>
            </Index.Box>
            <Index.Box
              className="table-sec"
              // className={`table-sec ${
              //   row.length < 10 ? "transaction-table" : ""
              // }`}
            >
              <Index.Box sx={{ width: "100%", height: dataTableHeight }}>
                {row && row.length > 0 ? (
                  <Index.DataGrid
                    rows={row}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({
                          from,
                          to,
                          count,
                          estimated,
                        }) => {
                          if (!estimated) {
                            return `${from} – ${to} of ${
                              count !== -1 ? count : `${to}`
                            }`;
                          }
                          return `${from} – ${to} of ${
                            count !== -1
                              ? count
                              : `${estimated > to ? estimated : to}`
                          }`;
                        },
                      },
                    }}
                  />
                ) : (
                  <Index.Box
                    sx={{ textAlign: "center", padding: "20px" }}
                    className="text-data"
                  >
                    No notification found
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="noti-page-box">
              {currentNotifications?.map((notification, index) => {
                return (
                  <>
                    <Index.Box className="single-message-box" key={index}>
                      <Index.Typography
                        className={
                          notification?.message?.charAt(0).toLowerCase() == "s"
                            ? "stopLoss"
                            : "targetAchived"
                        }
                      >
                        {notification.message}
                        <small> {new Date(notification.createdAt)?.toLocaleString()}</small>
                      </Index.Typography>
                    </Index.Box>
                  </>
                );
              })}
            </Index.Box>

            <Index.Box className="notification-sec">
              <Index.Box className="pagination-sec">
                <Index.Box className="flex">
                  <>
                    <Index.Pagination
                      count={Math.ceil(notification?.length / pageSize)}
                      page={page}
                      onChange={handleChangePage}
                      shape="rounded"
                      hidePrevButton
                      hideNextButton
                    />
                  </>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Notification;
