import React from "react";
import Index from "../Index";

const TableLoader = () => {
  return (
    <>
      <Index.Box
        sx={{ textAlign: "center", padding: "20px" }}
        className="text-data"
      >
        <Index.Box className="loader"></Index.Box>
      </Index.Box>
    </>
  );
};

export default TableLoader;
