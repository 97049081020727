import "./App.css";
import Routes from "./routes/Routes";
import style from "./assets/css/style.css";
import responsive from "./assets/css/responsive.css";
import select2 from "./assets/css/select2.css";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { ThemeContext } from "./theme/ThemeContext";
import { useEffect } from "react";
import useSocket from "./component/hooks/useSocket";
import Index from "./component/Index";
import { SOCKET_URL } from "./config/DataService";

const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showPopup, setShowPopup] = useState(false);

  // const sound = new Audio(notificationSound);

  const socket = useSocket(SOCKET_URL);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false);
      setShowPopup(true); // Show the popup when offline
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // const URL_WEB_SOCKET = `ws://localhost:3018/api/stock-data?stock_auth=${localStorage.getItem(
  //   "token"
  // )}`;

  // const wsClient = new WebSocket(URL_WEB_SOCKET);
  const themeTitle = localStorage.getItem("default-theme");
  const [theme, setTheme] = useState(!themeTitle ? "Light" : themeTitle);

  useEffect(() => {
    if (themeTitle === "Light" || !themeTitle) {
      document.body.classList.add("select-light-theme");
      document.body.classList.remove("select-dark-theme");
      document.body.classList.remove("select-medium-theme");
    } else if (themeTitle === "Dark") {
      document.body.classList.add("select-dark-theme");
      document.body.classList.remove("select-light-theme");
      document.body.classList.remove("select-medium-theme");
    } else if (themeTitle === "Medium") {
      document.body.classList.add("select-medium-theme");
      document.body.classList.remove("select-dark-theme");
      document.body.classList.remove("select-light-theme");
    }
  }, [themeTitle]);

  return (
    // <SocketContext.Provider value={wsClient}>
    <>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className={`theme-${theme}`}>
          <div className="App">
            {/* <DrawChartButton/> */}
            <Routes socket={socket} />
          </div>
        </div>
      </ThemeContext.Provider>

      {!isOnline && (
        <Index.Modal
          open={!isOnline}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-comman-details"
        >
          <Index.Box sx={style} className="modal-comman-inner-style">
            <Index.Box>
              <h3 className="modal-title">Connect to the internet</h3>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      )}
      {/* // </SocketContext.Provider> */}
    </>
  );
};

export default App;
