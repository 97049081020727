import React, { forwardRef } from "react";
import Index from "../../Index";

const GrayBgButton = forwardRef((props,ref) => {
  const { className, onClick, btnLabel, imgsrc } = props;
  return (
    <>
      <Index.Box className="gray-bg-btn-main">
        <Index.Button onClick={onClick} disableRipple className={className}>
          {btnLabel} <img src={imgsrc} />
        </Index.Button>
      </Index.Box>
    </>
  );
});

export default GrayBgButton;
