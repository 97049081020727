import { React, useContext, useEffect, useRef, useState } from "react";
import Index from "../../Index";
import OneColumn from "./OneColumn";
import TwoColumn from "./TwoColumn";
import FourColumn from "./FourColumn";
import SixColumn from "./SixColumn";
import NineColumn from "./NineColumn";
import { SocketContext } from "../../../theme/ThemeContext";
import {
  currentPagePlus,
  currentPageMinus,
  deleteWishListData,
  setGridViewStore,
} from "../../../redux/user/action";
import Footer from "../../../component/DefaultLayout/Footer";
import {
  Subscribe_stock_tokens,
  getPrviousDataOfStock,
  useStockDataListener,
} from "../../../socket";
import { useLocation } from "react-router-dom";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";

const validJson = (str = "") => {
  try {
    return JSON.parse(str);
  } catch {
    return 0;
  }
};

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1E2431",
  borderRadius: "10px 10px 0 0",
  border: "none",
  // boxShadow: 24,
  // p: 4,
};

const SingleStockView = () => {
  const allTags = ["Watch", "Buy", "Sell", "Reverse"];
  const { state } = useLocation();

  const {
    gridSpacing,
    wishListData,
    token: userAuthToken,
    chartView,
    displaySettings,
    chartDuration,
    graphType,
    gridView,
  } = Index.useSelector((state) => state.UserReducer);
  const dispatch = Index.useDispatch();
  const abortControllers = useRef([]);
  const [openLineChart, setOpenLineChart] = useState(false);
  const [openAreaChart, setOpenAreaChart] = useState(false);
  const [openCandleChart, setOpenCandleChart] = useState(false);
  const [chartIndex, setChartIndex] = useState(false);
  const [scriptData, setScriptData] = useState({});
  const [wishListDataState, setWishListDataState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [oldData, setOldData] = useState({});
  const [loader, setLoader] = useState(true);
  const [cursor, setCursor] = useState(0);
  const [singleStockData, setSingleStockData] = useState();
  const [tags, setTag] = useState("watch");
  const [gridV, setGridV] = useState({
    row: 0,
    column: 0,
  });

  const fromDate = usePreviousDateCalculator(
    chartDuration[chartView]?.timeFrame
  );

  const getAllHistoryData = async (live, controller, token) => {
    try {
      const formData = {
        tokens: [token],
        interval: "",
        fromDate: Index.moment(fromDate, "YYYY-MM-DD HH:mm").format(
          "YYYY-MM-DDTHH:mm:00Z"
        ),
        toDate: Index.moment().format("YYYY-MM-DDTHH:mm:00Z"),
      };
      const response = await Index.DataService(userAuthToken).post(
        Index.Api.user.getAllHistoricalData,
        formData,
        {
          signal: controller.signal,
        }
      );

      if (response.data.data.length) {
        let format = response?.data?.data?.[0]?.data?.map((el) =>
          live
            ? [setDatefun(el[0]), +el[1], +el[2], +el[3], +el[4], +el[4]]
            : [setDatefun(el[0]), +el[1], +el[2], +el[3], +el[4], +el[5]]
        );
      }
    } catch (error) {}
  };

  const callAllHistorical = async () => {
    abortControllers.current = null;
    const controller = new AbortController();
    abortControllers.current = controller;
    getAllHistoryData(chartView === "Intraday", controller, state?.stockToken);
  };

  useEffect(() => {
    callAllHistorical();
    return () => {
      abortControllers.current.abort();
    };
  }, [wishListData, chartDuration, graphType[chartView], chartView]);

  const stock_data = useStockDataListener();
  useEffect(() => {
    if (stock_data !== null) {
      let a = [...wishListData];
      let index = a?.findIndex(
        (e) => +e?.token === +stock_data?.token.replaceAll(/"/g, "")
      );

      // if (index !== -1) {
      if (+state?.stockToken === +stock_data?.token.replaceAll(/"/g, "")) {
        setScriptData((prevScriptData) => {
          let arr = prevScriptData[stock_data?.token]?.stockData || [];
          return {
            ...prevScriptData,
            [stock_data?.token]: {
              tags: "Watch",
              stock_name: a[index]?.name,
              token: a[index]?.token,
              exch: a[index]?.Exch || a[index]?.exch_seg || "MCX",
              stockData: [
                ...arr,
                [
                  setDatefun(+stock_data?.exchange_timestamp),
                  stock_data?.open_price_day / 100,
                  stock_data?.high_price_day / 100,
                  stock_data?.low_price_day / 100,
                  stock_data?.close_price / 100,
                  stock_data?.last_traded_price / 100,
                ],
              ],
            },
          };
        });
      } else {
        setScriptData((prevScriptData) => {
          let obj = { ...prevScriptData };
          delete obj[stock_data?.token];
          return obj;
        });
      }
    }
  }, [stock_data, state?.stockToken]);

  useEffect(() => {
    if (state?.stockToken && chartView !== "Intraday") {
      const singleData = wishListData?.find((data) => {
        return data?.token == JSON.parse(state?.stockToken);
      });
      setSingleStockData(singleData);
      Subscribe_stock_tokens([singleData?.token?.toString()]);
    } else {
      setScriptData({});
      Subscribe_stock_tokens([state?.stockToken?.toString()]);
    }
    let gridViewData = { column: 1, row: 1 };
    dispatch(setGridViewStore({ ...gridView, [chartView]: gridViewData }));
  }, [state?.stockToken]);

  return (
    <>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec single-stock-view ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } `}
          >
            {state?.stockToken ? (
              <>
                {chartView === "Intraday" && (
                  <Index.Grid
                    container
                    spacing={gridSpacing}
                    className="chart-main"
                  >
                    {Object.keys(scriptData)?.length > 0
                      ? Object.keys(scriptData || {})?.map(
                          (item, index, array) => {
                            return (
                              <OneColumn
                                key={item}
                                headerData={scriptData[item]}
                                data={scriptData[item]?.stockData}
                                index={scriptData[item]?.token}
                                loopIndex={index}
                                token={scriptData[item]?.token}
                                chartStyle={0}
                                // handleOpenLineChart={handleOpenLineChart}
                                // handleOpenAreaChart={handleOpenAreaChart}
                                // handleOpenCandleChart={handleOpenCandleChart}
                                length={array.length}
                                live={true}
                                active={index === cursor}
                                selectedId={index === cursor ? index : null}
                              />
                            );
                          }
                        )
                      : "Loading..."}
                  </Index.Grid>
                )}
                {/* {chartView === "Positional" ? (
                  <Index.Grid
                    container
                    // spacing={gridSpacing}
                    className="chart-main"
                  >
                    <Index.Grid xs={12}>
                      <Index.Box className="modal-header-sec">
                        <Index.ChartHeader
                          index={JSON.parse(state?.stockToken)}
                          tags={tags}
                          setTags={setTag}
                          allTags={allTags}
                          // headerData={{
                          //   stock_name: wishListData?.filter(
                          //     (data) =>
                          //       data?.token == JSON.parse(state?.stockToken)
                          //   )?.[0]?.name,
                          //   // stockData: socketData[JSON.parse(state?.stockToken)],
                          // }}
                          headerData={singleStockData}
                          onClose={() => {
                            // handleClose();
                          }}
                        ></Index.ChartHeader>
                      </Index.Box>
                      <Index.Box className="modal-body-sec">
                        <Index.Box className="chart-body">
                          <CustomLineChartSingle
                            height={500}
                            width={1200}
                            // liveData={{}}
                            index={state?.stockToken}
                            stockToken={state?.stockToken}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                ) : (
                  ""
                )} */}
                {chartView === "Positional" && singleStockData ? (
                  <Index.Grid container spacing={2} className="chart-main">
                    <OneColumn
                      // key={item.token}
                      headerData={singleStockData}
                      data={singleStockData}
                      // index={index}
                      chartStyle={0}
                      // handleOpenLineChart={handleOpenLineChart}
                      // handleOpenAreaChart={handleOpenAreaChart}
                      // handleOpenCandleChart={handleOpenCandleChart}
                      length={1}
                      // active={index === cursor}
                      // selectedId={index === cursor ? index : null}
                      active={true}
                      selectedId={1}
                    />
                  </Index.Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              "Loading..."
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SingleStockView;
