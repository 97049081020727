import React from "react";
import Index from "../../Index";

const TwoColumn = (props) => {
  const {
    index,
    data,
    chartStyle,
    headerData,
    handleOpenLineChart,
    handleOpenAreaChart,
    handleOpenCandleChart,
  } = props;
  return (
    <Index.Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      key={index}
      className="grid-main"
    >
      <Index.Box className="main-chart-box">
        <Index.ChartHeader headerData={headerData} />
        {chartStyle === 0 && (
          <Index.Box
            className="line-chart-body"
            onClick={() => handleOpenLineChart(index)}
          >
            <Index.CustomLineChart
              shareData={data}
              index={index}
              DivideHeight="3.6"
              borderRadiusClass={"border-radius-graph-bottom"}
            />
          </Index.Box>
        )}
        {chartStyle === 1 && (
          <Index.Box
            className="line-chart-body"
            onClick={() => handleOpenAreaChart(index)}
          >
            <Index.CustomAreaChart
              shareData={data}
              index={index}
              DivideHeight="3.6"
              borderRadiusClass={"border-radius-graph-bottom"}
            />
          </Index.Box>
        )}
        {chartStyle === 3 && (
          <Index.Box
            className="line-chart-body"
            onClick={() => handleOpenCandleChart(index)}
          >
            <Index.CustomCandlestickChart
              shareData={data}
              index={index}
              DivideHeight="3.6"
              borderRadiusClass={"border-radius-graph-bottom"}
            />
          </Index.Box>
        )}
      </Index.Box>
    </Index.Grid>
  );
};

export default TwoColumn;
