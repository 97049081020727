import React, { useState } from "react";
import Index from "../Index";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const ReplaceShortcut = () => {
  //   const [user, setUser] = useState({
  //     // shortcutName: "",
  //     shortcutKey: "",
  //   });
  //   const onChangeInput = (e) => {
  //     // const { key } = e;
  //     setUser((prevData) => ({
  //       ...prevData,
  //       ["shortcutKey"]: e.key,
  //     }));
  //   };

  //   const [first, setFirst] = useState();

  // //   const handleKeyDown = (e) => {
  // //     setFirst(e.key);
  // //   };
  //   document.addEventListener("keydown", onChangeInput);

  //   const [inputValue, setInputValue] = useState('');

  //   React.useEffect(() => {
  //     const handleKeyDown = (event) => {
  //       setInputValue((prevValue) => prevValue + event.key);
  //     };

  //     // Attach the event listener when the component mounts
  //     window.addEventListener('keydown', handleKeyDown);

  //     // Clean up the event listener when the component unmounts
  //     return () => {
  //       window.removeEventListener('keydown', handleKeyDown);
  //     };
  //   }, []);

  // const [inputValues, setInputValues] = useState(['', '', '']);
  // const [activeField, setActiveField] = useState(0);

  // const handleKeyDown = (event) => {

  //   const pressedKey = event.key;

  //   if (pressedKey.length === 1) {
  //     setInputValues((prevValues) => {
  //       const newValues = [...prevValues];
  //       newValues[activeField] = pressedKey;
  //       return newValues;
  //     });

  //     setActiveField((prevField) => (prevField + 1) % inputValues.length);
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [activeField, inputValues]);

  const [pressedKeys, setPressedKeys] = useState([]);

  useEffect(() => {
    // Add event listeners when the component mounts
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // Event handler for keydown event
  const handleKeyDown = (event) => {
    // Check if the key is not already in the pressedKeys array
    if (!pressedKeys.includes(event.key)) {
      setPressedKeys((prevKeys) =>
        prevKeys.length !== 3 ? [...prevKeys, event.key] : prevKeys
      );
    }
  };

  // Event handler for keyup event
  const handleKeyUp = (event) => {
    // Remove the key from the pressedKeys array
    // setPressedKeys((prevKeys) => prevKeys.filter((key) => key !== event.key));
  };

  // Function to check if both 'A' and 'B' keys are pressed simultaneously
  const areKeysPressed = () => {
    return pressedKeys.includes("a") && pressedKeys.includes("b");
  };

  return (
    <>
      <Index.Box className="body-p">
        <Index.Box className="replace-wrapper">
          <Index.Typography
            className="key-combination-text"
            variant="p"
            component="p"
          >
            Press desired key combination <br></br>
            and then press enter
          </Index.Typography>

          <Index.Box className="form-group update_key_shortcut">
            {/* {inputValues.map((value, index) => (
              <input
                key={index}
                type="text"
                value={value}
                onChange={(e) => {
                  const newInputValues = [...inputValues];
                  newInputValues[index] = e.target.value;
                  setInputValues(newInputValues);
                }}
                onFocus={() => setActiveField(index)}
              />
            ))} */}

            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="Ctrl"
              autoComplete="off"
              name="shortcutName"
              value={Array.from(new Set(pressedKeys)).join("+")}
              //   onChange={onChangeInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setPressedKeys([])} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Index.Box>

          <Index.Box className="form-group ctrl-shift-sec">
            {/* <Index.Box className="ctrl-bg">Ctrl</Index.Box> */}
            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="Ctrl"
              autoComplete="off"
              name="shortcutName"
              //   value={user.shortcutName}
              //   onChange={onChangeInput}
            />
            <Index.Typography className="plus-text" variant="p" component="p">
              +
            </Index.Typography>
            {/* <Index.Box className="ctrl-bg">Shift</Index.Box> */}
            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="Hide Header"
              autoComplete="off"
              name="shortcutName"
              //   value={user.shortcutName}
              //   onChange={onChangeInput}
            />
            <Index.Typography className="plus-text" variant="p" component="p">
              +
            </Index.Typography>
            {/* <Index.Box className="ctrl-bg">P</Index.Box> */}
            <Index.TextField
              fullWidth
              id="fullWidth-shortcut-name"
              className="form-control"
              placeholder="Hide Header"
              autoComplete="off"
              name="shortcutName"
              //   value={user.shortcutName}
              //   onChange={onChangeInput}
            />
          </Index.Box>
          <Index.Box className="add-btn-sec">
            <Index.PrimaryButton
              className="primary-btn login-btn"
              btnLabel="Submit"
            ></Index.PrimaryButton>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ReplaceShortcut;
