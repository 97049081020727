const Api = {
  auth: {
    login: "user/login",
    register: "user/register",
    forgotPassword: "/user/forget-password",
    resetPassword: "/user/reset-password",
    changePassword: "/user/change-password",
    otpVerification: "/user/verify-otp",
  },
  user: {
    getUserWatchList: "user/get-watchList",
    getSymbollist: "user/angel-broking/getAllSymbols/",
    getWatchlist: "user/watch-list",
    addScriptToList: "user/add-script-in-watchlist",
    removeScriptToList: "user/remove-script-in-watchlist",
    getAllFavoriteWatchlist: "user/get-all-favorite-watchlist",
    createUserWatchList: "user/create-watchlist",
    addRemoveFavoriteScript: "user/add-remove-favorite-script",
    getWatchListScript: "user/get-scripts?id=",
    addWatchlist: "user/watch-list/add-watch-list",
    removeWatchlist: "user/watch-list/remove-watch-list",
    angelGetAllHistory: "user/angel-broking/getAllAngelBrokingData",
    editProfile: "user/edit-profile",
    addStock: "user/stock/stockTagAddAndUpdate",
    removeStock: "user/stock/stockTagRemove",
    reverseStock: "/user/stock/stockReverse",
    stockTagCounts: "/user/stock/tagsCount",
    getAllTags: "/user/stocks",
    getUsersStocksTags: "/user/get-user-stocks",
    getSingleAngelData: "/user/stock/get-single-angel-data?token=",
    getNiftyData: "/user/nifty",
    getAllShortcuts: "/user/get-all-short-cuts",
    getAllTagList: "/user/get-stock-tags",
    exitStocks: "/user/stocks-exit-tag-status",
    resetStocks: "/user/stocks-reset-watch-tag",
    getAllFavoriteTags: "/user/get-favourite",
    getAllFavoriteTags: "/user/get-favourite",
    removeFavoriteTag: "/user/remove-favourite",
    aadFavoriteTag: "/user/create-favourite",
    getAllTools: "/user/get-all-tools",
    updateTool: "/user/update-tool",
    getNearFarMonthStocks: "/user/get-stocks",
    getAllReports: "/user/get-all-report",
    getAllHistoricalData: "user/get-historical-data",
    getIntradayData: "user/get-intra-day-data",
    getMultipleStocksTags: "user/get-stocks-tags",
    addUpdateAnnotation: "/user/create-annotation",
    getAnnotations: "/user/get-all-annotation",
  },
  admin: {
    addUser: "admin/add-user",
    updateUser: "admin/update-user",
    getSingleUSer: "admin/get-single-user",
    deleteUser: "admin/delete-user",
    getAllUSer: "user/get-all-users",
    updateStatus: "admin/update-status",
    getUserTag: "admin/get-user-tag",
    createIndex: "/admin/create-index",
    getAllIndex: "/admin/get-all-index",
    getSingleIndex: "/admin/get-single-index",
    deleteIndex: "/admin/delete-index",
    activeDeactiveIndex: "/admin/active-deactive-index",
    addIndexStocks: "/admin/add-index-stocks",
    getAllContracts: "/admin/get-all-contracts",
  },
};
export { Api };
