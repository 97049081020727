import anychart from "anychart";
import AnyChart from "anychart-react";
import React, { memo, useEffect, useRef } from "react";
import Index from "../../Index";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const CustomMiniChart = ({ chartData, token, chartType }) => {
  const { miniChartSize, GraphStyle, isFlip } = Index.useSelector(
    (state) => state.UserReducer
  );

  const chartRef = useRef(null);
  const chartDataSetRef = useRef(null);

  useEffect(() => {
    // if (chartData?.length) {
    chartDataSetRef.current = anychart.data.set(chartData);
    // }
  }, [chartData]);

  useEffect(() => {
    if (chartData?.length) {
      if (chartDataSetRef.current) {
        const lastPrice = chartData?.length && chartData;

        const index = lastPrice[chartData?.length - 1].length == 2 ? 1 : 4;

        let formattedDate = Index.moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DDTHH:mm:ssZ");
        chartDataSetRef.current.append([
          setDatefun(formattedDate),
          lastPrice[chartData?.length - 1][index],
        ]);
      }
    }
  }, [chartData, token]);

  function getChartInstance(chartType) {
    switch (chartType) {
      case 0:
      case 2:
        return anychart.line();
      case 1:
        return anychart.area();
      default:
        throw new Error(`Invalid chart type: ${chartType}`);
    }
  }

  useEffect(() => {
    const container = document.getElementById(token);
    if (container) {
      container.innerHTML = "";
      // container.style.height = "50px";
      // container.style.width = "150px";
      container.style.height = `${miniChartSize?.height}px`;
      container.style.width = `${miniChartSize?.width}px`;
      container.style.padding = `0px`;

      const chart = getChartInstance(chartType);
      chart.xScale().mode("continuous");
      chart.background().fill("transparent");

      const seriesData = chartDataSetRef?.current?.mapAs({ x: 0, value: 4 });
      let series;

      if (chartType === 0 || chartType === 2) {
        series = chart.line(seriesData);
        chartType === 2 && series.markers(true);
        series.stroke(function () {
          return {
            keys: [GraphStyle.lineGraph.color],
            thickness: GraphStyle.lineGraph.thickness,
          };
        });
        series.fill({
          keys: [GraphStyle.lineGraph.color],
          fill: 2,
        });
      } else if (chartType === 1) {
        series = chart.area(seriesData);
        series.stroke(function () {
          return {
            keys: [GraphStyle.areaGraph.thicknessColor],
            thickness: GraphStyle.areaGraph.thickness,
          };
        });
        series.fill({
          keys: [GraphStyle.areaGraph.color],
          fill: 2,
        });
      }
      // series.normal().stroke('#FF0000', 1.5);
      chart.yScale().inverted(isFlip);
      chart.xAxis(false);
      chart.yAxis(false);
      chart.tooltip(false);

      chart.container(container).draw();

      chartRef.current = chart;
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [token, chartData, chartType, miniChartSize, GraphStyle, isFlip]);

  return (
    <AnyChart
      id={token}
      style={{ height: miniChartSize?.height, width: miniChartSize?.width }}
    />
  );
};

export default memo(CustomMiniChart);
