import React from "react";
import Index from "../Index";
import Select2 from "react-select2-wrapper";
import { useState } from "react";
const DownloadChart = () => {
  const [downloadmodalIntraPositionBtn, setDownloadmodalIntraPositionBtn] =
    useState("Positional");
  const [intradayBtnActive, setIntradayBtnActive] = useState(0);
  const [positionalBtnActive, setPositionalBtnActive] = useState(0);
  const [positionalTimeBtnActive, setPositionalTimeBtnActive] = useState(0);
  const [defaultModelStyleBtnActive, setDefaultModelStyleBtnActive] =
    useState(0);

  const IntradayButtons = [
    { title: "1 Minute" },
    { title: "5 Minute" },
    { title: "10 Minute" },
    { title: "15 Minute" },
    { title: "30 Minute" },
  ];

  const PositionalButtons = [
    { title: "5 Days", time: ["30 Min", "1 Hour"] },
    { title: "1 Month", time: ["30 Min", "1 Hour"] },
    { title: "3 Months", time: ["30 Min", "1 Hour"] },
    { title: "6 Months", time: ["30 Min", "1 Hour"] },
    { title: "1 Year", time: ["30 Min", "1 Hour"] },
    // { title: "2 Year", time: ["30 Min", "1 Hour"] },
    // { title: "3 Year", time: ["30 Min", "1 Hour"] },
  ];

  const DefaultModelStyleBttons = [
    { title: "Line chart", icon: Index.Svg.lineChartDown },
    { title: "Area chart", icon: Index.Svg.areachartDown },
    { title: "Dotted chart", icon: Index.Svg.dottedchartDown },
    { title: "Candle Stick chart", icon: Index.Svg.candelchartDown },
  ];

   //Date Range
   const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [calenderDateRange, setCalenderDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const changeShowCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };
  const handleDateSelect = (range) => {
    setCalenderDateRange({
      startDate: range?.selection?.startDate,
      endDate: range?.selection?.endDate,
      key: "selection",
    });
  };
  const handleClearDate = () => {
    setDateRange({
      startDate: "",
      endDate: "",
      key: "selection",
    });
    setCalenderDateRange({
      startDate: "",
      endDate: "",
      key: "selection",
    });
    changeShowCalendar(false);
  };

  return (
    <>
      <Index.Box className="body-p download-chart-wrapper">
        {/* <Index.Box className="search-buy-sell">
          <Index.Box className="slectTwo-wrap">
            <Select2 className="index"
              data={[
                "Stock Name Here...",
                "Stock Name Here2...",
                "Stock Name Here3...",
              ]}
              options={{
                placeholder: "Please select the index",
              }}
              
            />
          </Index.Box>
        </Index.Box> */}

        <Index.Box className="report-intra-pos-main">
          <Index.Box className="report-intra-pos-sec">
            <Index.Grid container>
            {["Intraday", "Positional"].map((item, index) => (
                    <Index.Grid container xs={6} sm={4} md={5} lg={4}>
              <Index.Box 
                className={`report-intra-pos-btn ${downloadmodalIntraPositionBtn === item && "active"
                  } `}
                onClick={() => setDownloadmodalIntraPositionBtn(item) }
                key={index}
              >
                <svg
                  id="Group_508"
                  className="darck-check download-chart-round"
                  data-name="Group 508"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_184"
                        data-name="Rectangle 184"
                        width="10"
                        height="10"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth="1"
                      />
                    </clipPath>
                  </defs>
                  <rect
                    id="Rectangle_183"
                    data-name="Rectangle 183"
                    width="27"
                    height="27"
                    rx="4"
                    fill="#42506f"
                  />
                  <g
                    id="Mask_Group_23"
                    data-name="Mask Group 23"
                    transform="translate(9 9)"
                    clipPath="url(#clip-path)"
                  >
                    <g id="check" transform="translate(0 1.022)">
                      <g id="Group_507" data-name="Group 507">
                        <path
                          id="Path_359"
                          data-name="Path 359"
                          d="M13.457,6.1A.92.92,0,0,1,14.77,7.385L9.875,13.506a.92.92,0,0,1-1.325.025L5.3,10.285a.92.92,0,1,1,1.3-1.3l2.569,2.568,4.26-5.428a.328.328,0,0,1,.025-.027Z"
                          transform="translate(-5.011 -5.843)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                {item}
              </Index.Box>
              </Index.Grid>
            ))}
             </Index.Grid>
          </Index.Box>
          <Index.Box className="time-frame-sec">
            <Index.Typography
              className="modal-sub-title"
              variant="p"
              component="p"
            >
              Time Frame
            </Index.Typography>

            {downloadmodalIntraPositionBtn === "Intraday" ? (
              <Index.Box className="time-frame-content intraday-sec">
                <Index.Box className="time-frame-left">
                <Index.Grid container>
                  <Index.Box className="time-frame-ul-li">
                    <Index.List className="time-frame-ul">
                      {IntradayButtons.map((item, index) => (
                        <Index.Grid container xs={4} sm={4} md={2} lg={2}>
                        <Index.ListItem
                          className={`time-frame-li ${intradayBtnActive === index && "active"
                            } `}
                          onClick={() => setIntradayBtnActive(index)}
                          key={index}
                        >
                          <Index.Box className="day-btn">
                            <Index.Typography
                              className=""
                              variant="p"
                              component="p"
                            >
                              {item.title}
                            </Index.Typography>
                          </Index.Box>
                        </Index.ListItem>
                        </Index.Grid>
                      ))}
                    </Index.List>
                  </Index.Box>
                  </Index.Grid>
                </Index.Box>
              </Index.Box>
            ) : (
              <Index.Box className="time-frame-content positional-sec">
                <Index.Box className="time-frame-left">
                  <Index.Box className="time-frame-ul-li">
                    <Index.List className="time-frame-ul">
                      {PositionalButtons.map((item, index) => (
                        <Index.ListItem
                          className={`time-frame-li ${positionalBtnActive === index && "active"
                            } `}
                          onClick={() => setPositionalBtnActive(index)}
                          key={index}
                        >
                          <Index.Box className="day-btn">
                            <Index.Typography
                              className=""
                              variant="p"
                              component="p"
                            >
                              {item.title}
                            </Index.Typography>
                          </Index.Box>

                          {item.time.map((time, index) => (
                            <Index.Box className="time-btn-sec" key={index}>
                              <Index.Box className="time-frame-ul-li">
                                <Index.List className="time-frame-ul time-inner-ul">
                                  <Index.ListItem
                                    className={`time-frame-li time-frame-dark-text-color time-inner-li ${positionalTimeBtnActive === index &&
                                      "active"
                                      } `}
                                    onClick={() =>
                                      setPositionalTimeBtnActive(index)
                                    }
                                  >
                                    <Index.Typography
                                      className=""
                                      variant="p"
                                      component="p"
                                    >
                                      {time}
                                    </Index.Typography>
                                  </Index.ListItem>
                                </Index.List>
                              </Index.Box>
                            </Index.Box>
                          ))}
                        </Index.ListItem>
                      ))}
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="style-sec">
          <Index.Typography
            className="modal-sub-title"
            variant="p"
            component="p"
          >
            Style
          </Index.Typography>
          <Index.Grid container>
          <Index.Box className="modal-ul-li">
       
            <Index.List className="modal-ul">

              {DefaultModelStyleBttons.map((item, index) => (
                  <Index.Grid container xs={6} sm={6} md={3} lg={3}>
                <Index.ListItem
                  className={`modal-li cus-center ${defaultModelStyleBtnActive === index && "active"
                    } `}
                  onClick={() => setDefaultModelStyleBtnActive(index)}
                  key={index}
                >
                  <Index.Typography
                    className="moda-list-p"
                    variant="p"
                    component="p"
                  >
                    {" "}
                    <img
                      className="modal-chart-img"
                      src={item.icon}
                      alt="modalLine"
                    />
                    {item.title}
                  </Index.Typography>
                </Index.ListItem>
                </Index.Grid>
              ))}
            </Index.List>
          </Index.Box>
          </Index.Grid>
        </Index.Box>

        <Index.Box className="date-range-wrapper">
          <Index.Typography
            className="modal-sub-title"
            variant="p"
            component="p"
          >
            Date Range
          </Index.Typography>
         
          {/* <Index.Box className="date-range-sec date-placeholder">
          <Index.Grid container gap={2}>
            <Index.Grid container xs={12} sm={12} md={5} lg={5}>
            <Index.Box className="chart-date-picker">
              <Index.DatePickerMobile  label="Enter start date"/>
            </Index.Box>
            </Index.Grid>

            <Index.Grid container xs={12}  sm={12} md={1} lg={1} justifyContent={"center"} alignItems={"center"}> 

            <Index.Typography className="to-text" variant="p" component="p">
              To 
            </Index.Typography>
            </Index.Grid>
            <Index.Grid container xs={12}  sm={12} md={5} lg={5}>

            <Index.Box className="chart-date-picker">
              <Index.DatePickerMobile label="Enter end date" />
            </Index.Box>
            </Index.Grid>
            </Index.Grid>
          </Index.Box> */}

          <Index.Box className="date-range-sec">
            <Index.Box className="report-date-range-box report-modal-range-box download-chart-date">
              <Index.Box
                onClick={()=>{
                  if(dateRange.startDate && dateRange.endDate){
                    setCalenderDateRange(dateRange);
                  }
                  changeShowCalendar()
                }}
                className="report-date-box-cus"
              >
                <Index.TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  className="form-control"
                  placeholder="DD-MM-YYYY To DD-MM-YYYY"
                  value={
                    dateRange.startDate &&
                    Index.moment(dateRange.startDate).format("DD-MM-YYYY") +
                      "  To  " +
                      Index.moment(dateRange.endDate).format("DD-MM-YYYY")
                  }
                />
                <Index.Button onClick={handleClearDate}>
                  <Index.RefreshIcon />
                </Index.Button>
              </Index.Box>

              <Index.Box
                className={`report-date-range-calender-box ${
                  !showCalendar && "date-display-none"
                } `}
              >
                <Index.DateRangePicker
                  onChange={handleDateSelect}
                  ranges={[calenderDateRange]}
                  staticRanges={[]}
                  inputRanges={[]}
                  showPreview={false}
                  preview={false}
                  showDateDisplay={false}
                  className="responsive-date-range-picker  custom-picker-direction"
                />
                <Index.Box className="date-range-btns">
                  <Index.Button 
                    onClick={(e)=>{
                      setDateRange(calenderDateRange);
                      setShowCalendar(false)
                    }}
                    disabled={!(calenderDateRange.startDate && calenderDateRange.endDate)}
                  >
                    Apply
                  </Index.Button>
                  <Index.Button onClick={(e)=> {
                    setCalenderDateRange(dateRange);  
                    setShowCalendar(false)
                  }}>
                    Close
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
       
        </Index.Box>
        <Index.Box className="download-chart-btn">
        <Index.PrimaryButton
          className="primary-btn edit-download-btn custom-btn"
          btnLabel="Download"
          type="submit"
        ></Index.PrimaryButton>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DownloadChart;
