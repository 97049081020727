import { useEffect } from "react";
import notificationSound from "../../assets/audio/mp3/notification-sound.mp3";

const useNotification = ({ socket, userId }) => {
  const sound = new Audio(notificationSound);

  //Notification sound
  const handleNotification = (data) => {
    try {
      sound.play();
    } catch (error) {}
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (socket) {
        socket.off(`notification-${userId}`, handleNotification);
      }
    } else {
      if (socket) {
        socket.on(`notification-${userId}`, handleNotification);
      }
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on(`notification-${userId}`, handleNotification);

      return () => {
        socket.off(`notification-${userId}`, handleNotification);
      };
    }
  }, [socket]);
};

export default useNotification;
