import React from "react";
import Index from "../../Index";

const RedButton = (props) => {
  const { className, onClick, btnLabel } = props;
  return (
    <>
      <Index.Box className="red-btn-main">
        <Index.Button className={className} disableRipple onClick={onClick}>
          {btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
};

export default RedButton;
