// import type from "./type";

// const initialState = {
//   miniChartData: {}, // Change to an empty object instead of an array
//   miniChartDataArr: [],
// };

// const WebSocketReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case type.MINI_CHART_DATA:
//       return {
//         ...state,
//         miniChartData: {
//           ...state.miniChartData,
//           [action.payload.token]: [
//             ...(state.miniChartData[action.payload.token] || []).slice(-100),
//             action.payload.last_traded_price,
//           ],
//         },
//         // miniChartData:action.payload
//       };

      
//     default:
//       return state;
//   }
// };

// export default WebSocketReducer;
import type from "./type";

const initialState = {
  miniChartData: {},
};
const WebSocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.MINI_CHART_DATA:
      const { token, last_traded_price } = action.payload;
      const existingData = state.miniChartData[token] || [];
      const lastData = existingData[existingData.length-5];
      if (lastData !== last_traded_price) {
        // Push the new data only if it's different from the last one
        const updatedData = [...existingData, last_traded_price];
        return {
          ...state,
          miniChartData: {
            ...state.miniChartData,
            [token]: updatedData?.slice(-50),
          },
        };
      }
      // If the last data is the same as the new data, return the current state
      return state;

    default:
      return state;
  }
};

export default WebSocketReducer;
