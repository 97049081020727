import React, { useState } from "react";
import Index from "../Index";
import {
  activeShortCut,
  manageGridBorder,
  manageGridColor,
  manageGridSpacing,
} from "../../redux/user/action";

const GridModal = () => {
  const dispatch = Index.useDispatch();
  const { gridSpacing, gridBorder, gridColor } = Index.useSelector(
    (store) => store.UserReducer
  );
  const[error,setError]=useState("")
  return (
    <>
      <Index.Box className="grid-setting-wrapper grid-modal-main">
        <Index.Box className="gap-thickness-color-sec">
          <Index.Box className="gap-sec">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font"
                variant="p"
                component="p"
              >
                Grid Gap
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth-grid-gap"
                  className="form-control"
                  value={gridSpacing * 8}
                  onChange={(e) => {
                    console.log(e.target.value, "e.target.value");
                    // setOpacity(e.target.value / 100);
                    if(e.target.value<=30){
                      dispatch(manageGridSpacing(+e.target.value / 8));
                      setError("")
                    }else{
                      setError("Not more than 30")
                    }
               
                  }}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                  name="gap"
                  // placeholder="20"
                  autoComplete="off"
                />
                {error && (
                  <Index.FormHelperText  error className="error-text">
                        
                            {error}
                        </Index.FormHelperText>

                )}
                
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="gap-sec line-thickness">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font"
                variant="p"
                component="p"
              >
                Line Thickness
              </Index.Typography>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth-line-thickness"
                  className="form-control"
                  // placeholder="2"
                  value={gridBorder}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue.replace(/[^0-9]/g, "");
                    if (numericValue !== inputValue) {
                      e.target.value = numericValue;
                    }
                    dispatch(manageGridBorder(numericValue));
                  }}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                  name="thickness"
                  autoComplete="off"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="gap-sec line-color">
            <Index.Box className="cus-input-grp ">
              <Index.Typography
                className="cus-lable light-font"
                variant="p"
                component="p"
              >
                Line Color
              </Index.Typography>
              <Index.Box className="check-color-pick-sec">
                {/* <Index.Box className="check-color-box cus-center pink-bg">
                                    <img className='check-img' alt='checkWhite' src={Index.Svg.checkWhite} />

                                </Index.Box> */}
                <Index.MuiColorInput
                  // type="color"
                  variant="standard"
                  // className="color-input"
                  name="color"
                  value={gridColor}
                  onChange={(e) => {
                    // setOpacity(e.target.value / 100);
                    dispatch(manageGridColor(e));
                  }}
                  format="hex"
                  isAlphaHidden
                  inputProps={{
                    readOnly: true,
                    value: "",
                  }}
                />
                {/* <Index.Box className="check-color-box cus-center eyeDrop-sec">
                                    <img className='eyeDrop-img' alt='eyeDrop' src={Index.Svg.eyeDrop} />

                                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default GridModal;
