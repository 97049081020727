import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { profileFormValidations } from "../../../validation/Validations";
import { PostUserProfile, activeShortCut } from "../../../redux/user/action";
import { IconButton } from "@mui/material";
import { IMAGE_URL } from "../../../config/DataService";

const Profile = () => {
  const navigate = Index.useNavigate();
  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);
  const { displaySettings, twoSideArrowZoom } = Index.useSelector(
    (state) => state.UserReducer
  );
  const userLoginDetail = Index.useSelector(
    (state) => state.UserReducer.userLoginDetail
  );

  // Const declaration region start
  const dispatch = Index.useDispatch();
  // Const declaration region end
  // Hooks declaration region start
  const [user, setUser] = useState({
    email: userLoginDetail ? userLoginDetail.email : "",
    fullName: userLoginDetail ? userLoginDetail.firstName : "",
    mobileNumber: userLoginDetail?.mobileNumber
      ? userLoginDetail.mobileNumber
      : "",
    city: userLoginDetail ? userLoginDetail.city : "",
    state: userLoginDetail ? userLoginDetail.state : "",
    zipCode: userLoginDetail?.zipCode ? userLoginDetail.zipCode : "",
    profile: userLoginDetail ? userLoginDetail.profile : "",
  });

  const [profile, setProfile] = useState(null);

  const [userError, setUserError] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [imageExists, setImageExists] = useState(false);
  const [error, setError] = useState("");
  // Hooks declaration region end

  // Validation function start
  const handleProfileChange = (e) => {
    setError("");
    let img = e.target.files[0];
    // setProfile(img);
    if (img instanceof File) {
      setUser({
        ...user,
        ["profile"]: URL.createObjectURL(img),
      });
    } else {
      setUser({
        ...user,
        ["profile"]: "",
      });
    }
    const validationErrors = profileFormValidations({ ...user, profile: img });
    if (!validationErrors.profile) {
      setProfile(img);
    } else {
      setProfile(null);
    }
    setUserError((prevErrors) => ({
      ...prevErrors,
      profile: validationErrors.profile,
    }));
  };
  const onChangeInput = (event) => {
    setError("");
    const { name, value } = event.target;
    if (name === "zipCode" && value?.includes(".")) {
      return;
    }
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const validationErrors = profileFormValidations({ ...user, [name]: value });
    setUserError((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
  };
  // Validation function end

  // Submit handler function start
  const userLoginFormSubmit = async (event) => {
    event.preventDefault();
    setDisableBtn(true);
    const formData = new FormData();
    formData.append("fullName", user.fullName);
    formData.append("email", user.email);
    formData.append("mobileNumber", user.mobileNumber);
    formData.append("city", user.city);
    formData.append("state", user.state);
    formData.append("zipCode", user.zipCode);
    {
      profile && formData.append("profile", profile);
    }
    const validationErrors = profileFormValidations({ ...user, profile });
    if (Object.keys(validationErrors).length > 0) {
      setUserError(validationErrors);
      setDisableBtn(false);
    } else {
      dispatch(PostUserProfile(formData, userLoginToken, setError));
      setTimeout(() => setDisableBtn(false), 5000);
    }
  };
  function checkIfImageExists(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  }

  useEffect(() => {
    if (userLoginDetail.profile) {
      checkIfImageExists(IMAGE_URL + userLoginDetail.profile)
        .then((exists) => {
          setImageExists(exists);
        })
        .catch((error) => {
          setImageExists(false);
        });
    } else {
      setImageExists(false);
    }
  }, [userLoginDetail]);
  return (
    <>
      <Index.Box className="profile-main">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec profile-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            <Index.Box
              className="profile-wrapper"
              component="form"
              onSubmit={userLoginFormSubmit}
            >
              <Index.Box className="profile-sec cus-center">
                <Index.Box className="prof-img">
                  <img
                    className="profile-img"
                    alt="profile-img"
                    src={
                      profile
                        ? user.profile
                        : imageExists
                        ? `${IMAGE_URL}${userLoginDetail.profile}`
                        : Index.Jpg.profileImg
                    }
                  />
                  {/* <img
                    className="profile-img"
                    alt="profile-img"
                    src={`${IMAGE_URL}${userLoginDetail?.profile}`}
                  /> */}
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    className="profile-edit gradient-bg cus-center"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="profile"
                      onChange={handleProfileChange}
                    />
                    <img className="edit-ic" alt="edit" src={Index.Svg.edit} />
                  </IconButton>
                </Index.Box>
                <span className="auth-error-msg">{userError.profile}</span>
              </Index.Box>
              <Index.Box className="profile-input-wrapper">
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    Full Name
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      value={user.fullName}
                      fullWidth
                      id="fullWidth.fullName"
                      name="fullName"
                      className="form-control"
                      placeholder="Please enter full name"
                      autoComplete="off"
                      onChange={(e) => {
                        const userInput = e.target.value;
                        if (
                          /^[a-zA-Z\s]*$/.test(userInput) ||
                          userInput === ""
                        ) {
                          if (userInput.length <= 25) onChangeInput(e);
                        }
                      }}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">{userError.fullName}</span>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    Email
                  </Index.Typography>
                  <Index.Box className="form-group profile-email">
                    <Index.TextField
                      name="email"
                      value={user.email}
                      disabled
                      fullWidth
                      id="fullWidth-email-2"
                      className="form-control"
                      placeholder="Please enter email"
                      autoComplete="off"
                      onChange={onChangeInput}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">{userError.email}</span>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    Mobile Number
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="mobileNumber"
                      value={user.mobileNumber}
                      type="text"
                      fullWidth
                      id="fullWidth-mobile"
                      className="form-control"
                      placeholder="Please enter mobile number"
                      autoComplete="off"
                      onChange={(e) => {
                        let userInput = e.target.value;
                        if (
                          !isNaN(userInput) &&
                          !userInput.includes(".") &&
                          !(
                            userInput.length === 1 &&
                            userInput.charAt(0) === "0"
                          )
                        )
                          onChangeInput(e);
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">
                    {userError.mobileNumber}
                  </span>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    City
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="city"
                      value={user.city}
                      fullWidth
                      id="fullWidth-city"
                      className="form-control"
                      placeholder="Please enter city"
                      autoComplete="off"
                      onChange={(e) => {
                        const userInput = e.target.value;
                        if (
                          /^[a-zA-Z\s]*$/.test(userInput) ||
                          userInput === ""
                        ) {
                          if (userInput.length <= 25) onChangeInput(e);
                        }
                      }}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">{userError.city}</span>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    State
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="state"
                      value={user.state}
                      fullWidth
                      id="fullWidth-state"
                      className="form-control"
                      placeholder="Please enter state"
                      autoComplete="off"
                      onChange={(e) => {
                        const userInput = e.target.value;
                        if (
                          /^[a-zA-Z\s]*$/.test(userInput) ||
                          userInput === ""
                        ) {
                          onChangeInput(e);
                        }
                      }}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">{userError.state}</span>
                </Index.Box>
                <Index.Box className="cus-input-grp">
                  <Index.Typography
                    className="cus-lable light-font"
                    variant="p"
                    component="p"
                  >
                    Zip Code
                  </Index.Typography>
                  <Index.Box className="form-group">
                    <Index.TextField
                      value={user.zipCode}
                      fullWidth
                      name="zipCode"
                      type="text"
                      id="fullWidth-pincode"
                      className="form-control"
                      placeholder="Please enter zip code"
                      autoComplete="off"
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          !e.target.value.includes(".") &&
                          e.target.value.length <= 9
                        )
                          onChangeInput(e);
                      }}
                      onBlur={(e) => {
                        dispatch(activeShortCut(true));
                      }}
                      onFocus={(e) => {
                        dispatch(activeShortCut(false));
                      }}
                    />
                  </Index.Box>
                  <span className="auth-error-msg">{userError.zipCode}</span>
                </Index.Box>
              </Index.Box>

              {error && <span className="auth-error-msg">{error}</span>}

              <Index.Box className="profile-btn-main">
                <Index.PrimaryButton
                  className="primary-btn profile-save-btn"
                  btnLabel="Save Changes"
                  type="submit"
                  disabled={disableBtn}
                />
                <Index.PrimaryButton
                  className="primary-btn profile-save-btn"
                  btnLabel="Change Password"
                  type="button"
                  onClick={() => {
                    navigate("/user/create-new-password");
                  }}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Profile;
