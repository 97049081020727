import React from 'react'
import Index from '../Index'

const SideBarTop = () => {
    return (
        <>
        <Index.Box className='full-sidebar-top'>
            <Index.List className='main-ul'>
                <Index.ListItem className='main-li'>
                    <Index.Box className='btn-bg'>
                        <img className='head-btn-img' alt='i' src={Index.Svg.sidebarZoom} />
                    </Index.Box>
                </Index.ListItem>
            </Index.List>
            </Index.Box>
        </>
        
    )
}

export default SideBarTop