import React from "react";
import Index from "../../Index";

export default function PrimaryButton(props) {
  const {
    className,
    onClick,
    btnLabel,
    imgsrc,
    type,
    disabled = false,
    disableRipple = true
  } = props;

  return (
    <Index.Box className="primary-btn-main">
      <Index.Button
        className={className}
        type={type}
        disableRipple={disableRipple}
        onClick={onClick}
        disabled={disabled}
        variant="contained"
      >
        {btnLabel} <img src={imgsrc} />
      </Index.Button>
    </Index.Box>
  );
}
