import React from "react";
import Index from "../../Index";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const DatePickerMobile = ({ value, ...rest }) => {
  const momentValue = value ? value : null;
  return (
    <>
      <Index.Box className="datepicker-wrap ">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker className="mob-date-picker" value={momentValue} {...rest} />
        </LocalizationProvider>
      </Index.Box>
    </>
  );
};

export default DatePickerMobile;
