import axios from "axios";
import { DataService } from "../config/DataService";
import { Api } from "../config/Api";

// const BASE_URL = " http://localhost:3018/api/";
// const BASE_URL = "http://192.168.29.138:3018/api/";

// const setHeadersWithContentType = () => {
//   axios.defaults.headers.post["Content-Type"] =
//     "application/x-www-form-urlencoded";
// };
// const setHeadersWithContentTypeFormData = () => {
//   axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
// };
const setHeadersWithAccessToken = (token) => {
  axios.defaults.headers.post["auth"] = token;
};
const handleError = (error) => {
  for (const key in error) {
    if (Object.hasOwnProperty.call(error, key)) {
      const element = error["response"];
      return { message: element.data.message, status: element.status };
    }
  }
};

export const loginApiHandle = async (params) => {
  return DataService()
    .post(Api.auth.login, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const PostUserProfileApiHandle = async (params, token) => {
  return DataService(token)
    .post(Api.user.editProfile, params)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const GetAllShortcuts = async (token) => {
  return DataService(token)
    .get(Api.user.getAllShortcuts)
    .then((e) => e.data.data)
    .catch((e) => handleError(e));
};

// admin user management service

export const addUser = async (data) => {
  return DataService()
    .post(Api.admin.addUser, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const updateUser = async (data) => {
  return DataService()
    .post(Api.admin.updateUser, data)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getAllUser = async (token) => {
  return (
    DataService(token)
      .get(Api.admin.getAllUSer)
      // .get(`${Api.admin.getAllUSer}?page=${data.page}&perPage=${data.perPage}`)
      .then((e) => e.data)
      .catch((e) => handleError(e))
  );
};

export const deleteUser = async (id) => {
  return DataService()
    .post(Api.admin.deleteUser, { id: id })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getSingleUser = async (id) => {
  return DataService()
    .get(`${Api.admin.getSingleUSer}?id=${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const updateStatus = async (id, isActive, token) => {
  return DataService(token)
    .post(Api.admin.updateStatus, { id: id, isActive: isActive })
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getUserTagged = async (id) => {
  return DataService()
    .get(`${Api.admin.getUserTag}/${id}`)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};

export const getUserAllTagsList = async(token) => {
  return DataService(token)
  .get(`${Api.user.getAllTagList}`)
  .then((e) => e.data)
  .catch((e) => handleError(e));
};

export const doGet = (API_ENDPOINT,token) => {
  return DataService(token)
    .get(API_ENDPOINT)
    .then((e) => e.data)
    .catch((e) => handleError(e));
};
