import React, { useState } from "react";
import Index from "../../Index";
// import validations from "../../../validation/ValidationOnSubmit";
import { forgetPasswordValidations } from "../../../validation/Validations";
import { activeShortCut } from "../../../redux/user/action";
import OtpVerification from "./OtpVerification";

const ForgotPassword = () => {
  // Const declaration region start
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  // Const declaration region end

  // State declaration region start
  const [user, setUser] = useState({
    email: "",
  });
  const [userError, setUserError] = useState({});
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");

  const [userEmail, setUserEmail] = useState("");
  // State declaration region end

  // Hooks declaration region start
  // Hooks declaration region end

  // Function declaration region start
  // Input fields on change input handle start
  const onChangeInput = (e) => {
    setError("")
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const validationErrors = forgetPasswordValidations({
      ...user,
      [name]: value,
    });
    setUserError((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
    setDisable(Object.keys(validationErrors).length > 0);
  };

  // Input fields on change input handle end

  // Submit handler function start

  const handleForgetPassword = async (e) => {
    setDisable(true);
    setUserEmail(user.email);
    e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("email", user.email);

    const validationErrors = forgetPasswordValidations(user);
    if (Object.keys(validationErrors).length > 0) {
      setUserError(validationErrors);
    } else {
      try {
        const response = await Index.DataService().post(
          Index.Api.auth.forgotPassword,
          formData
        );
        const UserId = response.data.data._id;
        // Index.toast.success(response.data.message);
        // setTimeout(() => {
          navigate("/otp-verification", {
            state: { UserId: UserId, userEmail: user.email },
          });
          setDisable(false);
        // }, 1000);
      } catch (error) {
        // Index.toast.error(error?.response?.data?.message || error.message);
        setError(error?.response?.data?.message || error.message)
        setDisable(false);
        setUser({
          email: "",
        });
      }
    }
  };

  return (
    <>
      <Index.Box className="login-bg">
        <Index.Box
          className="login-inner-main auth-main custom-login-inner"
          component="form"
          onSubmit={handleForgetPassword}
        >
          <img className="main-logo" alt="logo" src={Index.Png.logo} />
          <Index.Typography variant="h1">Forgot Password</Index.Typography>
          <Index.Typography
            className="blue-text login-sub-heading cus-forgot-sub-heading"
            variant="p"
            component="p"
          >
            Enter your email and we'll send you a link to reset your password.
          </Index.Typography>
          <Index.Box className="cus-input-grp">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Enter your email
            </Index.Typography>
            <Index.Box className="form-group ">
              <Index.TextField
                fullWidth
                id="fullWidth-email"
                className="form-control custom-form-input"
                placeholder="Enter Email"
                name="email"
                value={user.email}
                onChange={onChangeInput}
                inputProps={{
                  maxLength: 50,
                }}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
              />
            </Index.Box>
            <span className="auth-error-msg">{userError.email}</span>
          </Index.Box>

          {error && (
            <span className="auth-error-msg">{error}</span>
          )}

          <Index.PrimaryButton
            className="primary-btn login-btn custom-btn"
            btnLabel="Forgot Password"
            type="submit"
            disabled={disable}
          />
          <Index.Link
            className="forgot-link cus-link cus-link-forgot-page"
            to="/"
          >
            <span> Back to Log In</span>
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ForgotPassword;
