import React from "react";
import Index from "../../Index";

const RoundButton = (props) => {
  const { className, onClick, btnLabel, imgsrc, alt } = props;
  return (
    <>
      <Index.Box className="round-btn-main">
        <Index.Button onClick={onClick} disableRipple className={className}>
          {btnLabel} <img src={imgsrc} alt={alt} />
        </Index.Button>
      </Index.Box>
    </>
  );
};

export default RoundButton;
