import React, { useEffect } from "react";
import Index from "../../component/Index";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { manageCategoryFilter } from "../../redux/user/action";

const PrivateLayout = ({ children }) => {
  const location = Index.useLocation();
  const dispatch = Index.useDispatch();
  const navigate = useNavigate();

  const UserLoggedIn = Index.useSelector(
    (state) => state.UserReducer.isUserLogin
  );

  // useEffect(()=>{
  //   dispatch(manageCategoryFilter([]));
  // },[location?.pathname])
  useEffect(() => {
    if (location.pathname === "/user" || location.pathname === "/user/") {
      navigate("/user/dashboard");
    }
  }, [location]);

  return UserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace={true} />
  );
  // return <Outlet />
};

export default PrivateLayout;
