import React, { useState } from "react";
import Draggable from "react-draggable";
import Index from "../Index";
import { Slider } from "@mui/material";

const annotationTypeList = [
  {
    id: 1,
    type: "ellipse",
    icon: Index.Svg.bxLineChart,
  },
  {
    id: 2,
    type: "line",
    icon: Index.Svg.bxLineChart,
  },
  {
    id: 3,
    type: "marker",
    icon: Index.Svg.bxLineChart,
  },
  {
    id: 4,
    type: "rectangle",
    icon: Index.Svg.bxLineChart,
  },
  {
    id: 5,
    type: "triangle",
    icon: Index.Svg.bxLineChart,
  },
  {
    id: 6,
    type: "fibonacciArc",
    icon: Index.Svg.fibonacciArc,
  },
];

const DrawChartModal = ({
  title,
  show,
  x,
  y,
  setShow,
  html,
  width,
  height,
  opacity,
  setOpacity,
  SVG,
  newChart,
}) => {  
  const themeTitle = localStorage.getItem("default-theme");

  const [isActive, SetIsActive] = useState(true);
  const [isSliderActive, setIsSliderActive] = useState(true);

  const handleClose = () => {
    SetIsActive(true);
    setShow(!show);
  };

  const handleOverlayClick = (e) => {
    // Close the modal only if the overlay itself is clicked, not its children
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const addAnnotation = (annotationType) => {
    if (newChart) {
      const controller = newChart.plot(0).annotations();
      newChart.title(`Annotations: ${annotationType} is selected`);
      newChart.annotations().startDrawing({annotationType});
    }
  };
  return (
    <>
      <Index.Box className="handle" style={{ opacity: opacity + 0.2 || 1 }}>
        <Index.Box className={``}>
          <Index.Box className="modal-wrapper drawing-modal-wrapper">
            <Index.Box className="modal-header cus-between">
              <Index.Box className="model-header-right cus-vertical-center">
                <Index.Box className="btn-bg opacity-btn test">
                  <p>Opacity</p>
                  <Index.Box sx={{ width: 55 }}>
                    <Slider
                      aria-label="Temperature"
                      color="secondary"
                      step={25}
                      value={opacity * 100 + 20 || 100}
                      onChange={(e) => {
                        setOpacity(e.target.value / 100);
                      }}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  className="model-header-left cus-vertical-center"
                  sx={{ marginLeft: "20px" }}
                >
                  <img
                    className="setting-ic"
                    alt="settingsolid"
                    width={20}
                    // src={Index.Svg.settingsSolid}
                    src={themeTitle==="Medium" ? Index.Svg.settingsSolid: themeTitle==="Light" ? Index.Svg.settingsLight:Index.Svg.settingsDark}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="drawing-tool-flex">
              {annotationTypeList?.map((ele) => {
                return (
                  <Index.Box
                    className="tool-wrapper"
                    key={ele?.id}
                    onClick={() => addAnnotation(ele?.type)}
                  >
                    <Index.Box className="model-header-left cus-vertical-center">
                      <img
                        className="setting-ic"
                        alt="settingsolid"
                        width={13}
                        src={ele?.icon}
                      />
                    </Index.Box>
                  </Index.Box>
                );
              })}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DrawChartModal;
