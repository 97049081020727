import React from "react";
import Index from "../../Index";

const SecondaryBtn = (props) => {
  const { className, onClick, btnLabel, imgsrc, sx } = props;
  return (
    <>
      <Index.Box className="secondary-btn-main">
        <Index.Button
          className={className}
          disableRipple
          onClick={onClick}
          sx={sx}
        >
          {btnLabel} <img src={imgsrc} />
        </Index.Button>
      </Index.Box>
    </>
  );
};

export default SecondaryBtn;
