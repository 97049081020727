import { React, useState } from "react";
import Index from "../../Index";
import anychart from "anychart";

let count = 50;
let rawData = [];
for (let i = 0; i < count; i++) {
  rawData.push([i, Math.random() * 10]);
}
let dataSet = anychart.data.set(rawData);

let counter = count;
setInterval(function () {
  dataSet.remove(0);
  dataSet.append([counter++, Math.random() * 10]);
}, 500);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1E2431",
  borderRadius: "10px 10px 0 0",
  border: "none",
  // boxShadow: 24,
  // p: 4,
};

const IntradayTab = (props) => {
  const { displaySettings } = Index.useSelector((state) => state.UserReducer);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { index } = props;

  return (
    <>
      <Index.Box className="intraday-tab-wrapper">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } `}
          >
            <Index.Box className=" dash-grid">
              <Index.Grid container spacing={2} className="chart-main">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
                  <Index.Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={index}
                    className="grid-main"
                  >
                    <Index.Box className="small-chart-box" onClick={handleOpen}>
                      <Index.ChartHeader />
                      <Index.Box className="line-chart-body">
                        <Index.CustomLineChart
                          index={index}
                          DivideHeight="4.4"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>
                ))}
              </Index.Grid>
            </Index.Box>
          </Index.Box>
          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Index.Box sx={style}>
              <Index.Box className="modal-header-sec">
                <Index.ChartHeader onClose={handleClose}></Index.ChartHeader>
              </Index.Box>
              <Index.Box className="modal-body-sec">
                <Index.Box className="chart-body">
                  <Index.Typography
                    className="cus-hover"
                    variant="p"
                    component="p"
                  >
                    O: 23.76; H: 24.04; L: 23.21; C: 23.37;
                  </Index.Typography>
                  <Index.CustomLineChart index={index} />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.SideBar></Index.SideBar>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default IntradayTab;
