import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
  deleteUser,
  getAllUser,
  updateStatus,
} from "../../../../service/UserIndex";
import { toast } from "react-toastify";
import {
  activeShortCut,
  clearSingleUserDetails,
  getSingleUserDetails,
} from "../../../../redux/user/action";

const UserList = () => {
  const navigate = useNavigate();
  const {
    displaySettings,
    rowsPerPage: listRowsPerPage,
    twoSideArrowZoom,
  } = Index.useSelector((state) => state.UserReducer);
  const [row, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("token");
  // const [page, setPage] = useState(1);
  // const [count, setCount] = useState(0);

  const [DeleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [loader, setLoader] = useState(true);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [count, setCount] = useState(0);
  const [dataTableHeight, setDataTableHeight] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: listRowsPerPage,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearch = (ele) => {
    if (!search) return ele;

    return (
      ele?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
      (
        ele?.firstName?.toLowerCase() +
        " " +
        ele?.lastName?.toLowerCase()
      )?.includes(search?.toLowerCase()) ||
      ele?.email?.toLowerCase()?.includes(search?.toLowerCase())
    );
  };

  const rows = useMemo(() => {
    if (row?.length < 1) return [];

    return row?.filter(handleSearch)?.map((ele, index) => ({
      sNo: index + 1,
      ...ele,
    }));
  }, [row, search]);

  const getUserList = (token) => {
    getAllUser(token).then((res) => {
      if (res?.data?.userData?.length) {
        setRows(
          res?.data?.userData?.map((ele, index) => {
            return {
              ...ele,
              sNo: index + 1,
            };
          })
        );
        setLoader(false);
      } else {
        setLoader(false);
        setRows([]);
      }
      const countData = Math.ceil(res?.data?.count / rowsPerPage);

      setCount(countData);
    });
  };
  const handleOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const handleDelete = () => {
    deleteUser(DeleteId, token).then((res) => {
      // toast.success(res?.message);
      handleCloseDelete();
      getUserList(token);
    });
  };

  const handleCloseDelete = () => setOpenDelete(false);

  const getRowId = (row) => row._id;

  const activeDeactiveUser = (id, isActive, userType) => {
    updateStatus(id, isActive, token).then((res) => {
      if (userType === "admin" && isActive === false) {
        // toast.success("Admin deactivated successfully");
      } else if (userType === "admin" && isActive === true) {
        // toast.success("Admin activated successfully");
      } else {
        // toast.success(res?.message);
      }
      getUserList(token);
    });
  };

  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 80,
      filterable: false,
      hideable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <>{params.value}</>,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 180,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 160,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 140,
      editable: false,
      align: "center",
      headerAlign: "center",
      padding: 0,
      renderCell: (params) => {
        const status = params.row.isActive;
        return (
          <div
            style={{
              color: status === true ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {status == true ? "Active" : "InActive"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      type: "number",
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const id = params.row._id;
        const status = params.row.isActive;
        const userType = params.row.userType;
        return (
          <Index.Box>
            <Index.Box className="d-flex">
              <button
                className="table-gray-btn"
                disableRipple
                onClick={() => {
                  // navigate(`${/add-edit/}${id}`);
                  navigate("/user/edit", { state: { row: params.row } });
                }}
              >
                <Index.EditIcon
                  className="action-btn-img action-btn-icon"
                  // onClick={() => {
                  //   navigate("/add-edit", { state: { row: params.row } });
                  // }}
                />
              </button>
              <button
                className="table-gray-btn"
                disableRipple
                onClick={() => handleOpenDelete(id)}
              >
                <img
                  src={Index.Svg.deleteRed}
                  className="action-btn-img"
                  onClick={() => handleOpenDelete(id)}
                />
              </button>
              <button
                className="table-gray-btn"
                disableRipple
                onClick={() =>
                  navigate("/user/users-tagged-scripts", {
                    state: { row: params.row },
                  })
                }
              >
                <img
                  src={Index.Svg.eyeIcon}
                  className="action-btn-img"
                  onClick={() =>
                    navigate("/user/users-tagged-scripts", {
                      state: { row: params.row },
                    })
                  }
                />
              </button>
              <Index.Switch
                className="switch-main"
                color="success"
                checked={status}
                inputProps={{
                  "aria-label": "controlled",
                }}
                onClick={() => activeDeactiveUser(id, !status, userType)}
              />
            </Index.Box>
          </Index.Box>
        );
      },
    },
  ];

  useEffect(() => {
    getUserList(token);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: listRowsPerPage,
    });
  }, [listRowsPerPage]);

  // pages

  //Data table height
  let mainContentElement = document.querySelector(".main-content-sec");
  let topButtonSecElement = document.querySelector(".user-list-flex");

  useEffect(() => {
    if (mainContentElement && topButtonSecElement) {
      const mainHeight = mainContentElement.clientHeight;
      const flexHeight = topButtonSecElement.clientHeight;
      setDataTableHeight(mainHeight - flexHeight - 65);
    }
  }, [mainContentElement, topButtonSecElement, twoSideArrowZoom]);

  return (
    <>
      <Index.Box className="dashboard-wrapper">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          <Index.Box className="d-flex user-list-flex">
            <Index.PrimaryButton
              className="primary-btn index-btn add-user"
              btnLabel="Add User"
              onClick={() => {
                navigate("/user/add", { state: { row: row } });
              }}
            />
            <Index.Box className="cus-input-grp">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  className="form-control"
                  placeholder="Search..."
                  onChange={(event) => {
                    const value = event.target.value;
                    setSearch(value);
                  }}
                  onBlur={(e) => {
                    dispatch(activeShortCut(true));
                  }}
                  onFocus={(e) => {
                    dispatch(activeShortCut(false));
                  }}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-sec">
            {!loader ? (
              rows && rows.length > 0 ? (
                <Index.Box
                  sx={{ width: "100%", height: dataTableHeight }}
                  className="user-management-table"
                >
                  <Index.DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[1000]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    localeText={{
                      MuiTablePagination: {
                        labelDisplayedRows: ({
                          from,
                          to,
                          count,
                          estimated,
                        }) => {
                          if (!estimated) {
                            return `${from} – ${to} of ${
                              count !== -1 ? count : `${to}`
                            }`;
                          }
                          return `${from} – ${to} of ${
                            count !== -1
                              ? count
                              : `${estimated > to ? estimated : to}`
                          }`;
                        },
                      },
                    }}
                  />
                </Index.Box>
              ) : (
                <Index.Box
                  sx={{ textAlign: "center", padding: "20px" }}
                  className="text-data"
                >
                  No data found
                </Index.Box>
              )
            ) : (
              <Index.TableLoader />
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="footer-sec">
        <Index.Box className="pagination-sec">
          {/* <Index.Pagination
            component="div"
            page={page}
            count={1}
            onChange={handleChangePage}
            // rowsPerPage={rowsPerPage}
            // rowsPerPageOptions={[10, 15, 20]}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            shape="rounded"
            hidePrevButton
            hideNextButton
          /> */}

          {/* <Index.Pagination
            component="div"
            page={page}
            count={count}
            onChange={handleChangePage}
            // rowsPerPage={rowsPerPage}
            // rowsPerPageOptions={[10, 15, 20]}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            shape="rounded"
            hidePrevButton
            hideNextButton
          /> */}
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="del-user-img-box">
            <img src={Index.Svg.deleteIcon} className="image-delete-user" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={handleDelete}
            >
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default UserList;
