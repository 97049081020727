import React from 'react'
import Index from '../Index'

const AlertModal = () => {
    return (
        <>
            <Index.Box className='body-p alert-wrapper'>
                <Index.Box className="alert-box">
                    <Index.Typography className='alert-modal-text' variant="p" component="p">Stock Name Here “Already added”</Index.Typography>
                    <img className='alert-img' alt='alert-img' src={Index.Png.alert} />
                    <Index.Box className="tag-sec">
                        <Index.Typography className='alert-modal-text' variant="p" component="p">Tag -</Index.Typography>
                        <Index.Box className="watch-sec">Watch</Index.Box>
                    </Index.Box>
                    <Index.Box className="tag-sec">
                        <Index.Typography className='alert-modal-text' variant="p" component="p">Time frame -</Index.Typography>
                        <Index.Box className="minuts-sec">5 Minutes</Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default AlertModal