import React, { useState } from "react";
import Index from "../../Index";
import { resetPasswordValidations } from "../../../validation/Validations";
import { activeShortCut } from "../../../redux/user/action";

const ResetPassword = () => {
  // State declaration region start
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });
  const [userError, setUserError] = useState({});
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const UserId = location.state;
  const [error, setError] = useState("");
  // State declaration region end

  // Hooks declaration region start
  // Hooks declaration region end

  // Function declaration region start
  // Input fields on change input handle start
  const onChangeInput = (e) => {
    setError("")
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const validationErrors = resetPasswordValidations({
      ...user,
      [name]: value,
    });
    setUserError((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
  };

  // Input fields on change input handle start

  // password eye button handler
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // Submit handler function start

  const handleResetPassword = async (e) => {
    setDisable(true);
    e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("password", user.password);
    formData.append("confirm_password", user.confirmPassword);
    formData.append("userId", UserId);
    const validationErrors = resetPasswordValidations(user);
    if (Object.keys(validationErrors).length > 0) {
      setUserError(validationErrors);
      setDisable(false);
    } else {
      try {
        const response = await Index.DataService().post(
          Index.Api.auth.resetPassword,
          formData
        );
        if (response.data.status === 200) {
          // Index.toast.success(response.data.message);
          setTimeout(() => {
            navigate("/", { state: UserId });
            setDisable(false);
          }, 1000);
        } else {
          // Index.toast.error(response.data.message);
          setError(response.data.message);
          setDisable(false);
        }
      } catch (error) {
        // Index.toast.error(error.response.data.message);
        setError(error.response.data.message);
        setTimeout(() => {
          setDisable(false);
        }, 1000);
      }
    }
  };
  // Submit handler function end

  return (
    <>
      <Index.Box className="login-bg">
        <Index.Box
          className="login-inner-main auth-main auth-main"
          component="form"
          method="post"
          onSubmit={handleResetPassword}
        >
          <img className="main-logo" src={Index.Png.logo} alt="logo" />
          <Index.Typography variant="h1">Reset Password</Index.Typography>
          <Index.Typography
            className="blue-text cus-forgot-sub-heading"
            variant="p"
            component="p"
          >
            Enter the email associated with your <br />
            account and we'll send an email
          </Index.Typography>
          <Index.Box className="cus-input-grp">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Password
            </Index.Typography>
            <Index.Box className="form-group">
              <Index.OutlinedInput
                name="password"
                fullWidth
                id="fullWidth-new-password"
                className="form-control custom-form-input cus-reset-password custom_form_control"
                placeholder="Enter password"
                value={user.password}
                onChange={onChangeInput}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <Index.InputAdornment
                    position="end"
                    className="pass_position"
                  >
                    <Index.IconButton
                      className="icon_end_icon"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Index.VisibilityOff className="pass-icon-color-admin" />
                      ) : (
                        <Index.Visibility className="pass-icon-color-admin" />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
              />
            </Index.Box>
            <span className="auth-error-msg">{userError.password}</span>
          </Index.Box>

          <Index.Box className="cus-input-grp input-grp-confirm-password">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Confirm password
            </Index.Typography>
            <Index.Box className="form-group">
              <Index.OutlinedInput
                name="confirmPassword"
                fullWidth
                id="fullWidth-confirm-password"
                className="form-control custom-form-input cus-reset-password custom_form_control"
                placeholder="Enter confirm password"
                value={user.confirmPassword}
                onChange={onChangeInput}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <Index.InputAdornment
                    position="end"
                    className="pass_position"
                  >
                    <Index.IconButton
                      className="icon_end_icon"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Index.VisibilityOff className="pass-icon-color-admin" />
                      ) : (
                        <Index.Visibility className="pass-icon-color-admin" />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
              />
            </Index.Box>
            <span className="auth-error-msg">{userError.confirmPassword}</span>
          </Index.Box>

          {error && (
            <span className="auth-error-msg">{error}</span>
          )}

          <Index.PrimaryButton
            className="primary-btn login-btn custom-btn"
            btnLabel="Submit"
            // onClick={handleResetPassword}
            disabled={disable}
            type="submit"
          />
          <Index.Link className="forgot-link cus-link" to="/">
            <span> Back to Home</span>
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ResetPassword;
