import anychart from "anychart";
import AnyChart from "anychart-react";
import React, { memo, useEffect, useRef } from "react";
import Index from "../../Index";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const CustomCandlestickMiniChart = ({ chartData, token }) => {
  const { miniChartSize, GraphStyle, isFlip } = Index.useSelector(
    (state) => state.UserReducer
  );
  const chartRef = useRef(null);
  const chartDataSetRef = useRef(null);

  useEffect(() => {
    chartDataSetRef.current = anychart.data.set(
      chartData?.map((item) => [
        setDatefun(item[0]),
        item[1],
        item[2],
        item[3],
        item[4],
      ])
    );
  }, [chartData]);

  useEffect(() => {
    if (chartData?.length) {
      if (chartDataSetRef.current) {
        const lastPrice = chartData?.length && chartData;

        const index = lastPrice[chartData?.length - 1].length == 2 ? 1 : 4;

        let formattedDate = Index.moment()
          .utcOffset("+05:30")
          .format("YYYY-MM-DDTHH:mm:ssZ");
        chartDataSetRef.current.append([
          lastPrice[chartData?.length - 1][index],
          lastPrice[chartData?.length - 1][index],
          lastPrice[chartData?.length - 1][index],
          lastPrice[chartData?.length - 1][index],
        ]);
      }
    }
  }, [chartData, token]);

  useEffect(() => {
    const container = document.getElementById(token);
    if (container) {
      container.innerHTML = "";
      container.style.height = `${miniChartSize?.height}px`;
      container.style.width = `${miniChartSize?.width}px`;

      const chart = anychart.candlestick();
      chart.background().fill("transparent");

      const series = chart.candlestick(
        chartDataSetRef?.current?.mapAs({
          open: 1,
          high: 2,
          low: 3,
          close: 4,
        })
      );
      series.name("CSCO");
      series.legendItem().iconType("rising-falling");
      series.risingFill(GraphStyle.candleStickGraph.risingColor);
      series.fallingFill(GraphStyle.candleStickGraph.fallingColor);
      series.risingStroke(
        GraphStyle.candleStickGraph.risingColor,
        GraphStyle.candleStickGraph.thickness
      );
      series.fallingStroke(
        GraphStyle.candleStickGraph.fallingColor,
        GraphStyle.candleStickGraph.thickness
      );
      // series.normal().stroke('#FF0000', 1.5);
      chart.yScale().inverted(isFlip);
      chart.xAxis(false);
      chart.yAxis(false);
      chart.tooltip(false);

      chart.container(container).draw();

      chartRef.current = chart;
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [token, chartData, miniChartSize, GraphStyle, isFlip]);

  return (
    <AnyChart
      id={token}
      style={{ height: miniChartSize?.height, width: miniChartSize?.width }}
    />
  );
};

export default memo(CustomCandlestickMiniChart);
