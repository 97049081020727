import React, { useEffect, useReducer, useState } from "react";
import Index from "../Index";
import { useDispatch, useSelector } from "react-redux";
import {
  sortWishListData,
  manageCurrentTag,
  getWishListData,
  manageSortingTags,
} from "../../redux/user/action";
import { getUserAllTagsList } from "../../service/UserIndex";
import { Tooltip } from "@mui/material";

const sortType = ["Buy", "Sell", "Watch"];

const SortingModal = (props) => {
  const dispatch = useDispatch();
  // const [isDataWithSorted, setIsDataWithSorted] = useState(true);

  const { token, currentTag, sortingTags, isSortedWishlist } = useSelector(
    (state) => state.UserReducer
  );

  function filterDataBySequence(data, sequence) {
    const filteredData = [];
    if (sequence?.length) {
      for (let i = 0; i < sequence?.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (data[j]?.tag?.toLowerCase() === sequence[i]?.toLowerCase()) {
            filteredData.push(data[j]);
          }
        }
      }
    } else {
      filteredData = data;
    }
    return filteredData;
  }

  const filterFromOptions = async (tag) => {
    let data = await getUserAllTagsList(token);

    const filterTag = filterDataBySequence(data?.data, sortingTags);

    props?.setSortedDataOption &&
      props?.setSortedDataOption((prev) => ({
        ...prev,
        ["tag"]: sortingTags,
      }));
    dispatch(manageCurrentTag({ tag: sortingTags, filterTag }));
    // dispatch()
    // props?.setShow(!props?.show);
  };

  const handleAscDescFilter = () => {
    props?.setSortedDataOption &&
      props?.setSortedDataOption((prev) => ({
        ...prev,
        ["isAsc"]: !props?.sortedDataOption?.isAsc,
      }));
    // setIsDataWithSorted((e) => !e);
    // dispatch(`sortWishListData`(props?.sortedDataOption?.isAsc));
    dispatch(sortWishListData(!isSortedWishlist));
    // props?.setShow(!props?.show);
  };

  const resetFilter = async () => {
    dispatch(manageCurrentTag({ tag: "" }));
    dispatch(manageSortingTags(""));
    // setIsDataWithSorted(true);
    props?.setSortedDataOption &&
      props?.setSortedDataOption({
        isAsc: true,
        tage: "",
      });
    dispatch(sortWishListData('reset'));
    // props?.setShow(!props?.show);
  };

  useEffect(() => {
    if (sortingTags?.length) {
      filterFromOptions();
    } else {
      dispatch(manageCurrentTag({ tag: "" }));
    }
  }, [sortingTags]);

  useEffect(() => {
    getUserAllTagsList(token);
  }, []);

  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Index.Box className="filter-btn-main" disableRipple>
            <Tooltip title="Reset">
              <Index.Button
                onClick={resetFilter}
                className="filter-btn cus-center"
              >
                {" "}
                <img
                  className="reload-img"
                  src={Index.Svg.filterIc}
                  alt="filterIc"
                />
              </Index.Button>
            </Tooltip>
          </Index.Box>
          <Index.Box className="name-wrap">
            <Index.Typography className="cus-lablel" variant="p" component="p">
              Name
            </Index.Typography>
            <Index.Box className="btn-bg cus-center active">
              <img
                className="head-btn-img sort-icon"
                alt="zTOa"
                // src={isDataWithSorted ? Index.Svg.aToz : Index.Svg.zTOa}
                src={!isSortedWishlist ? Index.Svg.aToz : Index.Svg.zTOa}
                // onClick={() => {
                //   dispatch(sortWishListData(isDataWithSorted));
                //   props?.setSortedDataOption  && props?.setSortedDataOption((prev)=>({...prev, ["isAsc"]:!isDataWithSorted}))
                //   setIsDataWithSorted((e) => !e);
                // }}
                onClick={handleAscDescFilter}
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="name-wrap">
            <Index.Typography className="cus-lablel" variant="p" component="p">
              Tag
            </Index.Typography>
            <Index.Box className="sorting-tag-sec">
              {sortType.map((e, i) => {
                return (
                  <>
                    <Index.Box
                      className={`tag-main ${
                        sortingTags?.includes(e) ? "sorting-tag-active" : ""
                      }`}
                      onClick={() => {
                        dispatch(manageSortingTags(e));
                        // filterFromOptions();
                      }}
                    >
                      <Index.Box
                        className={`btn-bg cus-center ${
                          e === "Watch" ? "last-child" : ""
                        } || ${
                          sortingTags?.includes(e) ? "sort-sr-btn-active" : ""
                        }`}
                      >
                        {i + 1}
                      </Index.Box>
                      <Index.Typography
                        className={`tag-label ${
                          sortingTags?.includes(e) ? "sort-active-label" : ""
                        }`}
                        variant="p"
                        component="p"
                      >
                        {e}
                      </Index.Typography>
                    </Index.Box>
                  </>
                );
              })}
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="name-wrap">
            <Index.Typography className="cus-lablel" variant="p" component="p">
              Time Frame
            </Index.Typography>
            <Index.Box className="sorting-tag-sec sorting-time-frame">
              <Index.Box className="tag-main">
                <Index.Typography
                  className="tag-label"
                  variant="p"
                  component="p"
                >
                  Minimum
                </Index.Typography>
              </Index.Box>
              <Index.Box className="arrow-round-sec cus-center">
              
                <img
                  className="arrow-round-img right-arrow-img"
                  src={Index.Svg.rightRoundArrow}
                  alt="rightRoundArrow"
                />
                <img
                  className="arrow-round-img left-arrow-img"
                  src={Index.Svg.leftRoundArrow}
                  alt="leftRoundArrow"
                />
              </Index.Box>
              <Index.Box className="tag-main">
                <Index.Typography
                  className="tag-label"
                  variant="p"
                  component="p"
                >
                  Maximum
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SortingModal;
