import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { DataService, SOCKET_URL } from "./config/DataService.js";
import { useSelector } from "react-redux";

const URL = `${SOCKET_URL}?stock_auth=${localStorage.getItem(
  "token"
)}`;

// const URL = `http://localhost:3018?stock_auth=${localStorage.getItem(
//   "token"
// )}`;

// const URL = `http://192.168.29.34:3018?stock_auth=${localStorage.getItem(
//   "token"
// )}`;

const socket = io(URL);

export function useStockDataListener() {
  const [stockData, setStockData] = useState(null);

  useEffect(() => {
    const handleStockData = (data) => {
      // Update the state with the received data
      setStockData(data);
    };

    // Add the event listener
    socket.on("requestStockData", handleStockData);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("requestStockData", handleStockData);
    };
  }, []); // Empty dependency array to run the effect once on mount

  return stockData;
}

export const Subscribe_stock_tokens = (tokens) => {
  socket.emit("requestStockData", { tokens });
};

export const getPrviousDataOfStock = async (tokens) => {
  try {
    const data = await DataService().post("/user/get-last-five-data", {
      token: tokens,
    });
  } catch (err) {}
};

// Define your custom hook
export function useSocketData() {
  const [socketData, setSocketData] = useState({});
  const addSocketData = (token, data) => {
    setSocketData((prevData) => ({
      ...prevData,
      [token]: data,
    }));
  };
  return {
    socketData,
    addSocketData,
  };
}
