import { React, useState } from "react";
import Index from "../../Index";

const SixColumn = (props) => {
  const [headMenu, setHeadMenu] = useState(false);
  const {
    index,
    data,
    chartStyle,
    headerData,
    handleOpenLineChart,
    handleOpenAreaChart,
    handleOpenCandleChart,
  } = props;
  return (
    <Index.Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={12 / 7}
      key={index}
      className="grid-main"
    >
      <Index.Box className="small-chart-box">
        {chartStyle === 0 && (
          <Index.Box
            className="line-chart-body chart-hover-body"
            onClick={() => handleOpenLineChart(index)}
          >
            <Index.CustomLineChart
              shareData={data}
              index={index}
              DivideHeight="8"
              borderRadiusClass={"border-radius-graph-all"}
            />
            <HoverSection
              headMenu={headMenu}
              setHeadMenu={setHeadMenu}
              headerData={headerData}
            />
          </Index.Box>
        )}
        {chartStyle === 1 && (
          <Index.Box
            className="line-chart-body chart-hover-body"
            onClick={() => handleOpenAreaChart(index)}
          >
            <Index.CustomAreaChart
              shareData={data}
              index={index}
              DivideHeight="8"
              borderRadiusClass={"border-radius-graph-all"}
            />
            <HoverSection
              headMenu={headMenu}
              setHeadMenu={setHeadMenu}
              headerData={headerData}
            />
          </Index.Box>
        )}
        {chartStyle === 3 && (
          <Index.Box
            className="line-chart-body chart-hover-body"
            onClick={() => handleOpenCandleChart(index)}
          >
            <Index.CustomCandlestickChart
              shareData={data}
              index={index}
              DivideHeight="8"
              borderRadiusClass={"border-radius-graph-all"}
            />
            <HoverSection
              headMenu={headMenu}
              setHeadMenu={setHeadMenu}
              headerData={headerData}
            />
          </Index.Box>
        )}
      </Index.Box>
    </Index.Grid>
  );
};

export default SixColumn;

const HoverSection = (props) => {
  const { headMenu, setHeadMenu, headerData } = props;
  return (
    <Index.Box className="chart-hover-sec">
      <Index.Box className="watch-dot-btn-sec">
        <Index.Box className="dots-btn-sec">
          <Index.RedButton
            className="red-btn buy-btn buy-sell-btn bg-blue"
            btnLabel="Watch"
          />
          <img
            className="white-dots-img"
            onClick={() => setHeadMenu(!headMenu)}
            alt="threeDotsWhite"
            src={Index.Svg.threeDotsWhite}
          />
          {headMenu && (
            <Index.Box className="head-menu">
              <Index.List className="ul">
                <Index.ListItem className="li">
                  <Index.Typography
                    className="mint-text"
                    variant="p"
                    component="p"
                  >
                    Buy
                  </Index.Typography>
                </Index.ListItem>
                <Index.ListItem className="li">
                  <Index.Typography
                    className="mint-text"
                    variant="p"
                    component="p"
                  >
                    Sell
                  </Index.Typography>
                </Index.ListItem>
                <Index.ListItem className="li active">
                  <Index.Typography
                    className="mint-text"
                    variant="p"
                    component="p"
                  >
                    Watch
                  </Index.Typography>
                </Index.ListItem>
                <Index.ListItem className="li">
                  <Index.Typography
                    className="mint-text"
                    variant="p"
                    component="p"
                  >
                    Reverse
                  </Index.Typography>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
      <Index.Box className="hover-detail-sec">
        <Index.Box className="chart-header-left">
          <Index.Typography
            className="chart-title chart-text"
            variant="p"
            component="p"
          >
            Tata
          </Index.Typography>
          <Index.Box className="d-flex">
            <Index.Box className="chart-text red-color">
              <span>$</span>3214{" "}
              <img
                className="red-down-aroow"
                alt="downRedArrow"
                src={Index.Svg.downRedArrow}
              />
            </Index.Box>
            <Index.Typography
              className="chart-text value-text"
              variant="p"
              component="p"
            >
              <span>$</span>120 <span className="green-color">(20%)</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="cus-center one-min-bg">1 Minute</Index.Box>
        <Index.RoundButton
          className="round-btn download-btn hov-dow-btn"
          imgsrc={Index.Svg.download}
        />
      </Index.Box>
    </Index.Box>
  );
};
