import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useLocation } from "react-router-dom";

const OtpVerification = () => {
  const [otpValue, setOtpValue] = useState("");
  const [otpError, setOtpError] = useState("");
  const [disable, setDisable] = useState(true);
  const [timeLeft, setTimeLeft] = useState(120);
  const navigate = Index.useNavigate();
  const location = useLocation();
  const { UserId, userEmail } = location.state;
  const [error, setError] = useState("");

  useEffect(() => {
    if (timeLeft === 0) return;
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `0${minutes} : ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const onChangeInput = (value) => {
    setError("");
    setOtpValue(value);
    if (value.length === 0) {
      setOtpError("Please enter OTP");
      setDisable(true);
    } else {
      setOtpError("");
      setDisable(false);
    }
  };

  const validateOtp = () => {
    if (otpValue.length === 0) {
      setOtpError("Please enter OTP");
      setDisable(true);
    }
    if (otpValue.length < 4) {
      setOtpError("Please enter valid OTP");
      setDisable(true);
    }
  };

  const handleOtpVerification = async (e) => {
    setDisable(true);
    e.preventDefault();
    validateOtp();
    if (otpValue !== "" && otpError === "" && otpValue?.length == 4) {
      const formData = new URLSearchParams();
      formData.append("otp", otpValue);
      formData.append("id", UserId);
      try {
        const response = await Index.DataService().post(
          Index.Api.auth.otpVerification,
          formData
        );
        const userId = response.data.data._id;
        // Index.toast.success(response.data.message);
        // setTimeout(() => {
        navigate("/reset-password", { state: userId });
        setDisable(false);
        // }, 2000);
      } catch (error) {
        // Index.toast.error(error.response.data.message);
        setError(error.response.data.message);
        setOtpValue("");
        setTimeout(() => {
          setDisable(false);
        }, 2000);
      }
    }
  };

  const handleKeyDown = (e) => {
    const isNumberKey =
      (e.key >= "0" && e.key <= "9") ||
      e.key === "Backspace" ||
      e.key === "Delete";
    if (!isNumberKey) {
      e.preventDefault();
    }
  };

  const handleForgetPassword = async (e) => {
    setDisable(true);
    e.preventDefault();
    const formData = new URLSearchParams();
    formData.append("email", userEmail);
    try {
      const response = await Index.DataService().post(
        Index.Api.auth.forgotPassword,
        formData
      );
      const UserId = response.data.data._id;
      // Index.toast.success(response.data.message);

      setTimeLeft(120); // Reset timer
      // setTimeout(() => {
        navigate("/otp-verification", { state: { UserId, userEmail } });
        setDisable(false);
      // }, 2000);
    } catch (error) {
      // Index.toast.error(error?.response?.data?.message || error.message);
      setError(error?.response?.data?.message || error.message);
      setDisable(false);
    }
  };

  return (
    <>
      <Index.Box className="login-bg">
        <Index.Box
          className="login-inner-main auth-main"
          component="form"
          method="post"
          onSubmit={handleOtpVerification}
        >
          <img className="main-logo" src={Index.Png.logo} alt="logo" />
          <Index.Typography variant="h1">OTP Verification</Index.Typography>
          <Index.Typography className="blue-text" variant="p" component="p">
            We have sent OTP on your registered email address
          </Index.Typography>
          <Index.Box className="cus-input-grp otp-sec">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Enter OTP
            </Index.Typography>
            <Index.Box className="otp-inpt-grp">
              <Index.Box className="form-group">
                <MuiOtpInput
                  className="form-control"
                  length={4}
                  value={otpValue}
                  onChange={onChangeInput}
                  onKeyDown={handleKeyDown}
                />
              </Index.Box>
            </Index.Box>
            <span className="auth-error-msg">{otpError}</span>
          </Index.Box>

          {error && <span className="auth-error-msg">{error}</span>}
          <Index.PrimaryButton
            className="primary-btn login-btn custom-btn"
            btnLabel="Submit"
            type="submit"
            disabled={disable}
          />
          <div className="resend-container">
            {timeLeft != 0 ? (
              <a className="resend-timer check-target">
                Time Remaining: {formatTime(timeLeft)}
              </a>
            ) : (
              <Index.Button onClick={handleForgetPassword} disabled={disable}>
                <a className="forgot-link">Resend OTP</a>
              </Index.Button>
            )}
          </div>
          <Index.Link className="forgot-link cus-link" to="/">
            <span className="move-login"> Back to Log In</span>
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default OtpVerification;
