import React, { useEffect, memo, useRef } from "react";
import AnyChart from "anychart-react";
import Index from "../../Index";
import anychart from "anychart";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const CustomMiniHistoricalChart = ({ token, chartData, index, exchangeType }) => {
  const [LineData, setLineData] = React.useState([]);
  const { token: userToken ,chartDuration,miniChartSize} = Index.useSelector((state) => state.UserReducer);
  const chartRef = useRef(null);
  const chartDataSetRef = useRef(null);
  const getHistorylist = () => {
    const formData = new URLSearchParams();
    formData.append("exchange", exchangeType || "NSE");
    formData.append("symboltoken", token && token );
    formData.append("interval", "ONE_MINUTE");
    formData.append(
      "fromdate",
      Index.moment().set({ hour: 9, minute: 15 }).format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      Index.moment()
        .add(1, "d")
        .set({ hour: 3, minute: 30 })
        .format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(userToken)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.data) {
          let format = res.data.data.map((el) => {
            return [setDatefun(el[0]), el[4]];
          });
          // setData1(format);
          chartDataSetRef.current = anychart.data.set(format);

        }
      })
      .catch((err) => {
      });
  };


  useEffect(() => {
    setTimeout(()=>{
      getHistorylist();
    },index * 2500)
  }, [token]);

  // useEffect(() => {
  //   // if (!chartDataSetRef.current) {
  //   //   chartDataSetRef.current = anychart.data.set([]);
  //   // }
  //   // Update LineData when new data is received

  //   console.log(chartData?.[token] , "CUSTOM MINI CHART")

  //   if(chartData?.[token]){
  //     if (chartDataSetRef.current) {
  //       const lastPrice = chartData[token] && chartData[token];
  //       // if (lastPrice?.length<10) {
          
  //       // chartDataSetRef.current.append([new Date().getTime(), lastPrice[chartData[token]?.length-1][5]]);
  //       // }else{
  //       // chartDataSetRef.current.remove(0);
  //       const index = lastPrice[chartData[token]?.length - 1].length == 2 ? 1 : 5;
  
  //       chartDataSetRef.current.append([
  //         new Date().getTime(),
  //         lastPrice[chartData[token]?.length - 1][index],
  //       ]);
  //       // }
  //     }
  //   }


  // }, [chartData, token]);

  useEffect(() => {
    const container = document.getElementById(token);
    if (container) {
      container.innerHTML = "";
      // container.style.height = "50px";
      // container.style.width = "150px";
      container.style.height = `${miniChartSize?.height}px`;
      container.style.width = `${miniChartSize?.width}px`;

      const chart = anychart.line();
      chart.xScale().mode("continuous");
      chart.background().fill("transparent");

      const series = chart.line(
        chartDataSetRef?.current?.mapAs({ x: 0, value: 1 })
      );
      // series.normal().stroke('#FF0000', 1.5);
      chart.xAxis(false);
      chart.yAxis(false);
      chart.tooltip(false);

      chart.container(container).draw();

      chartRef.current = chart;
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [token,chartData]);


 
  return <AnyChart id={token} style={{ height: miniChartSize?.height , width: miniChartSize?.width }} />;
};

export default memo(CustomMiniHistoricalChart);
