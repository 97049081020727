import { React, useEffect, useMemo, useState } from "react";
import Index from "../../Index";
import {
  Subscribe_stock_tokens,
  useSocketData,
  getPrviousDataOfStock,
  useStockDataListener,
} from "../../../socket";
import { useSelector } from "react-redux";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import { ContactsOutlined } from "@mui/icons-material";
import CustomMiniChart from "../../../component/custom-chart/mini-chart/CustomMiniChart";
import moment from "moment";
import { toast } from "react-toastify";
import CustomMiniHistoricalChart from "../../../component/custom-chart/mini-chart/CustomMiniHistoricalChart";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#1E2431",
  borderRadius: "10px 10px 0 0",
  border: "none",
};

const allStockTypes = [
  { id: 1, name: "All", value: "All" },
  { id: 2, name: "Equity", value: "NSE" },
  { id: 3, name: "F&O", value: "MCX" },
];

const OpenOrderList = (props) => {
  const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse", "Remove"];
  let stock_data = useStockDataListener();
  const { socketData, addSocketData } = useSocketData();
  const [allTagsData, setAllTagsData] = useState([]);
  const [stockCategoryFilter, setStockCategoryFilter] = useState("All");
  const {
    wishListData,
    token,
    displaySettings,
    timeFrame,
    chartView,
    chartDuration,
    rowsPerPage,
    twoSideArrowZoom,
  } = useSelector((state) => state.UserReducer);
  const [miniChartOpen, setminiChartOpen] = useState(false);
  const [isActive, SetIsActive] = useState(true);
  const [active, Setactive] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setminiChartOpen(true);
  const [tags, setTag] = useState("watch");
  const [scriptData, setScriptData] = useState();
  const [activeMiniChartOpen, setActiveMiniChartOpen] = useState();
  const [openModel, setOpenModel] = useState({});
  const [currenChart, setCurrenChart] = useState("");
  const [orderCurrentChart, setOrderCurrentChart] = useState("");
  const [updateOrder, setUpdateOrder] = useState({});
  const [placeOrderData, setPlaceOrderData] = useState({});
  const [editOrderData, setEditOrderData] = useState({});
  const [targetOrTopLoss, setTargetOrTopLoss] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });
  const handleClose = () => {
    setOpenModel({});
    setminiChartOpen(false);
  };
  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);
  const currentTime = moment();
  const marketOpenTime = moment().set({ hour: 9, minute: 15, second: 0 });
  const marketCloseTime = moment().set({ hour: 15, minute: 30, second: 0 });

  const isMarketOpen = currentTime.isBetween(
    marketOpenTime,
    marketCloseTime,
    null,
    "[]"
  );

  useEffect(() => {
    Index.DataService(userLoginToken)
      .get(`${Index.Api.user.getAllTags}?open=true`)
      // Index.DataService(userLoginToken).get(`${Index.Api.user.getAllTags}`)
      .then((data) => {
        setAllTagsData(data?.data?.data);
      })
      .catch((error) => {});
  }, []);

  const columns = [
    { field: "id", headerName: "Sr.", width: 76, headerAlign: "center", hideable: false, align:'center' },
    {
      field: "script",
      headerName: "Script",
      width: 200,
      editable: false,
      align:'center',
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 120,
      editable: false,
      align:'center',
      headerAlign: "center",

      renderCell: (params) => (
        <Index.Button
          className={
            params.value.toLowerCase() === "buy"
              ? "green-buy-btn"
              : params.value.toLowerCase() === "sell"
              ? "red-buy-btn"
              : params.value.toLowerCase() === "watch"
              ? "blue-watch-btn"
              : "green-buy-btn"
          }
          disableRipple
        >
          {params.value}
        </Index.Button>
      ),
    },
    {
      field: "CMP",
      headerName: "CMP",
      headerAlign: "center",
      type: "number",
      width: 150,
      editable: false,
      align:'center',
    },
    {
      field: "miniChart",
      headerName: "Mini Chart",
      type: "number",
      width: 200,
      headerAlign: "center",
      align:'center',
      editable: false,
      renderCell: (params) => (
        <Index.Button
        onClick={() => {
          handleOpen();
          setActiveMiniChartOpen(params?.value?.token);
        }}
        disableRipple
        >
          {/* <CustomLineChartSingle
            height={65}
            width={200}
            liveData={socketData}
            index={`1_${params?.value?.token}`}
            stockToken={params?.value?.token}
          /> */}
          {isMarketOpen ? (
            <CustomMiniChart
              chartData={socketData}
              token={JSON.parse(params?.value?.token)}
            />
          ) : (
            <CustomMiniHistoricalChart
              chartData={params?.value?.stockData || []}
              // chartData={socketData}
              token={JSON.parse(params?.value?.token)}
              index={params?.value?.index}
              exchangeType={params?.value?.exchange_type}
            />
          )}
        </Index.Button>
      ),
    },
    {
      field: "entry",
      headerName: "Entry",
      type: "number",
      width: 150,
      editable: false,
      headerAlign: "center",
      align:'center',
      
      // renderCell: (params) => <img className='table-img' src={params.value} />
    },
    {
      field: "stopLoss",
      headerName: "Stop Loss",
      type: "number",
      width: 120,
      editable: false,
      headerAlign: "center",
      align:'center',
      padding: 0,
    },
    {
      field: "target",
      headerName: "Target",
      type: "number",
      width: 120,
      editable: false,
      headerAlign: "center",
      align:'center',
      padding: 0,
    },
    {
      field: "timeFrame",
      headerName: "Frame",
      type: "number",
      width: 100,
      editable: false,
      headerAlign: "center",
      align:'center',
      padding: 0,
    },
    {
      field: "createdAt",
      headerName: "Time",
      type: "number",
      width: 120,
      editable: false,
      headerAlign: "center",
      align:'center',
      padding: 0,
    },
  ];

  useEffect(() => {
    if (
      stock_data !== null &&
      allTagsData.some((e) => e.token === +JSON.parse(stock_data?.token)) &&
      isMarketOpen
    ) {
      setScriptData((prevScriptData) => ({
        ...prevScriptData,
        [stock_data?.token]: stock_data,
      }));

      let token = JSON.parse(stock_data?.token);
      if (socketData[token]) {
        socketData[token].push([
          setDatefun(+stock_data?.exchange_timestamp),
          stock_data.last_traded_price / 100,
        ]);
      } else {
        socketData[token] = [
          [
            setDatefun(+stock_data?.exchange_timestamp),
            stock_data.last_traded_price / 100,
          ],
        ];
      }
      addSocketData(token, socketData[token]);
    }
  }, [stock_data, allTagsData]);

  useEffect(() => {
    setScriptData(null);
    if (allTagsData?.length > 0) {
      // const tokenList = wishListData?.map((token) => {
      const tokenList = allTagsData?.map((token) => {
        return token?.token?.toString() || 0;
      });
      Subscribe_stock_tokens(tokenList);
    }
    // }, [wishListData]);
  }, [allTagsData]);

  const row = useMemo(() => {
    return Object.values(scriptData || {})?.map((item, index) => {
      const lastTradePrice = isMarketOpen
        ? (
            parseInt(scriptData?.[item?.token]?.last_traded_price) / 100
          ).toFixed(2)
        : parseInt(item?.stockData?.[item?.stockData?.length - 1][2])?.toFixed(
            2
          );
      const closePrice = isMarketOpen
        ? (parseInt(scriptData?.[item?.token]?.close_price) / 100).toFixed(2)
        : parseInt(item?.stockData?.[item?.stockData?.length - 1][4])?.toFixed(
            2
          );
      const changePointPercent =
        ((lastTradePrice - closePrice) / closePrice) * 100;
      const changePointValue = lastTradePrice - closePrice;
      const script = wishListData?.filter(
        (data) => data?.token == JSON.parse(item?.token)
      )?.[0]?.name;

      // const tag = getCurrentTag(item?.token)
      const tagData = scriptData?.[item?.token]?.findToken;

      return {
        tag: tagData?.tag || item?.tag || "Watch",
        status: tagData?.tag || item?.tag || "Watch",
        CMP: lastTradePrice,
        entry: tagData?.entryPrice || item?.entryPrice || "-",
        stopLoss: tagData?.topLossAt?.price || item?.topLossAt?.price || "-",
        target: tagData?.targetAt?.price || item?.topLossAt?.price || "-",
        chargepoint: `${
          closePrice > lastTradePrice
            ? changePointValue.toFixed(2)
            : closePrice !== lastTradePrice
            ? `+${changePointValue.toFixed(2)}`
            : 0
        } (${changePointPercent ? `${changePointPercent.toFixed(2)}%` : "0%"})`,
        script: script || item?.stock_name,
        id: index + 1,
        miniChart: {
          token: item?.token,
          lastTradePrice: lastTradePrice,
        },
        timeFrame: tagData?.timeFrame || item?.timeFrame,
        exchange_type: tagData?.exchange_type || item?.exchange_type,
        createdAt: Index.moment(tagData?.createdAt).format("HH:MM:SS"),
        token: item?.token,
        action: [
          {
            token: item?.token,
            value: Index.Svg.roundPlus,
          },
          {
            token: item?.token,
            stockName: item?.name || item?.stock_name,
            value: Index.Svg.whiteDots,
          },
        ],
      };
    });
  }, [scriptData]);

  //handle Tag update
  const handleTagUpdate = (tagName, token) => {
    setScriptData((prevScriptData) => ({
      ...prevScriptData,
      [JSON.stringify(token)]: {
        ...prevScriptData[JSON.stringify(token)],
        findToken: {
          ...(prevScriptData[JSON.stringify(token)]?.findToken || {}),
          tag: tagName,
        },
      },
    }));
  };

  const removeTopLossAtOrTargetAt = async (token, status) => {
    // try {
    //   const response = await Index.DataService(userLoginToken).post(
    //     "/user/stocks/remove-toploss-or-target",
    //     {
    //       token: JSON.parse(token),
    //       targetTag: status,
    //     }
    //   );
    //   toast.success("status updated");
    //   setTargetOrTopLoss()
    // setEditOrderData({})
    // } catch (error) {
    //   setTargetOrTopLoss()
    // setEditOrderData({})
    // }
  };

  function deleteLine(chart) {
    // get the selected annotation
    if (orderCurrentChart) {
      let controller = orderCurrentChart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      controller.removeAnnotation(selectedAnnotation);
      if (targetOrTopLoss) {
        setTimeout(() => {
          removeTopLossAtOrTargetAt(editOrderData?.token, targetOrTopLoss);
        }, 1000);
      }
    }
    // // get the selected annotation
    // var selectedAnnotation = currenChart.annotations().getSelectedAnnotation();
    // // remove the selected annotation
    // currenChart.annotations().removeAnnotation(selectedAnnotation);
  }

  //Category Wise Filter
  const handleCategoryWiseFilter = (ele) => {
    const exchangeType = ele?.exchange_type?.toString()?.toLowerCase();
    if (stockCategoryFilter === "All") {
      return ele;
    }
    return exchangeType === stockCategoryFilter?.toLowerCase();
  };

  useEffect(() => {
    window.addEventListener("keydown", function (e) {
      if (e?.keyCode === 46) {
        if (targetOrTopLoss) {
          deleteLine(orderCurrentChart);
        }
      }
    });
  }, [orderCurrentChart, targetOrTopLoss]);

  //Positional Data
  const getHistorylist = (headerData) => {
    const formData = new URLSearchParams();
    formData.append("exchange", headerData?.exchange_type || "MCX");
    formData.append("symboltoken", headerData?.token);
    formData.append("interval", chartDuration[chartView]?.interval);
    formData.append(
      "fromdate",
      Index.moment()
        .subtract(chartDuration[chartView]?.timeFrame, "d")
        .set({ hour: 9, minute: 15 })
        .format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      Index.moment()
        .add(1, "d")
        .set({ hour: 3, minute: 30 })
        .format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(userLoginToken)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.status === 200) {
          let tagDataObj = allTagsData?.find(
            (ele) => ele?.token == headerData?.token
          );

          if (tagDataObj) {
            tagDataObj.stockData = res.data.data;
            setScriptData((prevScriptData) => ({
              ...prevScriptData,
              [headerData?.token]: tagDataObj,
            }));
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (allTagsData?.length > 0 && !isMarketOpen) {
      allTagsData?.forEach((tag, index) => {
        setTimeout(() => {
          getHistorylist(tag);
        }, index * 2500);
      });
    }
  }, [allTagsData, isMarketOpen]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  return (
    <>
      <Index.Box className="dashboard-wrapper">
        <Index.Overlay></Index.Overlay>
        {/* <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } `}
        >
          <Index.Box className=" dash-grid">
            <Index.Grid container spacing={2} className="chart-main">
              {Object.keys(scriptData || {})?.map((item, i) => (
                <>
                  <Index.Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={scriptData[item]?.token}
                    className="grid-main"
                  >
                    <Index.Box
                      className="small-chart-box"
                      id={`print-${JSON.parse(scriptData[item]?.token)}`}
                    >
                      <Index.OrderChartHeader
                        entryPrice={
                          scriptData[item]?.findToken?.entryPrice || "-"
                        }
                        defaultTag={scriptData[item]?.findToken?.tag || ""}
                        token={item}
                        currentPrice={scriptData[item]?.last_traded_price / 100}
                        closePrice={scriptData[item]?.close_price / 100}
                        isStopLossHit={
                          scriptData[item]?.findToken?.topLossAt?.completed ||
                          false
                        }
                        headerData={{
                          stock_name: wishListData?.filter(
                            (data) =>
                              data?.token == JSON.parse(scriptData[item]?.token)
                          )?.[0]?.name,
                        }}
                        stockTag={
                          scriptData[item]?.findToken?.topLossAt?.completed ||
                          scriptData[item]?.findToken?.targetAt?.completed
                            ? "Close"
                            : scriptData[item]?.findToken?.tag
                        }
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        deleteLine={deleteLine}
                        handleClose={handleClose}
                        updateOrder={updateOrder}
                        setUpdateOrder={setUpdateOrder}
                        placeOrderData={placeOrderData}
                        exchange_timestamp={
                          scriptData[item]?.exchange_timestamp
                        }
                        setScriptData={setScriptData}
                        scriptData={scriptData}
                        editOrderData={editOrderData}
                        setEditOrderData={setEditOrderData}
                        orderCurrentChart={orderCurrentChart}
                        setAllTagsData={setAllTagsData}
                      />
                      <Index.Box className="line-chart-body">
                        <CustomLineChartSingle
                          draw={true}
                          liveData={socketData}
                          index={item}
                          stockToken={item}
                          currentPrice={
                            scriptData[item]?.last_traded_price / 100
                          }
                          exchange_timestamp={
                            scriptData[item]?.exchange_timestamp
                          }
                          stockTag={scriptData[item]?.findToken?.tag}
                          targetAt={scriptData[item]?.findToken?.targetAt}
                          topLossAt={scriptData[item]?.findToken?.topLossAt}
                          setCurrenChart={setCurrenChart}
                          setUpdateOrder={setUpdateOrder}
                          updateOrder={updateOrder}
                          openModel={openModel}
                          setOpenModel={setOpenModel}
                          setPlaceOrderData={setPlaceOrderData}
                          markerData={{
                            timeStamp: scriptData[item]?.findToken?.timeStamp,
                            price: +scriptData[item]?.findToken?.entryPrice,
                            tag: scriptData[item]?.findToken?.tag,
                          }}
                          editOrderData={editOrderData}
                          setEditOrderData={setEditOrderData}
                          setOrderCurrentChart={setOrderCurrentChart}
                          setTargetOrTopLoss={setTargetOrTopLoss}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>
                </>
              ))}
            </Index.Grid>
          </Index.Box>
        </Index.Box> */}
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          {/* <Index.Box className="open-order-header">
            <Index.Box className="open-order-title">
              <Index.Typography
                className="order-title-text"
                variant="p"
                component="p"
              >
                Open Order List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="open-order-button">
              <Index.Box
                className="order-btn-bg cus-center minus-btn"
                onClick={() => SetIsActive(!isActive)}
              >
                <img
                  className="order-btn-img"
                  src={Index.Svg.minusGreen}
                  alt="minusGreen"
                />
              </Index.Box>
              <Index.Box
                className="order-btn-bg cus-center max-btn"
                onClick={() => Setactive(!active)}
              >
                <img
                  className="order-btn-img"
                  src={Index.Svg.maxScrren}
                  alt="maxScrren"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {isActive && ( */}
          <Index.Box
            className={!active ? "open-order-body active" : "open-order-body"}
          >
            <Index.Box className="open-order-list-filter">
              <Index.Select
                className="common-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="StockSelect"
                // label={"Chart"}
                defaultValue={stockCategoryFilter || "All"}
                value={stockCategoryFilter || "All"}
                // onChange={filterRowData}
                onChange={(e) => {
                  let value = e?.target?.value;
                  setStockCategoryFilter(value);
                }}
              >
                {allStockTypes.map((tag, index) => (
                  <Index.MenuItem
                    key={tag?.id}
                    value={tag?.value}
                    className="common-dropdown-menu"
                  >
                    {tag?.name}
                  </Index.MenuItem>
                ))}
              </Index.Select>
            </Index.Box>
            <Index.Box className="table-sec">
              <Index.Box sx={{ width: "100%" }}>
              {row && row.length > 0 ? (
                <Index.DataGrid
                  rows={row?.filter(handleCategoryWiseFilter)}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[1000]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  // checkboxSelection

                  disableRowSelectionOnClick
                />
              ) : (
                <Index.Box sx={{ textAlign: 'center', padding: '20px' }} className="text-data">
                  Open order report not found
                </Index.Box>
              )}
              </Index.Box>
            </Index.Box>
            {/* <Index.Footer></Index.Footer> */}
          </Index.Box>
          {/* )} */}
        </Index.Box>
      </Index.Box>

      {miniChartOpen && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={miniChartOpen}
              onClose={() => {
                handleClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Index.Box sx={style}>
                <Index.Box className="modal-header-sec">
                  <Index.ChartHeader
                    index={JSON.parse(activeMiniChartOpen)}
                    tags={tags}
                    setTags={setTag}
                    allTags={allTags}
                    headerData={{
                      stock_name: wishListData?.filter(
                        (data) => data?.token == JSON.parse(activeMiniChartOpen)
                      )?.[0]?.name,
                      stockData: socketData[JSON.parse(activeMiniChartOpen)],
                    }}
                    onClose={() => {
                      handleClose();
                    }}
                    handleTagUpdate={handleTagUpdate}
                  ></Index.ChartHeader>
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  <Index.Box className="chart-body">
                    <CustomLineChartSingle
                      height={500}
                      width={1000}
                      liveData={socketData}
                      index={activeMiniChartOpen}
                      stockToken={activeMiniChartOpen}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
    </>
  );
};

export default OpenOrderList;
