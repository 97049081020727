import { combineReducers } from 'redux';
import UserReducer from './user/reducer';
import WebSocketReducer from './webSocket/reducer';

const rootReducer = combineReducers({
  UserReducer : UserReducer,
  WebSocketReducer:WebSocketReducer,
});

export default rootReducer;
