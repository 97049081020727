import React, { useState } from "react";
import Index from "../../Index";
import { createNewPasswordValidations } from "../../../validation/Validations";
import { activeShortCut } from "../../../redux/user/action";

const CreateNewPassword = () => {
  // State declaration region start
  const dispatch = Index.useDispatch();
  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const [user, setUser] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [userError, setUserError] = useState({});
  const [error, setError] = useState("");
  // State declaration region end

  // Hooks declaration region start
  // Hooks declaration region end

  // Function declaration region start
  // Input fields on change input handle start
  const onChangeInput = (e) => {
    setError("");
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const validationErrors = createNewPasswordValidations({
      ...user,
      [name]: value,
    });
    setUserError((prevErrors) => ({
      ...prevErrors,
      [name]: validationErrors[name],
    }));
  };

  // Input fields on change input handle start

  // Submit handler function start

  const handleCreateNewPassword = async (e) => {
    e.preventDefault();

    const formData = new URLSearchParams();
    formData.append("oldPassword", user.oldPassword);
    formData.append("newPassword", user.newPassword);

    const validationErrors = createNewPasswordValidations(user);
    if (Object.keys(validationErrors).length > 0) {
      setUserError(validationErrors);
    } else {
      try {
        const response = await Index.DataService(userLoginToken).post(
          Index.Api.auth.changePassword,
          formData
        );
        if (response.data.status === 200) {
          // Index.toast.success(response.data.message);
          setTimeout(() => {
            setUser({
              // oldPassword: "",
              // newPassword: "",
              confirmPassword: "",
            });
          }, 1000);
        } else {
          // Index.toast.error(response.data.message);
          setError(response.data.message);
        }
      } catch (error) {
        // Index.toast.error(error.response.data.message);
        setError(error.response.data.message);
      }
    }
  };
  // Submit handler function end

  // Function declaration region start

  return (
    <>
      <Index.Box className="login-bg">
        <Index.Header></Index.Header>
        <Index.Overlay></Index.Overlay>

        <Index.Box
          className="login-inner-main change-password-inner-main custom-reset-inner"
          component="form"
          method="post"
          onSubmit={handleCreateNewPassword}
        >
          <img className="main-logo" src={Index.Png.logo} alt="logo" />
          <Index.Typography variant="h1">Create New Password</Index.Typography>
          <Index.Typography
            className="blue-text mb-30 reset-sub-heading"
            variant="p"
            component="p"
          >
            Your new password must be different from <br />
            previous used password
          </Index.Typography>

          <Index.Box className="cus-input-grp change-password-box">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Enter your old password
            </Index.Typography>
            <Index.Box className="form-group">
              <Index.TextField
                name="oldPassword"
                fullWidth
                id="fullWidth-new-oldPassword"
                className="form-control"
                placeholder="Enter your old password"
                autoComplete="new-password"
                value={user.oldPassword}
                onChange={onChangeInput}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
              />
            </Index.Box>
            {userError.oldPassword && (
              <span className="auth-error-msg change-password-error">
                {userError.oldPassword}
              </span>
            )}
          </Index.Box>

          <Index.Box className="cus-input-grp change-password-box">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Enter your new password
            </Index.Typography>
            <Index.Box className="form-group password-field-box">
              <Index.TextField
                name="newPassword"
                fullWidth
                id="fullWidth-new-newPassword"
                className="form-control password-field"
                placeholder="Enter your new password"
                autoComplete="new-password"
                value={user.newPassword}
                onChange={onChangeInput}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <Index.InputAdornment
                      position="end"
                      className="pass_position"
                    >
                      <Index.IconButton
                        className="icon_end_icon"
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <Index.VisibilityOff className="pass-icon-color-admin" />
                        ) : (
                          <Index.Visibility className="pass-icon-color-admin" />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  ),
                }}
              />
            </Index.Box>
            {userError.newPassword && (
              <span className="auth-error-msg change-password-error">
                {userError.newPassword}
              </span>
            )}
          </Index.Box>

          <Index.Box className="cus-input-grp change-password-box">
            <Index.Typography
              className="cus-lable light-font"
              variant="p"
              component="p"
            >
              Enter your confirm password
            </Index.Typography>
            <Index.Box className="form-group password-field-box">
              <Index.TextField
                name="confirmPassword"
                fullWidth
                id="fullWidth-confirm-password"
                className="form-control password-field"
                placeholder="Enter your confirm password"
                autoComplete="new-password"
                value={user.confirmPassword}
                onChange={onChangeInput}
                onBlur={(e) => {
                  dispatch(activeShortCut(true));
                }}
                onFocus={(e) => {
                  dispatch(activeShortCut(false));
                }}
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <Index.InputAdornment
                      position="end"
                      className="pass_position"
                    >
                      <Index.IconButton
                        className="icon_end_icon"
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Index.VisibilityOff className="pass-icon-color-admin" />
                        ) : (
                          <Index.Visibility className="pass-icon-color-admin" />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  ),
                }}
              />
            </Index.Box>
            {userError.confirmPassword && (
              <span className="auth-error-msg change-password-error">
                {userError.confirmPassword}
              </span>
            )}
          </Index.Box>

          {error && (
            <span className="auth-error-msg change-password-error mt-10">
              {error}
            </span>
          )}

          <Index.PrimaryButton
            className="primary-btn login-btn"
            btnLabel="Change Password"
            type="submit"
          />
          <Index.Link className="forgot-link" to="/user">
            Back to Home
          </Index.Link>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default CreateNewPassword;
